import React, { Component } from "react";

import { Wrapper } from "app/NativeComponents/common";

import ColumnButton from "./ColumnButton";

class ColumnItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      row_height: props.toggle_lead_images == "large" ? 100 : 35
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.toggle_lead_images !== this.props.toggle_lead_images) {
      this.setState({
        row_height: this.props.toggle_lead_images == "large" ? 100 : 35
      });
    }
  }

  render() {
    const { section, list_properties, colors, active_property } = this.props;
    const { row_height } = this.state;
    if (section) {
      return (
        <Wrapper style={{ width: section.width }}>
          {list_properties &&
            list_properties.map((property, i) => {
              if (property.deal) {
                return (
                  <ColumnButton
                    key={
                      "lead_item_" +
                      property.deal?.id +
                      "_" +
                      section.slug +
                      "_" +
                      i
                    }
                    row_height={row_height}
                    pressProperty={this.props.pressProperty}
                    section={section}
                    active_property={active_property}
                    property={property}
                    colors={colors}
                    getHighlightedText={this.props.getHighlightedText}
                    shouldPhoneNumberBeHighlighted={
                      this.props.shouldPhoneNumberBeHighlighted
                    }
                    toggle_lead_images={this.props.toggle_lead_images}
                    hover_row={this.props.hover_row}
                    setHoverRow={this.props.setHoverRow}
                  />
                );
              }
              return null;
            })}
        </Wrapper>
      );
    }
  }
}

export default ColumnItem;
