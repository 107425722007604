import {
  TOGGLE_ONBOARDING,
  TOGGLE_DRAWER,
  SET_INITIAL_REDIRECT,
  START_REDIRECT,
  END_REDIRECT,
  INIT_MAP_EDIT,
  SIGNATURE_INIT,
  TOGGLE_DEALS_OPTIONS,
  SELECT_DEFAULT_SENDING_OPTIONS,
  CHANGE_TAB,
  SET_FEATURE_MODAL,
  TOGGLE_PRICING_PAGE,
  TOGGLE_DEMO_PAGE,
  TOGGLE_PAYMENT_PAGE,
  TOGGLE_CANCEL_PAGE,
  TOGGLE_MAILER_DESIGNER,
  TOGGLE_MAILER_SELECTOR,
  SET_RIGHT_PANEL,
  TOGGLE_ACTIVE_PROPERTY,
  TOGGLE_STATUS_MODAL,
  TOGGLE_EDIT_MODAL,
  TOGGLE_TAG_MODAL,
  TOGGLE_LIST_MODAL,
  TOGGLE_ADD_PHONE_NUMBER,
  SET_LEAD_MODAL,
  TOGGLE_PRESET_MODAL,
  TOGGLE_ITEM_SELECTOR_MODAL,
  TOGGLE_BULK_UPLOAD_MODAL,
  TOGGLE_TEAM_ACTIVITY_MODAL,
  TOGGLE_TEAM_ACTIVITY_LIST,
  TOGGLE_MULTISEND_MODAL,
  TOGGLE_EDIT_NOTE,
  TOGGLE_MAIL_TIMELINE,
  SET_TASK_PANEL,
  TOGGLE_EXIT_STRATEGY,
  TOGGLE_SETTINGS_PANEL,
  TOGGLE_WORKFLOW_DESIGNER,
  TOGGLE_PHOTO_GALLERY,
  TOGGLE_ADVANCED_FILTERS,
  TOGGLE_LEAD_OWNER_MODAL,
  TOGGLE_LIST_LIBRARY,
  TOGGLE_NEW_LIST,
  PUSH_SIDE_PANEL,
  POP_SIDE_PANEL,
  COMPLETE_POP_SIDE_PANEL,
  COMPLETE_POP_ALL_SIDE_PANEL,
  POP_ALL_SIDE_PANEL,
  REPLACE_SIDE_PANEL,
  UPDATE_SIDE_PANEL,
  BUILD_SIDE_PANELS
} from "app/DealMachineCore/types";

import { store } from "app/store";
import { AppConfig } from "app/NativeActions";

export const appRedirect = ({ dispatch, redirect, payload }) => {
  if (dispatch) {
    handleRedirect({ dispatch, redirect, payload });
  } else {
    return dispatch => {
      handleRedirect({ dispatch, redirect, payload });
    };
  }
};
export const setInitialRedirect = (path = "") => {
  return {
    type: SET_INITIAL_REDIRECT,
    payload: path
  };
};

const handleRedirect = ({ dispatch, redirect, payload }) => {
  switch (redirect) {
    default:
      break;

    case "login":
      dispatch({ type: START_REDIRECT, payload: "/login" });
      break;

    case "register":
    case "signUp":
    case "sign_up":
      dispatch({ type: START_REDIRECT, payload: "/sign-up" });
      break;

    case "forgotPassword":
      dispatch({ type: START_REDIRECT, payload: "/forgot-password" });

      break;

    case "resetPassword":
      dispatch({ type: START_REDIRECT, payload: "/reset-password" });

      break;

    case "init":
      if (
        payload?.user?.team_owner == 1 &&
        parseInt(payload?.user?.finished_onboarding) !== 1
      ) {
        dispatch({ type: TOGGLE_ONBOARDING, payload: true });
        dispatch({
          type: START_REDIRECT,
          payload: "/sign-up/confirm-subscription"
        });
      } else {
        dispatch({ type: TOGGLE_DRAWER, payload: true });

        if (payload.user && payload.type != "load") {
          dispatch({ type: START_REDIRECT, payload: "/map" });
        }
      }

      break;
  }
};

export const startRedirect = redirect => {
  return {
    type: START_REDIRECT,
    payload: redirect
  };
};
export const endRedirect = redirect => {
  return {
    type: END_REDIRECT
  };
};
export const togglePricingPage = toggle => {
  return {
    type: TOGGLE_PRICING_PAGE,
    payload: toggle
  };
};

export const toggleDemoPage = toggle => {
  return {
    type: TOGGLE_DEMO_PAGE,
    payload: toggle
  };
};

export const togglePaymentPage = toggle => {
  return {
    type: TOGGLE_PAYMENT_PAGE,
    payload: toggle
  };
};

export const toggleCancelPage = toggle => {
  return {
    type: TOGGLE_CANCEL_PAGE,
    payload: toggle
  };
};

export const toggleMailerDesigner = toggle => {
  return {
    type: TOGGLE_MAILER_DESIGNER,
    payload: toggle
  };
};

export const toggleMailerSelector = toggle => {
  return {
    type: TOGGLE_MAILER_SELECTOR,
    payload: toggle
  };
};

export const setRightPanel = panel => {
  return {
    type: SET_RIGHT_PANEL,
    payload: panel
  };
};

export const toggleActiveProperty = (toggle, data = {}) => {
  return dispatch => {
    const { property_id = null, lead_id = null, no_redirect = false } = data;
    let new_path = window.location.pathname;

    if (toggle) {
      if (lead_id) {
        dispatch({
          type: START_REDIRECT,
          payload: new_path + "#lead=" + lead_id
        });
      } else if (property_id) {
        dispatch({
          type: START_REDIRECT,
          payload: new_path + "#property=" + property_id
        });
      }
    } else {
      if (!no_redirect) {
        new_path = new_path.split("#");
        dispatch({
          type: START_REDIRECT,
          payload: new_path[0]
        });
      }
    }

    dispatch({
      type: TOGGLE_ACTIVE_PROPERTY,
      payload: toggle
    });
  };
};
export const toggleStatusModal = toggle => {
  return {
    type: TOGGLE_STATUS_MODAL,
    payload: toggle
  };
};
export const toggleLeadOwnerModal = toggle => {
  return {
    type: TOGGLE_LEAD_OWNER_MODAL,
    payload: toggle
  };
};
export const toggleEditModal = toggle => {
  return {
    type: TOGGLE_EDIT_MODAL,
    payload: toggle
  };
};

export const toggleListModal = toggle => {
  return {
    type: TOGGLE_LIST_MODAL,
    payload: toggle
  };
};
export const toggleTagModal = toggle => {
  return {
    type: TOGGLE_TAG_MODAL,
    payload: toggle
  };
};
export const toggleAddPhoneNumber = toggle => {
  return {
    type: TOGGLE_ADD_PHONE_NUMBER,
    payload: toggle
  };
};

export const setLeadModal = modal => {
  return {
    type: SET_LEAD_MODAL,
    payload: modal
  };
};

export const togglePresetModal = toggle => {
  return {
    type: TOGGLE_PRESET_MODAL,
    payload: toggle
  };
};
export const toggleItemSelectorModal = toggle => {
  return {
    type: TOGGLE_ITEM_SELECTOR_MODAL,
    payload: toggle
  };
};
export const toggleBulkUploadModal = toggle => {
  return {
    type: TOGGLE_BULK_UPLOAD_MODAL,
    payload: toggle
  };
};
export const toggleTeamActivityModal = toggle => {
  return {
    type: TOGGLE_TEAM_ACTIVITY_MODAL,
    payload: toggle
  };
};
export const toggleTeamActivityList = toggle => {
  return {
    type: TOGGLE_TEAM_ACTIVITY_LIST,
    payload: toggle
  };
};

export const toggleMultiSendModal = toggle => {
  return {
    type: TOGGLE_MULTISEND_MODAL,
    payload: toggle
  };
};

export const toggleEditNote = toggle => {
  return {
    type: TOGGLE_EDIT_NOTE,
    payload: toggle
  };
};

export const toggleMailTimeline = toggle => {
  return {
    type: TOGGLE_MAIL_TIMELINE,
    payload: toggle
  };
};

export const toggleExitStrategy = toggle => {
  return {
    type: TOGGLE_EXIT_STRATEGY,
    payload: toggle
  };
};

export const setTaskPanel = panel => {
  return {
    type: SET_TASK_PANEL,
    payload: panel
  };
};

export const toggleSettingsPanel = toggle => {
  return {
    type: TOGGLE_SETTINGS_PANEL,
    payload: toggle
  };
};

export const toggleWorkflowDesigner = toggle => {
  return {
    type: TOGGLE_WORKFLOW_DESIGNER,
    payload: toggle
  };
};

export const togglePhotoGallery = toggle => {
  return {
    type: TOGGLE_PHOTO_GALLERY,
    payload: toggle
  };
};

export const toggleAdvancedFilters = toggle => {
  return {
    type: TOGGLE_ADVANCED_FILTERS,
    payload: toggle
  };
};

export const toggleListLibrary = toggle => {
  return {
    type: TOGGLE_LIST_LIBRARY,
    payload: toggle
  };
};

export const toggleNewList = toggle => {
  return {
    type: TOGGLE_NEW_LIST,
    payload: toggle
  };
};

export const pushSidePanel = ({
  slug,
  id = null,
  overlay = false,
  focus_modal = false,
  focus_side_panel = false,
  content_side_panel = false,
  expanded_push = false,
  expanded_side_panel = false,
  locked = false,
  data = null,
  onSuccess = () => {}
}) => {
  return dispatch => {
    dispatch({
      type: PUSH_SIDE_PANEL,
      payload: {
        slug,
        id,
        overlay,
        focus_modal,
        focus_side_panel,
        content_side_panel,
        expanded_push,
        expanded_side_panel,
        locked,
        data
      }
    });

    onSuccess();
  };
};

export const replaceSidePanel = ({
  slug,
  id = null,
  overlay = false,
  focus_modal = false,
  focus_side_panel = false,
  content_side_panel = false,
  expanded_push = false,
  locked = false,
  data = null,
  onSuccess = () => {}
}) => {
  return dispatch => {
    dispatch({
      type: REPLACE_SIDE_PANEL,
      payload: {
        slug,
        id,
        overlay,
        focus_modal,
        focus_side_panel,
        content_side_panel,
        expanded_push,
        locked,
        data
      }
    });

    onSuccess();
  };
};

export const updateSidePanel = ({
  index,
  overlay,
  data = null,
  onSuccess = () => {}
}) => {
  return dispatch => {
    dispatch({
      type: UPDATE_SIDE_PANEL,
      payload: {
        index,
        overlay,
        data
      }
    });

    onSuccess();
  };
};

export const popSidePanel = (onSuccess = null) => {
  return {
    type: POP_SIDE_PANEL,
    payload: { onSuccess }
  };
};

export const completePopSidePanel = () => {
  return {
    type: COMPLETE_POP_SIDE_PANEL
  };
};

export const popAllSidePanels = (onSuccess = null) => {
  return {
    type: POP_ALL_SIDE_PANEL,
    payload: { onSuccess }
  };
};

export const completepopAllSidePanels = () => {
  return {
    type: COMPLETE_POP_ALL_SIDE_PANEL
  };
};

export const buildSidePanels = panels => {
  return {
    type: BUILD_SIDE_PANELS,
    payload: panels
  };
};

export const tabNavigation = ({ slug }) => {
  return dispatch => {
    switch (slug) {
      default:
        break;
      case "map":
      case "drive":
        dispatch({
          type: CHANGE_TAB,
          payload: "map"
        });
        dispatch({
          type: START_REDIRECT,
          payload: "/map"
        });
        break;

      case "leads":
        dispatch({
          type: CHANGE_TAB,
          payload: "leads"
        });
        dispatch({
          type: START_REDIRECT,
          payload: "/leads"
        });
        break;

      case "settings":
        dispatch({
          type: START_REDIRECT,
          payload: "/settings"
        });
        break;

      case "dashboard":
        dispatch({
          type: CHANGE_TAB,
          payload: "dashboard"
        });
        dispatch({
          type: START_REDIRECT,
          payload: "/dashboard"
        });
        break;

      case "tasks":
        dispatch({
          type: START_REDIRECT,
          payload: "/tasks"
        });
        break;

      case "driving":
        dispatch({
          type: START_REDIRECT,
          payload: "/driving"
        });
        break;

      case "mail":
        dispatch({
          type: CHANGE_TAB,
          payload: "mail"
        });
        dispatch({
          type: START_REDIRECT,
          payload: "/mail"
        });
        break;
      case "mail_center":
        dispatch({
          type: CHANGE_TAB,
          payload: "mail_center"
        });
        dispatch({
          type: START_REDIRECT,
          payload: "/mail-center"
        });
        break;

      case "postcard_designer":
      case "postcards":
        dispatch({
          type: CHANGE_TAB,
          payload: "postcard_designer"
        });
        dispatch({
          type: START_REDIRECT,
          payload: "/postcards"
        });
        break;

      case "mail_sequences":
        dispatch({
          type: CHANGE_TAB,
          payload: "mail_sequences"
        });
        dispatch({
          type: START_REDIRECT,
          payload: "/mail-sequences"
        });
        break;

      case "selling":
        dispatch({
          type: CHANGE_TAB,
          payload: "selling"
        });
        dispatch({
          type: START_REDIRECT,
          payload: "/selling"
        });
        break;

      case "team":
        dispatch({
          type: START_REDIRECT,
          payload: "/team"
        });
        break;
      case "list_builder":
        dispatch({
          type: START_REDIRECT,
          payload: "/lists/build-list"
        });
        break;

      case "learn":
        dispatch({
          type: CHANGE_TAB,
          payload: "leads"
        });
        dispatch({
          type: START_REDIRECT,
          payload: "/learn"
        });

      case "connect":
        dispatch({
          type: CHANGE_TAB,
          payload: "connect"
        });
        dispatch({
          type: START_REDIRECT,
          payload: "/connect"
        });
        break;

      case "dialer":
        dispatch({
          type: CHANGE_TAB,
          payload: "dialer"
        });
        dispatch({
          type: START_REDIRECT,
          payload: "/dialer"
        });
        break;
    }
  };
};

/**
 * Represents an in-application deep link
 * to an object instance using [attribute]=value syntax
 */

export class DeepLink {
  static #class_is_logging = false;
  static #class_is_authenticating = false;
  static #class_is_timestamping = false;

  // Logging...
  // static #utilities = [ DeepLink.browserUrl, DeepLink.decodeInteger, DeepLink.encodeInteger, DeepLink.getTimeStamp, DeepLink.goToUrl, DeepLink.hashCodeOf, DeepLink.replaceRegEx ];
  // #instances = [ this.constructor, this.url_hash, this.hasValueFor, this.hasValuesFor, this.hash, this.setValueFor, this.toString, this.valueOf, this.verifyAndGo, this.url ];

  static #url_hash_format = {
    format:
      "|:area:|#|:object:|(|:attribute:|)=|:value:|:|:creator:|@|:issued:|#|:hash:|!|:target:|",
    prefix: "|:",
    suffix: ":|"
  };

  static #url_hash_replacements = [
    // Disregard empty values ():
    { search_for: /\(\)/, replace_with: "" },
    {
      search_for: /-/g,
      replace_with: "_"
    }, // Disregard adjoining symbols:
    { search_for: /:@/, replace_with: "@" },
    { search_for: /@#/, replace_with: "#" },
    {
      search_for: /#!/,
      replace_with: "!"
    },

    // Disregard terminating/ending symbol:
    { search_for: /!+$/, replace_with: "" },

    // Disregard single "hash" (#):
    { search_for: /^#$/, replace_with: "" }
  ];

  static url_components = {
    protocol: /^https?:\/\//,
    localhost: /localhost:?[0-9]*/,
    area: /\/([a-z]+)/,

    /*
    Reserved Parameters & Patterns:
        region: /~([a-z])/,
    */

    // ex: https://directory/

    // ex: /#property
    object: /#([a-z_]+)/,

    // ex: /#property(id)
    attribute: /\(([a-z]+)\)/,

    // ex: /#property(id)=12345678
    value: /=([a-z,0-9]+),?/,

    // ex: /#property(id)=12345678!o
    /*
    Reserved Parameters & Patterns:
        context: /!([a-z]+)/,
        action: /:([a-z]+)/,
        selector: /\$([a-z]+)/,
        style: /:([a-z]+)/,
        scroll: /@([a-z]+)/,
        effect: /:([a-z]+)/,
        timing: /@([0-9,a-z]+)/,
        focus: /&([a-z]+)/,
    */
    creator: /:([a-z,0-9]+)/,
    issued: /@([a-z,0-9]+)/,
    hash: /#([a-z,0-9]+)/,
    target: /!((o)|(fm)|(fsp)|(csp)|(l))/
  };

  // http://localhost:3000/#property(id)=2949304!overlay,issued,from,for!hmac"

  #url_full = "";
  #url_hash = "";
  #url_map = {};

  /**
   * Create a deep link from a map (if specified) or the current window.location.href (if map unspecified)
   * @param {object|string} [input=""] - Optional URL or map of key-value components to construct a Deep Link
   */
  constructor(input = "") {
    /* url to map to url */
    /* map to url to map */

    DeepLink.logFunctionEntry(this.constructor, { map: input });
    DeepLink.logInformation("COMPONENTS DEFAULTED AS:");
    DeepLink.logInformation(DeepLink.url_components);

    switch (typeof input) {
      case "string":
        this.url_full = input ?? DeepLink.browserUrl ?? "";
        this.#url_map = this.createMapping(input);
        this.#url_hash = this.createUrlHash(input);
        break;

      case "object":
        this.#url_map = input;
        this.#url_hash = this.createUrlHash(input);
        break;

      default:
        return this;
    } // end switch (...)
  } // end constructor(...)

  static get browser_window_location_href() {
    const return_value = window?.location?.href ?? "";
    DeepLink.logParameterAccess("browser_window_location_href", return_value);
    return return_value;
  } // end browserUrl()

  get url_full() {
    DeepLink.logParameterAccess("url", this.#url_full);
    return this.#url_full;
  } // end get url()

  set url_full(url) {
    if (url !== this.#url_full) {
      // url changed, perform updates to: #url_full, #url_full_hash, and create new map...
      DeepLink.logParameterChangeFrom("url", this.#url_full);
      this.#url_full =
        typeof url === "string" ? url : DeepLink.browser_window_location_href;
      DeepLink.logParameterChangeTo("url", this.#url_full);

      DeepLink.logParameterChangeFrom("url_hash", this.#url_hash);
      this.#url_hash = "#" + this.#url_full.concat("#").split("#")[1];
      DeepLink.logParameterChangeTo("url_hash", this.#url_hash);

      this.updateHash();
    } // end if ...
  } // end set url(...)

  get url_hash() {
    this.updateHash();

    const hash = DeepLink.replaceRegEx(this.#url_hash);

    DeepLink.logParameterAccess("url_hash", hash);

    return hash;
  } // end get url_hash()

  get hash_code() {
    const hash_code = DeepLink.hashCodeOf(this.url_hash);

    DeepLink.logParameterAccess("hash_code", hash_code);

    return hash_code;
  } // end get hash()

  get url_has_hash() {
    this.updateHash();

    const hash = this.#url_hash;
    const return_value = !!hash && hash !== "#";

    DeepLink.logParameterAccess("url_has_hash", return_value);

    return return_value;
  } // end url_has_hash()

  static replaceRegEx(string, replacements = DeepLink.#url_hash_replacements) {
    DeepLink.logFunctionEntry(DeepLink.replaceRegEx, {
      string: string,
      replacements: replacements
    });

    // return string;

    let replaced = string;

    replacements.forEach(replacement => {
      if (replacement.search_for.test(replaced))
        replaced = replaced.replace(
          replacement.search_for,
          replacement.replace_with
        );
    }); // end replacements.forEach(...)

    DeepLink.logFunctionExit(DeepLink.replaceRegEx, replaced);

    return replaced;
  } // end replaceRegEx

  static goToUrl(url) {
    DeepLink.logFunctionEntry(DeepLink.goToUrl, { url: url });
    DeepLink.logInformation("Navigating to: ".concat(url) + "...");

    window.location.href = url;

    DeepLink.logFunctionExit(DeepLink.goToUrl);
  } // end goToUrl( ... )

  static getTimeStamp() {
    DeepLink.logFunctionEntry(DeepLink.getTimeStamp);

    const time_stamp = DeepLink.encodeDateTime(Date.now());

    DeepLink.logFunctionExit(DeepLink.getTimeStamp, time_stamp);

    return time_stamp;
  } // end getTimeStamp()

  isValidIfExpiresAfter(timeframe, key = "issued") {
    DeepLink.logFunctionEntry(this.isValidIfExpiresAfter, {
      timeframe: timeframe,
      key: key
    });

    const time_stamp = DeepLink.decodeDateTime(this.valueOf(key));
    const valid = time_stamp + timeframe < Date.now();

    DeepLink.logFunctionExit(this.isValidIfExpiresAfter, valid);

    return valid;
  } // end isValidIfExpiresAfter(...)

  static encodeDateTime(date_time) {
    DeepLink.logFunctionEntry(DeepLink.encodeDateTime, {
      date_time: date_time
    });

    const prehistoric = new Date("2023-01-01"); // Any date prior to implementation reduces the final number
    const precision = 1000 * 60; // Minute

    const encoded = Math.floor(date_time - prehistoric);
    const converted = encoded / precision;

    DeepLink.logFunctionExit(DeepLink.encodeDateTime, converted);

    return converted;
  } // end encodeDateTime(...)

  static decodeDateTime(date_time) {
    DeepLink.logFunctionEntry(DeepLink.decodeDateTime, {
      date_time: date_time
    });

    const prehistoric = new Date("2023-01-01"); // Any date prior to implementation reduces the final number
    const precision = 1000 * 60; // Minute

    const decoded = Math.floor(date_time * precision);
    const converted = decoded + prehistoric;

    DeepLink.logFunctionExit(DeepLink.decodeDateTime, converted);

    return converted;
  }

  static encodeInteger(integer) {
    const letters_phonetic_replacements = [
      "a|h",
      "b|q",
      "c|r",
      "d|s",
      "e|x",
      "f|f"
    ];

    DeepLink.logFunctionEntry(DeepLink.encodeInteger);

    let encoded = parseInt(integer).toString(16);

    for (const replacement of letters_phonetic_replacements ?? []) {
      // indices flipped in DeepLink.decodeInteger(...):
      const search_for = replacement?.split("|")[0] ?? "";
      const replace_with = replacement?.split("|")[1] ?? "";
      encoded = encoded.replace(search_for, replace_with);
    }

    DeepLink.logFunctionExit(DeepLink.encodeInteger, encoded);

    return encoded;
  } // end encodeInteger(...)

  static decodeInteger(hexadecimal) {
    const letters_phonetic_replacements = [
      "a|h",
      "b|q",
      "c|r",
      "d|s",
      "e|x",
      "f|f"
    ];

    DeepLink.logFunctionEntry(DeepLink.decodeInteger);

    let decoding = hexadecimal;

    for (const replacement of letters_phonetic_replacements ?? []) {
      // indices flipped in DeepLink.encodeInteger(...):
      const search_for = replacement?.split("|")[1] ?? "";
      const replace_with = replacement?.split("|")[0] ?? "";
      decoding = decoding.replace(search_for, replace_with);
    }

    const decoded = parseInt(decoding, 16);

    DeepLink.logFunctionExit(DeepLink.decodeInteger, decoded);

    return decoded;
  } // end decodeInteger(...)

  static hashCodeOf(string) {
    DeepLink.logFunctionEntry(DeepLink.hashCodeOf, { string: string });

    let hash_code = 0;

    for (let index = 0; index < string.length; index++) {
      // Public domain
      hash_code = (Math.imul(31, hash_code) + string.charCodeAt(index)) | 0;
    } // end for ...

    DeepLink.logFunctionExit(DeepLink.hashCodeOf, hash_code);

    return hash_code;
  } // end hashCodeOf(...)

  //region Logging...
  static logParameterChangeFrom(parameter_name, parameter_value) {
    DeepLink.logInformation(
      "Parameter: " + parameter_name + " previous value:"
    );
    DeepLink.logInformation(parameter_value);
  } // end logParameterChangeFrom( ... )

  static logParameterChangeTo(parameter_name, parameter_value) {
    DeepLink.logInformation("Parameter: " + parameter_name + " new value:");
    DeepLink.logInformation(parameter_value);
  } // end logParameterChangeTo((...)

  static logParameterAccess(parameter_name, parameter_value) {
    DeepLink.logInformation(
      "Parameter: " + parameter_name + " value accessed:"
    );
    DeepLink.logInformation(parameter_value);
  } // end logParameterAccess(...)

  static logFunctionEntry(calling_function, calling_arguments = []) {
    const function_name = calling_function.name;

    switch (Object?.entries(calling_arguments)?.length) {
      case undefined:
        DeepLink.logInformation(
          "Enter function: " + function_name + ", with unknown argument(s)."
        );
        break;

      case 0:
        DeepLink.logInformation(
          "Enter function: " + function_name + ", with no arguments."
        );
        break;

      case 1:
        DeepLink.logInformation(
          "Enter function: " + function_name + ", with one argument:"
        );
        DeepLink.logInformation(calling_arguments);
        break;

      default:
        DeepLink.logInformation(
          "Enter function: " + function_name + ", with multiple arguments..."
        );
        DeepLink.logInformation(calling_arguments);
    }

    return true;
  } // end logFunctionEntry(...)

  static logFunctionExit(calling_function, return_value = "void") {
    DeepLink.logInformation(
      "Exit function: " +
        calling_function.name +
        ", with return value: " +
        return_value
    );
  } // end logFunctionExit(...(

  static logInformation(information) {
    // const prefix = "{DeepLink}: ";
    // if ([null, undefined].includes(information)) {
    //   DeepLink.log("information to log is null or undefined.");
    // } // end if
    // else if (Array.isArray(information)) {
    //   while (information.length > 0) {
    //     // log information to console as table:
    //     const log = information.shift();
    //     DeepLink.log(
    //       "{Array}"
    //         .concat(" [", (information.length - 1).toLocaleString(), "] ")
    //         .concat(log)
    //     );
    //   }
    // } // end else if
    // else if (typeof information === "object") {
    //   DeepLink.log(JSON.stringify(information));
    // } else {
    //   DeepLink.log(information);
    // }
  }

  static log(string) {
    const prefix = "{DeepLink}: ";

    if (DeepLink.#class_is_logging) console.log(prefix.concat(string));
  }

  // end log(...)

  //endregion

  addTimeStamp(key = "issued") {
    DeepLink.logFunctionEntry(this.addTimeStamp, { key: key });

    if (!DeepLink.#class_is_timestamping) {
      DeepLink.logInformation("Timestamping is disabled.");
      DeepLink.logFunctionExit(this.addTimeStamp, this);
      return this;
    }
    const time_stamp = DeepLink.getTimeStamp();

    this.#url_map[key] = DeepLink.encodeInteger(time_stamp);

    DeepLink.logFunctionExit(this.addTimeStamp, this);

    return this;
  } // end addTimeStamp(...)

  getDateTime(key = "issued") {}

  signAndDate() {
    DeepLink.logFunctionEntry(this.signAndDate);

    this.addTimeStamp().sign();

    DeepLink.logFunctionExit(this.signAndDate, this);

    return this;
  } // end signAndDate()

  verify(key = "hash") {
    DeepLink.logFunctionEntry(this.verify, { key: key });

    let deep_link = new DeepLink(this.#url_full);
    let return_value = !(
      deep_link.url_has_hash && DeepLink.#class_is_authenticating
    );

    DeepLink.logInformation(this.verify.name + ": IN VERIFY :");

    if (!return_value && deep_link.hasValueFor(key)) {
      const start_hash = deep_link.valueOf(key);

      deep_link.sign();

      const regen_hash = deep_link.valueOf(key);

      DeepLink.logInformation(
        this.verify.name + ": " + start_hash + " vs " + regen_hash
      );

      return_value = start_hash === regen_hash;
    }

    DeepLink.logFunctionExit(this.verify, return_value);

    return return_value;
  } // end verify(...)

  verifyAndGo(error_url = DeepLink.browser_window_location_href) {
    DeepLink.logFunctionEntry(this.verifyAndGo, { error_url: error_url });

    if (this.verify()) {
      this.go();
    } else {
      DeepLink.goToUrl(error_url);
    }

    DeepLink.logFunctionExit(this.verifyAndGo);
  } // end verifyAndGo(...)

  sign(key = "hash") {
    DeepLink.logFunctionEntry(this.sign, { key: key });

    if (!DeepLink.#class_is_authenticating) {
      DeepLink.logInformation("Authentication is disabled.");
      DeepLink.logFunctionExit(this.sign, this);
      return this;
    } else if (this.hasValueFor(key)) {
      this.setValueFor(key, "");
    }
    const salt_value = "/*!!*=*fR($H-$aLt-|S-t][(-B($T-S@lT*!!*=*/";
    const hash_code = DeepLink.hashCodeOf(salt_value + this.url_hash);

    DeepLink.logInformation(
      this.sign.name +
        ": Signing: " +
        this.url_hash +
        ", as: " +
        DeepLink.encodeInteger(Math.abs(hash_code))
    );

    this.setValueFor(key, DeepLink.encodeInteger(Math.abs(hash_code)));
    DeepLink.logFunctionExit(this.sign, this);

    return this;
  } // end sign(...)

  updateHash() {
    DeepLink.logFunctionEntry(this.updateHash);

    this.#url_hash = this.createUrlHash(this.#url_map);

    DeepLink.logFunctionExit(this.updateHash);
  }

  toString() {
    DeepLink.logFunctionEntry(this.toString);

    let string = "";

    string += "URL: ".concat(this.#url_full, "\n");
    string += "Anchor: ".concat(this.#url_hash, "\n");
    string += "Mapping: ".concat(JSON.stringify(this.#url_map));

    DeepLink.logFunctionExit(this.toString, string);

    return string;
  } // end toString()

  createUrlHash(map = this.#url_map) {
    DeepLink.logFunctionEntry(this.createUrlHash, { map: map });

    if ([null, undefined].includes(map)) return "#";

    let hash = DeepLink.#url_hash_format["format"];
    const prefix = DeepLink.#url_hash_format["prefix"];
    const suffix = DeepLink.#url_hash_format["suffix"];

    // Set known, identified maps based on key-names
    for (const [key, value] of Object.entries(map)) {
      const key_format = prefix + key + suffix;
      hash = hash.replace(key_format, value?.toString());
    } // end for ...

    // Clear other maps (unidentified key-names in this.#url_map)
    for (const [key, value] of Object.entries(DeepLink.url_components)) {
      const key_format = prefix + key + suffix;
      hash = hash.replace(key_format, "");
    } // end for ...

    DeepLink.logFunctionExit(this.createUrlHash, hash);

    return hash;
  } // end createUrlHash(...)

  sync() {
    DeepLink.logFunctionEntry(this.sync);

    this.updateMapping();
    this.updateHash();

    DeepLink.logFunctionExit(this.sync);
  } // end sync()

  updateMapping() {
    DeepLink.logFunctionEntry(this.updateMapping);

    this.#url_map = this.createMapping(this.#url_full);

    DeepLink.logFunctionExit(this.updateMapping);
  } // end updateMapping()

  createMapping(url) {
    DeepLink.logFunctionEntry(this.createMapping, { url: url });

    let map = {};
    if (this.url_has_hash) {
      for (const [key, value] of Object.entries(DeepLink.url_components)) {
        let match = url.match(value);
        if (match) {
          map[key] = match[1];
          url = url.replace(match[0], "");
          if (key == "object") {
            map[key] = map[key].replace("-", "_");
          }
        } // end if ...
      } // end for ...
    } // end if ( this.#url_full_hash ) ...

    DeepLink.logFunctionExit(this.createMapping, map);

    return map;
  } // end createMapping(...)

  setValueFor(key, value) {
    DeepLink.logFunctionEntry(this.setValueFor, { key: key, value: value });

    this.#url_map[key] = value;
    this.updateHash();

    DeepLink.logFunctionExit(this.setValueFor);
  } // end setValueFor(...)

  hasValueFor(key) {
    DeepLink.logFunctionEntry(this.hasValueFor, { key: key });

    const return_value = !!this.valueOf(key);

    DeepLink.logFunctionExit(this.hasValueFor, return_value);

    return return_value;
  } // end hasValueFor(...)

  hasValuesFor(keys) {
    DeepLink.logFunctionEntry(this.hasValuesFor, { keys: keys });

    const return_value = keys.some(key => {
      return this.hasValueFor(key);
    });

    DeepLink.logFunctionExit(this.hasValuesFor, return_value);

    return return_value;
  } // end hasValuesFor(...)

  valueOf(key) {
    DeepLink.logFunctionEntry(this.valueOf, { key: key });

    const return_value = this.#url_map[key] ?? "";

    DeepLink.logFunctionExit(this.valueOf, return_value);

    return return_value;
  } // end valueOf(...)

  go() {
    DeepLink.logFunctionEntry(this.go);

    const url = window?.location?.href.split("#")[0].concat(this.#url_hash);

    DeepLink.goToUrl(url);

    DeepLink.logFunctionExit(this.go);
  } // end go()

  //</editor-fold>
} // end class DeepLink
