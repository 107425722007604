import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  Gradient,
  Wrapper,
  Row,
  Scroll,
  Copy
} from "app/NativeComponents/common";
import {
  UpsellButton,
  InlineButton,
  PopoverMenu,
  CircleButton,
  IconButton,
  SelectItem
} from "app/NativeComponents/snippets";

import {
  tabNavigation,
  setModal,
  toggleModal,
  changeTab,
  pushSidePanel,
  searchDealMachine,
  selectActiveProperty,
  setActiveList,
  openUrl,
  displayIntercom,
  nextOnboardingStep,
  checkIfUserHasMetadata,
  loadNewsAndUpdates,
  getDashboardStats
} from "app/NativeActions";
import Logo from "./Logo";
import Search from "./Search";
import PartnerLogo from "./PartnerLogo";
import CurrentCallSession from "./CurrentCallSession";

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intercom_opened: false,
      local_tab: "",
      show_mail_menu: false,
      loading_seen_slugs: true,
      news_slugs: [],
      seen_news_slugs: []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tab !== this.props.tab) {
      this.setState({
        local_tab: this.props.tab
      });
    }

    if (this.props.location?.pathname !== prevProps.location?.pathname) {
      this.setTabFromURLPath();
    }

    if (
      JSON.stringify(this.state.news_slugs) !==
        JSON.stringify(prevState.news_slugs) ||
      prevProps.tab !== this.props.tab
    ) {
      this.getNotifications();
    }
  }

  setTabFromURLPath() {
    //
    const main_path = window?.location.pathname || "";

    if (main_path.startsWith("/dashboard")) {
      this.setState({
        local_tab: "dashboard"
      });
    } else if (main_path.startsWith("/map") || main_path.startsWith("/drive")) {
      this.setState({
        local_tab: "map"
      });
    } else if (main_path.startsWith("/leads")) {
      this.setState({
        local_tab: "leads"
      });
    } else if (main_path.startsWith("/selling")) {
      this.setState({
        local_tab: "selling"
      });
    } else if (main_path.startsWith("/mail-center")) {
      this.setState({
        local_tab: "mail_center"
      });
    } else if (main_path.startsWith("/postcards")) {
      this.setState({
        local_tab: "postcard_designer"
      });
    } else if (main_path.startsWith("/mail-sequences")) {
      this.setState({
        local_tab: "mail_sequences"
      });
    } else if (main_path.startsWith("/partner")) {
      this.setState({
        local_tab: "partner"
      });
    } else if (main_path.startsWith("/connect")) {
      this.setState({
        local_tab: "connect"
      });
    } else if (main_path.startsWith("/dialer")) {
      this.setState({
        local_tab: "dialer"
      });
    } else {
      this.setState({
        local_tab: "map"
      });
    }
    if (!main_path.startsWith("/dashboard")) {
      this.loadDashboardNotifications();
    }
  }

  componentDidMount() {
    this.setTabFromURLPath();
  }

  loadDashboardNotifications() {
    this.setState(
      {
        loading_seen_slugs: true
      },
      () => {
        const { data } = loadNewsAndUpdates();

        loadNewsAndUpdates()
          .then(data => {
            let slugs = [];
            if (data && data.items) {
              //for all of the data items..get the slug_id from each one and put them in slugs
              data.items.map(item => {
                if (
                  item.fields &&
                  item.fields.slug_id &&
                  item.fields.show_notification
                ) {
                  slugs.push(item.fields.slug_id);
                }
              });

              this.setState({
                news_slugs: slugs
              });
            }
            this.setState(() => ({ contentful_loading: false }));
          })
          .catch(err => {
            this.setState(() => ({ contentful_loading: false }));
          });
      }
    );
  }

  getNotifications() {
    this.setState(
      {
        loading_seen_slugs: true
      },
      () => {
        this.props.getDashboardStats({
          token: this.props.token,
          type: "get_news_and_updates_notifications",
          slugs: this.state.news_slugs.join(","),
          onLoading: () => {},
          onError: error => {},
          onSuccess: results => {
            this.setState({
              loading_seen_slugs: false,
              seen_news_slugs: results?.seen_news_slugs
            });
          }
        });
      }
    );
  }

  getDaysLeftOnTrial = endDateString => {
    const endDate = new Date(endDateString);
    const today = new Date();
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    const differenceInMiliSeconds = endDate.getTime() - today.getTime();
    const differenceInDays = Math.round(
      differenceInMiliSeconds / millisecondsInADay
    );
    return differenceInDays;
  };

  render() {
    const { colors, device, tab, source_of_truth, user } = this.props;
    const { local_tab } = this.state;
    const daysLeftOfTrial =
      user?.user_version >= 6 &&
      source_of_truth?.subscription?.status == "trialing" &&
      this.getDaysLeftOnTrial(source_of_truth?.subscription?.trial_end);

    const phone_plan = checkIfUserHasMetadata("dialer");
    const call_tracking = checkIfUserHasMetadata("call_tracking");

    return (
      <Gradient
        style={{
          alignSelf: "stretch",
          height: 60,
          justifyContent: "center",
          minWidth: 950
        }}
        color1={colors.side_gradient_color_1}
        color2={colors.side_gradient_color_2}
      >
        <Wrapper
          style={{
            height: 60
          }}
        >
          <Row>
            <Row>
              {!call_tracking ? (
                <>
                  <Logo {...this.props} local_tab={local_tab} />
                  <PartnerLogo {...this.props} local_tab={local_tab} />
                </>
              ) : null}

              {call_tracking ? (
                <InlineButton
                  textColor={colors.white_text_color}
                  pressedIn={local_tab === "dashboard"}
                  style={{
                    borderRadius: 0,
                    margin: 0,
                    height: 60,
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0
                  }}
                  onPress={() => {
                    this.props.changeTab("dashboard", tab);
                    this.props.tabNavigation({ slug: "dashboard" });
                  }}
                  renderRight={
                    parseInt(this.state.news_slugs?.length) -
                      parseInt(this.state.seen_news_slugs?.length) >
                      0 &&
                    !this.state.loading_seen_slugs &&
                    local_tab !== "dashboard"
                      ? () => {
                          return (
                            <Wrapper
                              style={{
                                borderRadius: 10,
                                width: 20,
                                height: 20,
                                backgroundColor: colors.orange_color,
                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: 10
                              }}
                            >
                              <Copy
                                style={{
                                  color: colors.white_text_color,
                                  fontSize: 14
                                }}
                              >
                                {parseInt(this.state.news_slugs.length) -
                                  parseInt(this.state.seen_news_slugs.length)}
                              </Copy>
                            </Wrapper>
                          );
                        }
                      : null
                  }
                  noCopy={true}
                >
                  <Row>
                    <Logo {...this.props} local_tab={local_tab} />
                    <PartnerLogo {...this.props} local_tab={local_tab} />
                    <Copy style={{ color: colors.white_text_color }}>
                      Dashboard
                    </Copy>
                  </Row>
                </InlineButton>
              ) : null}

              <UpsellButton
                type="plan"
                slug="driving"
                tier={0}
                contentful_slug="unlock_driving"
                meta_slug="driving_for_dollars"
                onLockPressOverride={() => {
                  this.props.changeTab("map", tab);
                  this.props.tabNavigation({ slug: "map" });
                }}
                onPress={() => {
                  this.props.changeTab("map", tab);
                  this.props.tabNavigation({ slug: "map" });
                }}
                renderLockedChildren={({ hovering }) => {
                  return (
                    <InlineButton
                      icon="lock"
                      noPress={true}
                      pressedIn={hovering || local_tab == "map"}
                      textColor={colors.white_text_color}
                      onPress={() => {}}
                      style={{
                        borderRadius: 0,
                        margin: 0,

                        height: 60,
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: 0,
                        paddingBottom: 0
                      }}
                    >
                      Map
                    </InlineButton>
                  );
                }}
                renderChildren={({ hovering }) => {
                  return (
                    <InlineButton
                      noPress={true}
                      pressedIn={hovering || local_tab == "map"}
                      textColor={colors.white_text_color}
                      onPress={() => {}}
                      style={{
                        borderRadius: 0,
                        margin: 0,

                        height: 60,
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: 0,
                        paddingBottom: 0
                      }}
                    >
                      Map
                    </InlineButton>
                  );
                }}
              />

              {/*this.props.user.team_clearance_level > 0 ? (
                <InlineButton
                  textColor={colors.white_text_color}
                  pressedIn={local_tab === "connect"}
                  style={{
                    borderRadius: 0,
                    margin: 0,

                    height: 60,
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: 0,
                    paddingBottom: 0
                  }}
                  onPress={() => {
                    this.props.changeTab("connect", tab);
                    this.props.tabNavigation({
                      slug: "connect"
                    });
                  }}
                >
                  Connect
                </InlineButton>
              ) : null*/}
              {this.props.user.team_clearance_level > 0 ? (
                <InlineButton
                  textColor={colors.white_text_color}
                  pressedIn={local_tab == "leads"}
                  style={{
                    borderRadius: 0,
                    margin: 0,

                    height: 60,
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: 0,
                    paddingBottom: 0
                  }}
                  onPress={() => {
                    this.props.changeTab("leads", tab);
                    this.props.tabNavigation({ slug: "leads" });
                  }}
                >
                  Leads
                </InlineButton>
              ) : null}

              {(this.props.user.team_owner == 1 ||
                this.props.user.can_send_message == 1) &&
              phone_plan ? (
                <InlineButton
                  textColor={colors.white_text_color}
                  pressedIn={local_tab === "dialer"}
                  style={{
                    borderRadius: 0,
                    margin: 0,

                    height: 60,
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: 0,
                    paddingBottom: 0
                  }}
                  onPress={() => {
                    this.props.changeTab("dialer", tab);
                    this.props.tabNavigation({
                      slug: "dialer"
                    });
                  }}
                >
                  Dialer
                </InlineButton>
              ) : null}

              {this.props.user?.team_clearance_level > 1 ||
              (this.props.user?.can_edit_templates == 1 &&
                this.props.user?.team_clearance_level == 1) ? (
                <PopoverMenu
                  show={this.state.show_mail_menu}
                  onShow={s => {
                    this.setState({ show_mail_menu: s });
                  }}
                  popover_width={350}
                  popoverSheetTop={300}
                  popoverPlacement={"bottom"}
                  renderComponent={options => {
                    return (
                      <InlineButton
                        textColor={colors.white_text_color}
                        pressedIn={
                          local_tab == "mail_center" ||
                          local_tab == "mail_sequences" ||
                          local_tab == "postcard_designer" ||
                          options.hovering ||
                          this.state.show_mail_menu
                        }
                        noPress={true}
                        style={{
                          borderRadius: 0,
                          margin: 0,

                          height: 60,
                          alignItems: "center",
                          justifyContent: "center",
                          paddingTop: 0,
                          paddingBottom: 0
                        }}
                      >
                        Mail
                      </InlineButton>
                    );
                  }}
                  renderMenu={() => {
                    return (
                      <Wrapper style={{ alignSelf: "stretch" }}>
                        <SelectItem
                          select_type="none"
                          icon={"keyboard-arrow-right"}
                          onPress={() => {
                            this.setState(
                              {
                                show_mail_menu: false
                              },
                              () => {
                                this.props.changeTab("mail_center", tab);
                                this.props.tabNavigation({
                                  slug: "mail_center"
                                });
                              }
                            );
                          }}
                        >
                          Mail Center
                        </SelectItem>
                        <SelectItem
                          style={{ flex: 1 }}
                          select_type="none"
                          icon={"keyboard-arrow-right"}
                          onPress={() => {
                            this.setState(
                              {
                                show_mail_menu: false
                              },
                              () => {
                                this.props.changeTab("postcard_designer", tab);
                                this.props.tabNavigation({
                                  slug: "postcard_designer"
                                });
                              }
                            );
                          }}
                        >
                          Postcards
                        </SelectItem>
                        <UpsellButton
                          meta_slug={"advanced_mail_sequences"}
                          onPress={() => {
                            this.setState(
                              {
                                show_mail_menu: false
                              },
                              () => {
                                this.props.changeTab("mail_sequences", tab);
                                this.props.tabNavigation({
                                  slug: "mail_sequences"
                                });
                              }
                            );
                          }}
                          onLockPress={() => {
                            this.setState({ show_mail_menu: false });
                          }}
                          renderLockedChildren={({ hovering }) => {
                            return (
                              <>
                                <SelectItem
                                  primary={true}
                                  noPress={true}
                                  pressedIn={hovering}
                                  select_type="icon"
                                  select_icon="lock"
                                  icon={"keyboard-arrow-right"}
                                >
                                  Mail Sequences
                                </SelectItem>
                              </>
                            );
                          }}
                          renderChildren={({ hovering }) => {
                            return (
                              <>
                                <SelectItem
                                  select_type="none"
                                  noPress={true}
                                  pressedIn={hovering}
                                  icon={"keyboard-arrow-right"}
                                >
                                  Mail Sequences
                                </SelectItem>
                              </>
                            );
                          }}
                        />
                      </Wrapper>
                    );
                  }}
                />
              ) : null}
            </Row>
            <Wrapper style={{ flex: 1 }}>
              <Row style={{ marginRight: 10, justifyContent: "flex-end" }}>
                {this.props.user.team_clearance_level > 0 && daysLeftOfTrial ? (
                  <>
                    <InlineButton
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "end_trial",
                          overlay: true
                        });
                      }}
                      primary={true}
                      // icon="star"
                      style={{
                        borderRadius: 30,
                        margin: 10,
                        marginRight: 5,
                        marginLeft: 5,
                        height: 40,
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: 0,
                        paddingBottom: 0,
                        backgroundColor: colors.orange_color_muted
                      }}
                      textStyle={{ fontSize: 13 }}
                      description="Start
                    your subscription now"
                    >
                      {daysLeftOfTrial} Days Left
                    </InlineButton>
                  </>
                ) : null}

                <CurrentCallSession />

                {/*!this.props.current_call_session &&
                this.props.user.team_clearance_level > 0 &&
                this.props.is_partner == 0 &&
                !daysLeftOfTrial ? (
                  <>
                    <InlineButton
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "share_and_earn",
                          overlay: true
                        });
                      }}
                      primary={true}
                      icon="star"
                      style={{
                        borderRadius: 30,
                        margin: 10,
                        marginRight: 5,
                        marginLeft: 5,
                        height: 40,
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: 0,
                        paddingBottom: 0,
                        backgroundColor: colors.orange_color_muted
                      }}
                    >
                      Earn $10
                    </InlineButton>
                  </>
                ) : null*/}
                {this.props.user.team_clearance_level > 0 ? (
                  <Search {...this.props} />
                ) : null}

                <IconButton
                  tooltip="Help & Support"
                  icon_color={colors.white_text_color}
                  icon={"contact-support"}
                  borderColor={colors.white_text_color}
                  image_size={36}
                  no_border={true}
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "help_center",
                      overlay: true
                    });
                    //displayIntercom();
                  }}
                  size={22}
                  style={{
                    borderRadius: 0,
                    margin: 0,
                    height: 60,
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: 0,
                    paddingBottom: 0
                  }}
                />
                {/*
            <IconButton
              tooltip="Settings"
              pressedIn={local_tab == "settings"}
              icon_color={colors.white_text_color}
              icon={"settings"}
              borderColor={colors.white_text_color}
              image_size={36}
              no_border={true}
              onPress={() => {
                this.props.changeTab("settings", tab);
                this.props.tabNavigation({ slug: "settings" });
              }}
              size={22}
              style={{
                borderRadius: 0,
                margin: 0,
                height: 60,
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 0,
                paddingBottom: 0
              }}
            />
            */}

                <IconButton
                  tooltip="Tasks"
                  pressedIn={local_tab == "tasks"}
                  icon_color={colors.white_text_color}
                  icon={"assignment"}
                  show_notification={
                    this.props.user?.active_task_count &&
                    this.props.user?.active_task_count > 0
                      ? true
                      : false
                  }
                  notification_color={colors.orange_color}
                  notification_number={
                    this.props.user?.active_task_count
                      ? this.props.user.active_task_count
                      : null
                  }
                  notification_size={16}
                  notification_font_size={10}
                  notification_offset_y={10}
                  borderColor={colors.white_text_color}
                  image_size={36}
                  no_border={true}
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "tasks",
                      overlay: true
                    });
                  }}
                  style={{
                    borderRadius: 0,
                    margin: 0,
                    marginRight: 5,
                    height: 60,
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: 0,
                    paddingBottom: 0
                  }}
                  size={22}
                />
                {this.props.user.team_clearance_level > 0 && (
                  <IconButton
                    tooltip="Notifications"
                    icon={"notifications"}
                    onPress={() =>
                      this.props.pushSidePanel({
                        slug: "notifications",
                        overlay: true
                      })
                    }
                    borderColor={colors.white_text_color}
                    image_size={36}
                    no_border={true}
                    icon_color={colors.white_text_color}
                    style={{
                      borderRadius: 0,
                      margin: 0,
                      marginRight: 5,
                      height: 60,
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: 0,
                      paddingBottom: 0
                    }}
                    size={23}
                  />
                )}

                <CircleButton
                  icon="person"
                  button_type="profile_pic"
                  user={this.props.user}
                  show_notification={false}
                  notification_color={colors.active_color}
                  notification_icon={""}
                  borderColor={colors.white_text_color}
                  image_size={36}
                  no_border={true}
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "settings",
                      overlay: true
                    });
                  }}
                />
                {/*
              <PopoverMenu
                popover_width={300}
                renderComponent={({ pressedIn, hovering }) => {
                  return (
                    <CircleButton
                      icon="person"
                      noPress={true}
                      button_type="profile_pic"
                      user={this.props.user}
                      show_notification={false}
                      notification_color={colors.active_color}
                      notification_icon={""}
                      borderColor={colors.white_text_color}
                      image_size={36}
                      no_border={true}
                    />
                  );
                }}
                menu_items={[
                  {
                    title: "Team",
                    icon: "keyboard-arrow-right",
                    type: "normal",
                    select_type: "none",
                    selected: tab == "team",
                    onPress: () => {
                      this.props.changeTab("team", tab);
                      this.props.tabNavigation({ slug: "team" });
                    }
                  },
                  {
                    title: "Settings",
                    icon: "keyboard-arrow-right",
                    type: "normal",
                    select_type: "none",
                    selected: tab == "settings",
                    onPress: () => {
                      this.props.changeTab("settings", tab);
                      this.props.tabNavigation({ slug: "settings" });
                    }
                  },
                  this.props.device == "desktop" &&
                  this.props.is_partner == 1 &&
                  this.props.user.team_clearance_level > 0
                    ? {
                        title: "Partner Dashboard",
                        icon: "keyboard-arrow-right",
                        type: "normal",
                        select_type: "none",
                        selected: tab == "partner",
                        onPress: () => {
                          this.props.changeTab("partner", tab);
                          this.props.tabNavigation({ slug: "partner" });
                        }
                      }
                    : null,

                  {
                    title: "Request Feature",
                    icon: "keyboard-arrow-right",
                    type: "normal",
                    select_type: "none",
                    onPress: () => {
                      openUrl("https://dealmachine.featureupvote.com");
                    }
                  }
                ]}
              />
              */}
              </Row>
            </Wrapper>
          </Row>
        </Wrapper>
      </Gradient>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  route,
  drawer,
  partner,
  tasks,
  feature_toggle,
  onboarding,
  billing,
  dialer
}) => {
  const { token, user, user_partner_info } = auth;
  const { device, isIphoneX, isMobile, platform, desktopMobile } = native;
  const { colors } = settings;
  const { track_route } = route;
  const { tab } = drawer;
  const { is_partner } = partner;
  const { active_task_count } = tasks;
  const { source_of_truth, all_feature_metadata } = billing;
  const { mailCenter, listBuilderMap } = feature_toggle;
  const { current_call_session } = dialer;
  return {
    user,
    user_partner_info,
    token,
    device,
    isIphoneX,
    isMobile,
    platform,
    desktopMobile,
    colors,
    track_route,
    tab,
    is_partner,
    active_task_count,
    onboarding,
    source_of_truth,
    mailCenter,
    listBuilderMap,
    all_feature_metadata,
    current_call_session
  };
};

export default withRouter(
  connect(mapStateToProps, {
    tabNavigation,
    setModal,
    changeTab,
    pushSidePanel,
    searchDealMachine,
    selectActiveProperty,

    toggleModal,
    setActiveList,
    nextOnboardingStep,
    getDashboardStats
  })(NavBar)
);
