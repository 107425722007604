import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Bold,
  Wrapper,
  Copy,
  ContentfulRichText,
  ProgressBar
} from "app/NativeComponents/common";
import { Header1, Header3, QRCodeWrapper } from "app/NativeComponents/common";

import {
  logout,
  loadOnboardingText,
  startRedirect,
  updateUser,
  tabNavigation,
  getMarketingOnboardingData,
  updateUserSettings
} from "app/NativeActions";

import {
  GhostButton,
  InlineButton,
  List,
  OnboardingWrapper,
  SpinWrapper,
  AttentionBox
} from "app/NativeComponents/snippets";
import LeadItem from "app/DealMachineCore/reuseable/LeadItem";
class SelectLeadStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentful_content: null,
      contentful_loading: true,
      leads: [],
      leads_loading: false,
      selecting_lead: false,
      loading_id: null
    };
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/select-a-lead");
    }

    this.props.getMarketingOnboardingData({
      token: this.props.token,
      type: "get_leads_from_list",
      onLoading: () => {
        this.setState({ leads_loading: true });
      },
      onError: () => {
        this.setState({ leads_loading: true });
      },
      onSuccess: results => {
        this.setState({
          leads_loading: false,
          leads: results?.leads
        });
      }
    });
  }

  render() {
    const { contentful_content, contentful_loading } = this.state;
    const { colors, isMobile } = this.props;

    return (
      <Container title={"Select a lead"}>
        <OnboardingWrapper>
          <>
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: 25
              }}
            >
              <Header1
                style={{
                  textAlign: "center"
                }}
              >
                🤩 <Bold>That's a nice list you got there.</Bold>
              </Header1>
              <Header3
                style={{
                  textAlign: "center"
                }}
              >
                Let's analyze one of your leads. Select a lead to continue.
              </Header3>
            </Wrapper>

            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 25
              }}
            >
              <Copy style={{ textAlign: "center" }}>Tutorial step 2 of 4.</Copy>
              <ProgressBar
                style={{ marginTop: 10 }}
                color={colors.active_color}
                backgroundColor={colors.white_color}
                width={200}
                progress={2 / 4}
              />
            </Wrapper>

            <AttentionBox
              title={"Your leads are ready to rock."}
              description={
                "Notice how we've already included associated contacts for each lead? These contacts include email addresses and phone numbers, accurate ones too. This is a great way to get in touch with the right people. Select one of these leads to proceed."
              }
            />
            <Wrapper
              style={{
                padding: this.props.desktopMobile ? 0 : 25,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {this.state.leads_loading ? (
                <SpinWrapper text={"Loading Leads"} />
              ) : (
                <List
                  rowNumber={1}
                  style={{ flex: 1, alignSelf: "stretch" }}
                  infiniteScroll={false}
                  items={this.state.leads}
                  itemStructure={({ item, index }) => {
                    return (
                      <LeadItem
                        key={"item_" + index}
                        image_toggle={"none"}
                        include_add_button={false}
                        include_check_icon={false}
                        property={item}
                        loading={
                          this.state.loading_id === item?.id &&
                          this.state.selecting_lead
                        }
                        contact_preview={true}
                        onPress={() => {
                          this.props.updateUserSettings({
                            token: this.props.token,
                            type: "selected_onboarding_lead",
                            value: item?.deal?.id,
                            onLoading: () => {
                              this.setState({
                                selecting_lead: true,
                                loading_id: item?.id
                              });
                            },
                            onError: () => {
                              this.setState({
                                selecting_lead: false,
                                loading_id: item?.id
                              });
                            },
                            onSuccess: () => {
                              this.props.updateUser({
                                token: this.props.token,
                                type: "onboarding_stepthrough_step",
                                payload: {
                                  onboarding_stepthrough_current_step:
                                    "alma_step"
                                },
                                onLoading: () => {},
                                onError: () => {},
                                onSuccess: () => {
                                  this.setState({
                                    selecting_lead: false
                                  });
                                },
                                no_loading: true
                              });
                            }
                          });
                        }}
                      />
                    );
                  }}
                  canRefresh={false}
                  canLoadMore={false}
                />
              )}
            </Wrapper>
          </>
        </OnboardingWrapper>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, billing, settings, native }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth } = billing;
  const { isMobile, device, initialRedirect, desktopMobile } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    source_of_truth,
    desktopMobile
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,

  tabNavigation,
  getMarketingOnboardingData,
  updateUserSettings,
  updateUser
})(SelectLeadStep);
