import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  SmallLabel,
  UpsellButton
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  getLimitsForAllPlan,
  setPhotoToCameraroll,
  updateUserSettings,
  checkIfUserHasMetadata
} from "app/NativeActions";

class AppSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoCameraRoll: props.photoCameraRoll,
      hide_alma: props.user?.user_settings?.hide_alma
    };
  }
  handleBack() {
    this.props.popSidePanel();
  }

  render() {
    const { source_of_truth } = this.props;
    const limits = getLimitsForAllPlan(source_of_truth);
    const phone_plan =
      checkIfUserHasMetadata("dialer") && this.props.device !== "mobile";
    const call_tracking = checkIfUserHasMetadata("call_tracking");
    const { photoCameraRoll } = this.state;
    return (
      <Container>
        <NewHeader
          title="Application Settings"
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          {this.props.user?.team_clearance_level > 0 ? (
            <>
              <SelectItem
                select_type="none"
                icon="keyboard-arrow-right"
                onPress={() => {
                  this.props.pushSidePanel({
                    slug: "mail_settings",
                    overlay: true
                  });
                }}
              >
                Mail Settings
              </SelectItem>

              <SelectItem
                select_type="none"
                icon="keyboard-arrow-right"
                onPress={() => {
                  this.props.pushSidePanel({
                    slug: "manage_lead_tags",
                    overlay: true
                  });
                }}
              >
                Lead Tags
              </SelectItem>
              {this.props.user?.user_version >= 6 &&
              this.props.user.can_edit_custom_fields == 1 ? (
                <UpsellButton
                  meta_slug="custom_fields_limit"
                  onLockPressOverride={() => {
                    this.props.pushSidePanel({
                      slug: "custom_fields",
                      overlay: true
                    });
                  }}
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "custom_fields",
                      overlay: true
                    });
                  }}
                  renderLockedChildren={({ hovering }) => {
                    return (
                      <SelectItem
                        select_type="none"
                        icon={"lock"}
                        noPress={true}
                        pressedIn={hovering}
                      >
                        Custom Lead Fields
                      </SelectItem>
                    );
                  }}
                  renderChildren={({ hovering }) => {
                    return (
                      <SelectItem
                        select_type="none"
                        icon={"keyboard-arrow-right"}
                        noPress={true}
                        pressedIn={hovering}
                      >
                        Custom Lead Fields
                      </SelectItem>
                    );
                  }}
                />
              ) : (
                <SelectItem
                  select_type="none"
                  icon={
                    limits.number_of_custom_fields == 0 ||
                    this.props.user.can_edit_custom_fields == 0
                      ? "lock"
                      : "keyboard-arrow-right"
                  }
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "custom_fields",
                      overlay: true
                    });
                  }}
                  disabled={
                    limits.number_of_custom_fields == 0 ||
                    this.props.user.can_edit_custom_fields == 0
                      ? true
                      : false
                  }
                >
                  Custom Lead Fields
                </SelectItem>
              )}

              {this.props.user?.user_version >= 6 &&
              this.props.user.can_edit_custom_fields == 1 ? (
                <UpsellButton
                  meta_slug="custom_statuses"
                  onLockPressOverride={() => {
                    this.props.pushSidePanel({
                      slug: "custom_statuses",
                      overlay: true
                    });
                  }}
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "custom_statuses",
                      overlay: true
                    });
                  }}
                  renderLockedChildren={({ hovering }) => {
                    return (
                      <SelectItem
                        select_type="none"
                        icon={"lock"}
                        noPress={true}
                        pressedIn={hovering}
                      >
                        Lead Statuses
                      </SelectItem>
                    );
                  }}
                  renderChildren={({ hovering }) => {
                    return (
                      <SelectItem
                        select_type="none"
                        icon={"keyboard-arrow-right"}
                        noPress={true}
                        pressedIn={hovering}
                      >
                        Lead Statuses
                      </SelectItem>
                    );
                  }}
                />
              ) : (
                <SelectItem
                  select_type="none"
                  icon={
                    limits.include_custom_statuses == 0 ||
                    this.props.user.can_edit_custom_fields == 0
                      ? "lock"
                      : "keyboard-arrow-right"
                  }
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "custom_statuses",
                      overlay: true
                    });
                  }}
                  disabled={
                    limits.include_custom_statuses == 0 ||
                    this.props.user.can_edit_custom_fields == 0
                      ? true
                      : false
                  }
                >
                  Lead Statuses
                </SelectItem>
              )}

              {phone_plan &&
              (this.props.user.team_owner == 1 ||
                this.props.user.can_send_message == 1) ? (
                <SelectItem
                  select_type="none"
                  icon="keyboard-arrow-right"
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "dialer_settings",
                      overlay: true
                    });
                  }}
                >
                  Dialer Settings
                </SelectItem>
              ) : null}

              {call_tracking &&
              (this.props.user.team_owner == 1 ||
                this.props.user.can_send_mail == 1) ? (
                <SelectItem
                  select_type="none"
                  icon="keyboard-arrow-right"
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "call_tracking",
                      overlay: true
                    });
                  }}
                >
                  Call Tracking
                </SelectItem>
              ) : null}

              <SelectItem
                select_type="none"
                icon="keyboard-arrow-right"
                onPress={() => {
                  this.props.pushSidePanel({
                    slug: "basic_automation",
                    overlay: true
                  });
                }}
              >
                Basic Automation
              </SelectItem>
              {this.props.user?.user_version >= 6 ? (
                <>
                  {source_of_truth.subscription.status === "trialing" ? (
                    <SelectItem
                      select_type="none"
                      icon={"lock"}
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "end_trial",
                          data: {
                            title:
                              "You cannot access the API until your free trial ends.",
                            description:
                              "Pay now and start your subscription to export your leads."
                          }
                        });
                      }}
                    >
                      API
                    </SelectItem>
                  ) : (
                    <SelectItem
                      select_type="none"
                      icon={"keyboard-arrow-right"}
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "api",
                          overlay: true
                        });
                      }}
                    >
                      API
                    </SelectItem>
                  )}
                </>
              ) : (
                <SelectItem
                  select_type="none"
                  icon={!limits?.api_access ? "lock" : "keyboard-arrow-right"}
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "api",
                      overlay: true
                    });
                  }}
                  disabled={!limits?.api_access ? true : false}
                >
                  API
                </SelectItem>
              )}
            </>
          ) : null}
          <SelectItem
            select_type="none"
            icon="keyboard-arrow-right"
            onPress={() => {
              this.props.pushSidePanel({
                slug: "notification_settings",
                overlay: true
              });
            }}
          >
            Notifications
          </SelectItem>

          {this.props.device == "mobile" ? (
            <SelectItem
              select_type="toggle"
              selected={photoCameraRoll}
              onPress={() => {
                this.setState({
                  photoCameraRoll: !photoCameraRoll
                });
                this.props.setPhotoToCameraroll(!photoCameraRoll);
                this.props.updateUserSettings({
                  token: this.props.token,
                  type: "save_to_camera_roll",
                  value: !photoCameraRoll
                });
              }}
              description={
                "When you take a photo, a photo will always be saved to your camera roll."
              }
            >
              Save photos to camera roll?
            </SelectItem>
          ) : null}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors, photoCameraRoll } = settings;
  const { plan_modules, source_of_truth } = billing;

  return {
    token,
    user,
    device,
    colors,
    photoCameraRoll,
    plan_modules,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  getLimitsForAllPlan,
  setPhotoToCameraroll,
  updateUserSettings
})(AppSettings);
