import {
  ADD_DEAL,
  ADD_DEAL_FAIL,
  ADD_DEAL_SUCCESS,
  CLEAR_AUTOCOMPLETE,
  CLEAR_NEXT_PLANNED_PROPERTY,
  SET_COMPLETED_ROUTE,
  ERROR_MESSAGE,
  FOCUS_SEARCH_BAR,
  GET_AUTOCOMPLETE,
  GET_AUTOCOMPLETE_FAIL,
  GET_AUTOCOMPLETE_SUCCESS,
  GET_MAP_PROPERTIES,
  GET_MAP_PROPERTIES_FAIL,
  UPDATE_MAP_LOCATION,
  SELECT_ACTIVE_PROPERTY,
  SELECT_MULTIPLE_PROPERTIES,
  SELECT_ACTIVE_PROPERTY_SUCCESS,
  SELECT_ACTIVE_PROPERTY_FAIL,
  GET_MAP_PROPERTIES_SUCCESS,
  GET_MAP_REVERSE_GEOCODE,
  GET_MAP_REVERSE_GEOCODE_FAIL,
  GET_MAP_REVERSE_GEOCODE_SUCCESS,
  GET_NEXT_PLANNED_PROPERTY,
  GET_NEXT_PLANNED_PROPERTY_FAIL,
  GET_NEXT_PLANNED_PROPERTY_SUCCESS,
  COMPLETE_PLANNED_PROPERTY,
  COMPLETE_PLANNED_PROPERTY_FAIL,
  COMPLETE_PLANNED_PROPERTY_SUCCESS,
  HIDE_PROPERTY_LIST,
  IS_LOADING,
  REFRESH_MAP_PROPERTIES,
  SET_LAST_LOCATION,
  SUCCESS_MESSAGE,
  SWITCH_MAP_MODE,
  TOGGLE_TAP_TO_ADD,
  TRIGGER_LOGOUT,
  SET_USER_LOCATION,
  SET_ALL_PERMISSIONS,
  GET_HIGHLIGHT_TEMPLATES_SUCCESS,
  SET_APPLIED_HIGHLIGHT,
  SET_STREET_VIEW_COORDS,
  SET_STREET_VIEW_LINE,
  TOGGLE_MAP_MOVING,
  UPDATE_MAIN_MAP_LOCATION,
  SELECT_ACTIVE_COORDINATES,
  TRIGGER_NEXT_PLANNED_PROPERTY,
  UPDATE_MAP_FILTERS,
  UPDATE_LEAD_FILTERS
} from "app/DealMachineCore/types";

import { appRedirect } from "app/NativeActions";

import api from "app/DealMachineCore/apis/DealMachineAPIV2";

const dm_api = api.create();

export const getMapProperties = ({
  token,
  load_type,
  bounds,
  property_bounds,
  limit,
  mobile,
  heatmap,
  dont_include_properties,
  dont_include_deals,
  dont_include_route_sections,
  map_type = "map",
  routes_only = false,
  route_plan = false,
  filters = {},
  route_id = null
}) => {
  return dispatch => {
    switch (load_type) {
      default:
        dispatch({ type: GET_MAP_PROPERTIES });
        break;

      case "refresh":
        dispatch({ type: REFRESH_MAP_PROPERTIES });
        break;
    }

    dm_api
      .mapProperties({
        token,
        map_type,
        routes_only,
        route_plan,
        bounds,
        property_bounds,
        limit,
        mobile,
        heatmap,
        filters,
        dont_include_properties:
          load_type == "refresh" ? "" : dont_include_properties,
        dont_include_deals: load_type == "refresh" ? "" : dont_include_deals,
        dont_include_route_sections,
        route_id
      })
      .then(response => {
        if (response.problem != null) {
          getMapPropertiesFail(dispatch, response.problem);
        } else if (response.data.error != false) {
          getMapPropertiesFail(dispatch, response.data.error);
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          getMapPropertiesSuccess(dispatch, response.data.results);
        }
      });
  };
};

const getMapPropertiesFail = (dispatch, error) => {
  dispatch({ type: GET_MAP_PROPERTIES_FAIL, payload: error });
};

const getMapPropertiesSuccess = (dispatch, results) => {
  dispatch({ type: GET_MAP_PROPERTIES_SUCCESS, payload: results });
};
export const clearNextPlannedProperty = () => {
  return dispatch => {
    dispatch({ type: CLEAR_NEXT_PLANNED_PROPERTY });
  };
};

export const setCompletedRoute = toggle => {
  return dispatch => {
    dispatch({ type: SET_COMPLETED_ROUTE, payload: toggle });
  };
};

export const completePlannedProperty = (token, prev_id, planned_route_id) => {
  return dispatch => {
    dispatch({ type: COMPLETE_PLANNED_PROPERTY });

    dm_api
      .completePlannedProperty({
        token,
        prev_id,
        planned_route_id
      })
      .then(response => {
        if (response.problem != null) {
          completePlannedPropertyFail(dispatch, response.problem);
        } else if (response.data.error != false) {
          completePlannedPropertyFail(dispatch, response.data.error);
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          completePlannedPropertySuccess(dispatch, response.data.result);
        }
      });
  };
};

export const getNextPlannedProperty = ({
  token,
  prev_id,
  task_id,
  planned_route_id,
  dont_include_properties,
  dont_include_deals,
  dont_include_route_sections,
  dont_include,
  filters = {},
  new_filters,
  list_id,
  user_location = {},
  zip = "",
  state = "",
  city = "",
  location_type = "",
  drawing_coordinates = [],

  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    //dispatch({ type: CLEAR_NEXT_PLANNED_PROPERTY });
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }
    dispatch({ type: GET_NEXT_PLANNED_PROPERTY });
    return new Promise((resolve, reject) => {
      dm_api
        .nextPlannedProperty({
          token,
          prev_id,
          planned_route_id,
          task_id,
          filters,
          new_filters: new_filters ? JSON.stringify(new_filters) : "",
          list_id,
          user_location,
          dont_include_properties,
          dont_include_deals,
          dont_include_route_sections,
          dont_include,
          location_type,
          zip,
          state,
          city,
          drawing_coordinates: drawing_coordinates
            ? JSON.stringify(drawing_coordinates)
            : ""
        })
        .then(response => {
          if (response.problem != null) {
            if (onError) {
              onError();
            }
            getNextPlannedPropertyFail(dispatch, response.problem);
            reject(true);
          } else if (response.data.error != false) {
            if (onError) {
              onError();
            }
            getNextPlannedPropertyFail(dispatch, response.data.error);
            reject(true);
            if (response.data.valid == "invalid") {
              dispatch({ type: TRIGGER_LOGOUT, payload: true });
            }
          } else {
            resolve(response.data.results);
            if (onSuccess) {
              onSuccess();
            }
            getNextPlannedPropertySuccess(dispatch, response.data.results);
          }
        });
    });
  };
};

export const getNextPlannedPropertyV2 = ({
  token,
  prev_id,
  task_id,
  planned_route_id,
  dont_include_properties,
  dont_include_deals,
  dont_include_route_sections,
  dont_include,
  filters = {},
  new_filters,
  list_id,
  user_location = {},
  zip = "",
  state = "",
  city = "",
  location_type = "",
  drawing_coordinates = [],

  property_flags = null,
  property_types = null,
  property_flags_and_or = null,
  value_range_min = null,
  value_range_max = null,
  price_type = null,
  beds_min = null,
  baths_min = null,
  use_beds_exact = null,
  search_locations = null,

  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    //dispatch({ type: CLEAR_NEXT_PLANNED_PROPERTY });
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }
    dispatch({ type: GET_NEXT_PLANNED_PROPERTY });
    return new Promise((resolve, reject) => {
      dm_api
        .nextPlannedPropertyV2({
          token,
          prev_id,
          planned_route_id,
          task_id,
          filters,
          new_filters: new_filters ? JSON.stringify(new_filters) : "",
          list_id,
          user_location,
          dont_include_properties,
          dont_include_deals,
          dont_include_route_sections,
          dont_include,
          location_type,
          zip,
          state,
          city,
          drawing_coordinates: drawing_coordinates
            ? JSON.stringify(drawing_coordinates)
            : "",
          property_flags,
          property_types,
          property_flags_and_or,
          value_range_min,
          value_range_max,
          price_type,
          beds_min,
          baths_min,
          use_beds_exact,
          search_locations: search_locations
            ? JSON.stringify(search_locations)
            : null
        })
        .then(response => {
          if (response.problem != null) {
            if (onError) {
              onError();
            }
            getNextPlannedPropertyFail(dispatch, response.problem);
            reject(true);
          } else if (response.data.error != false) {
            if (onError) {
              onError();
            }
            getNextPlannedPropertyFail(dispatch, response.data.error);
            reject(true);
            if (response.data.valid == "invalid") {
              dispatch({ type: TRIGGER_LOGOUT, payload: true });
            }
          } else {
            resolve(response.data.results);
            if (onSuccess) {
              onSuccess();
            }
            getNextPlannedPropertySuccess(dispatch, response.data.results);
          }
        });
    });
  };
};

const getNextPlannedPropertyFail = (dispatch, error) => {
  dispatch({ type: IS_LOADING, payload: false });
  dispatch({ type: GET_NEXT_PLANNED_PROPERTY_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const getNextPlannedPropertySuccess = (dispatch, results) => {
  dispatch({ type: IS_LOADING, payload: false });
  dispatch({ type: GET_NEXT_PLANNED_PROPERTY_SUCCESS, payload: results });
};

const completePlannedPropertySuccess = (dispatch, results) => {
  dispatch({ type: COMPLETE_PLANNED_PROPERTY_SUCCESS, payload: results });
};
const completePlannedPropertyFail = (dispatch, error) => {
  dispatch({ type: COMPLETE_PLANNED_PROPERTY_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

export const updateMapLocation = ({
  coordinates,
  zoom,
  heading,
  active_property,
  refresh = false
}) => {
  return {
    type: UPDATE_MAP_LOCATION,
    payload: { coordinates, zoom, heading, active_property, refresh }
  };
};

export const setUserLocation = coordinates => {
  return {
    type: SET_USER_LOCATION,
    payload: coordinates
  };
};

export const setAllDevicePermission = info => {
  return {
    type: SET_ALL_PERMISSIONS,
    payload: info
  };
};

export const setLastLocation = ({ coordinates }) => {
  return {
    type: SET_LAST_LOCATION,
    payload: coordinates
  };
};
export const selectActiveCoordinates = coordinates => {
  return { type: SELECT_ACTIVE_COORDINATES, payload: coordinates };
};

export const selectMultipleProperties = properties => {
  return { type: SELECT_MULTIPLE_PROPERTIES, payload: properties };
};
export const selectActiveProperty = (token, property, ignore_move = false) => {
  return dispatch => {
    if (property) {
      property.ignore_move = ignore_move;
      dispatch({ type: SELECT_ACTIVE_PROPERTY, payload: property });
      if (token) {
        dm_api
          .getProperty({
            token,
            property_id: property.property_id || 0,
            deal_id:
              !property.property_id && property.deal && !property.deal?.removed
                ? property.deal?.id
                : property.lead_id
                ? property.lead_id
                : 0 || 0
          })
          .then(response => {
            if (response.problem != null) {
              selectActivePropertyFail(dispatch, response.problem);
            } else if (response.data.error != false) {
              selectActivePropertyFail(dispatch, response.data.error);
              if (response.data.valid == "invalid") {
                dispatch({ type: TRIGGER_LOGOUT, payload: response });
              }
            } else {
              selectActivePropertySuccess(
                dispatch,
                response.data.results.property
              );
            }
          });
      }
    } else {
      selectActivePropertySuccess(dispatch, null);
    }
  };
};

const selectActivePropertyFail = (dispatch, error) => {
  dispatch({ type: SELECT_ACTIVE_PROPERTY_FAIL, payload: error });
};

const selectActivePropertySuccess = (dispatch, results) => {
  dispatch({ type: SELECT_ACTIVE_PROPERTY_SUCCESS, payload: results });
};

export const focusSearchBar = toggle => {
  return {
    type: FOCUS_SEARCH_BAR,
    payload: toggle
  };
};

export const getReverseGeocode = ({
  token,
  coordinate,
  no_property = false,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: GET_MAP_REVERSE_GEOCODE });
    }

    dm_api
      .reverseGeocode({
        token,
        coordinate,
        no_property
      })
      .then(response => {
        if (response.problem != null) {
          if (onError) {
            onError();
          } else {
            getReverseGeocodeFail(dispatch, response.problem);
          }
        } else if (response.data.error != false) {
          if (onError) {
            onError();
          } else {
            getReverseGeocodeFail(dispatch, response.data.error);
          }
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (onSuccess) {
            onSuccess(response.data.results);
          } else {
            getReverseGeocodeSuccess(dispatch, response.data.results);
          }
        }
      });
  };
};

const getReverseGeocodeFail = (dispatch, error) => {
  dispatch({ type: GET_MAP_REVERSE_GEOCODE_FAIL, payload: error });
};

const getReverseGeocodeSuccess = (dispatch, results) => {
  dispatch({ type: GET_MAP_REVERSE_GEOCODE_SUCCESS, payload: results });
};

export const getAutocomplete = ({
  token,
  search,
  limit = 5,
  type,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: GET_AUTOCOMPLETE });
    }

    dm_api
      .autocomplete({
        token,
        search,
        limit,
        type
      })
      .then(response => {
        if (response.problem != null) {
          if (onError) {
            onError();
          } else {
            getAutocompleteFail(dispatch, response.problem);
          }
        } else if (response.data.error != false) {
          if (onError) {
            onError();
          } else {
            getAutocompleteFail(dispatch, response.data.error);
          }
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (onSuccess) {
            onSuccess(response.data.results);
          } else {
            getAutocompleteSuccess(dispatch, response.data.results);
          }
        }
      });
  };
};

export const getAutocompleteMap = ({
  token,
  search,
  centerLat = null,
  centerLng = null,
  types = null,

  onLoading = null,
  onError = null,
  onSuccess = null,

  type = "old"
}) => {
  return dispatch => {
    onLoading();

    if (type === "new") {
      dm_api
        .autocompleteMapNew({
          token,
          search,
          centerLat,
          centerLng,
          types
        })
        .then(response => {
          if (response.problem != null) {
            onError();
          } else if (response.data.error != false) {
            onError();

            if (response.data.valid == "invalid") {
              dispatch({ type: TRIGGER_LOGOUT, payload: true });
            }
          } else {
            onSuccess(response.data.results);
          }
        });
    } else {
      dm_api
        .autocompleteMap({
          token,
          search,
          centerLat,
          centerLng,
          types
        })
        .then(response => {
          if (response.problem != null) {
            onError();
          } else if (response.data.error != false) {
            onError();

            if (response.data.valid == "invalid") {
              dispatch({ type: TRIGGER_LOGOUT, payload: true });
            }
          } else {
            onSuccess(response.data.results);
          }
        });
    }
  };
};

const getAutocompleteFail = (dispatch, error) => {
  dispatch({ type: GET_AUTOCOMPLETE_FAIL, payload: error });
};

const getAutocompleteSuccess = (dispatch, results) => {
  dispatch({ type: GET_AUTOCOMPLETE_SUCCESS, payload: results });
};

export const clearAutocomplete = () => {
  return {
    type: CLEAR_AUTOCOMPLETE
  };
};

export const saveRecentSearch = ({
  token,
  search_type,
  full_text,
  search_text,
  property_id,
  lead_id,
  result_type,
  data,
  latitude,
  longitude,
  location_type,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();

    dm_api
      .saveRecentSearch({
        token,
        search_type,
        full_text,
        search_text,
        property_id,
        lead_id,
        result_type,
        latitude,
        longitude,
        location_type,
        data: data ? JSON.stringify(data) : ""
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getRecentSearch = ({
  token,
  search_type,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();

    dm_api
      .getRecentSearch({
        token,
        search_type
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const addDeal = ({
  token,
  add_type,
  property = null,
  route_id = 0,
  address,
  address2,
  city,
  state,
  zip,
  devicetype,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dispatch({ type: ADD_DEAL, payload: { property: property } });

    dm_api
      .addDeal({
        token,
        property,
        route_id,
        address,
        address2,
        city,
        state,
        zip,
        devicetype
      })
      .then(response => {
        if (response.problem != null) {
          addDealFail({
            dispatch,
            error: response.problem,
            property,
            add_type
          });
          onError();
        } else if (
          response.data.error != false &&
          response.data.results.error_type == "exceeded_total_lead_limit"
        ) {
          addDealFail({
            dispatch,
            property,
            add_type
          });
          onError(response.data.results);
        } else if (response.data.error != false) {
          addDealFail({
            dispatch,
            error: response.data.error,
            property,
            add_type
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
          onError();
        } else {
          addDealSuccess({
            dispatch,
            results: response.data.results,
            property,
            add_type,
            onSuccess
          });
        }
      });
  };
};

const addDealFail = ({ dispatch, error, property, add_type }) => {
  dispatch({ type: ADD_DEAL_FAIL, payload: { error, property } });

  if (add_type == "manual") {
    dispatch({ type: IS_LOADING, payload: false });
  }

  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const addDealSuccess = ({
  dispatch,
  results,
  property,
  add_type,
  onSuccess = null
}) => {
  dispatch({
    type: ADD_DEAL_SUCCESS,
    payload: {
      ...results,
      added_property: results.property,
      property,
      add_type
    }
  });
  if (
    results?.previously_added_lead &&
    (add_type == "manual" || add_type == "manual_map")
  ) {
    dispatch({
      type: ERROR_MESSAGE,
      payload: {
        message: "This lead already exists in your account.",
        title: "Error"
      }
    });
  }
  if (onSuccess) {
    onSuccess(results.property);
  } else if (add_type == "manual" || add_type == "manual_map") {
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: {
        message: "You've successfully added a property.",
        title: "Success!"
      }
    });

    appRedirect({
      dispatch,
      redirect: "property",
      payload: {
        property_id: results?.property?.property_id,
        map: "default",
        add_type
      }
    });
  }
};

export const toggleTapToAdd = toggle => {
  return {
    type: TOGGLE_TAP_TO_ADD,
    payload: toggle
  };
};

export const switchMapMode = mode => {
  return {
    type: SWITCH_MAP_MODE,
    payload: mode
  };
};

export const hidePropertyList = toggle => {
  return {
    type: HIDE_PROPERTY_LIST,
    payload: toggle
  };
};

export const getHighlightTemplates = ({
  token,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getHighlightTemplates({
        token
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
        } else {
          dispatch({
            type: GET_HIGHLIGHT_TEMPLATES_SUCCESS,
            payload: response.data.results
          });
          onSuccess(response.data.results);
        }
      });
  };
};

export const getDriverDefaultHighlights = ({
  token,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getDriverDefaultHighlights({
        token
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
        } else {
          dispatch({
            type: GET_HIGHLIGHT_TEMPLATES_SUCCESS,
            payload: response.data.results
          });
          onSuccess(response.data.results);
        }
      });
  };
};

export const getPropertiesOnMap = ({
  token,
  type = "properties_on_map",
  bounds,
  route_id,
  highlight,
  load_type,
  filters,
  dont_include,
  limit,
  page = 0,
  sort_by,
  drawing_coordinates = null,
  property_flags = null,
  property_types = null,
  property_flags_and_or,

  value_range_min,
  value_range_max,
  baths_min,
  beds_min,
  use_beds_exact,
  price_type,
  viewing_type,
  zip,
  search_locations,
  include_owners = false,
  include_residents = false,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getPropertiesOnMap({
        token,
        type,
        bounds,
        route_id,
        highlight,
        load_type,
        dont_include,
        limit,
        page,
        sort_by,
        drawing_coordinates: drawing_coordinates
          ? JSON.stringify(drawing_coordinates)
          : null,
        filters: filters ? JSON.stringify(filters) : null,
        property_flags,
        property_types,
        property_flags_and_or,

        value_range_min,
        value_range_max,
        baths_min,
        beds_min,
        use_beds_exact,
        price_type,
        viewing_type,
        zip,
        include_owners,
        include_residents,
        search_locations: search_locations
          ? JSON.stringify(search_locations)
          : null
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getRoutesOnMap = ({
  token,
  bounds,
  route_id = null,
  dont_include_route_sections,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getRoutesOnMap({
        token,
        bounds,
        route_id,
        dont_include_route_sections
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const updateMapFilters = filters => {
  return {
    type: UPDATE_MAP_FILTERS,
    payload: filters
  };
};

export const updateLeadFilters = filters => {
  return {
    type: UPDATE_LEAD_FILTERS,
    payload: filters
  };
};

export const setAppliedHighlight = ({ applied_highlight }) => {
  return {
    type: SET_APPLIED_HIGHLIGHT,
    payload: { applied_highlight }
  };
};
export const getMorePropertyInfo = ({
  token,
  type,
  owner_hash,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getMorePropertyInfo({
        token,
        type,
        owner_hash
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data?.error != false) {
          onError();

          if (response.data?.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: response });
          }
        } else {
          onSuccess(response?.data?.results);
        }
      });
  };
};
export const getPropertyNew = ({
  token,
  property_id,
  lead_id,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getProperty({
        token,
        property_id: property_id || 0,
        deal_id: lead_id || 0
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data?.error != false) {
          onError();

          if (response.data?.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: response });
          }
        } else {
          onSuccess(response?.data?.results);
        }
      });
  };
};

export const setStreetViewCoords = ({
  latitude,
  longitude,
  heading = 0,
  pitch = 0,
  zoom = 15
}) => {
  return {
    type: SET_STREET_VIEW_COORDS,
    payload: { latitude, longitude, heading, pitch, zoom }
  };
};
export const setStreetViewLine = linestring => {
  return {
    type: SET_STREET_VIEW_LINE,
    payload: linestring
  };
};

export const setMapProperties = properties => {
  return { type: GET_MAP_PROPERTIES_SUCCESS, payload: { properties } };
};
export const toggleMapMoving = toggle => {
  return { type: TOGGLE_MAP_MOVING, payload: toggle };
};

export const renderLabelText = ({ property, property_label_type }) => {
  let label = "";
  if (property_label_type) {
    if (property_label_type == "property_address_range") {
      if (!property.property_address_range && !!property.owner_name) {
        if (!!property.owner_lastname) {
          label = property.owner_lastname;
        } else {
          label = property.owner_name;
        }
      } else {
        label = property.property_address_range;
      }
    } else if (property_label_type == "owner_lastname") {
      if (!!property.owner_lastname) {
        label = property.owner_lastname;
      } else {
        label = property.owner_name;
      }
    } else if (
      property_label_type == "saleprice" ||
      property_label_type == "calculated_total_value" ||
      property_label_type == "EstimatedValue"
    ) {
      let num = parseInt(property[property_label_type]);
      if (num >= 1000000) {
        label = (num / 1000000).toFixed(0).replace(/\.0$/, "") + "M";
      } else if (num >= 1000) {
        label = (num / 1000).toFixed(0).replace(/\.0$/, "") + "K";
      }
    } else if (
      property_label_type == "equity_percent" &&
      property[property_label_type] != null
    ) {
      label = Math.round(property[property_label_type]) + "%";
    } else if (property[property_label_type]) {
      label = property[property_label_type];
    }
  }

  if (label == 0) {
    return "--";
  } else if (
    !label ||
    label == "null" ||
    label === null ||
    label == undefined ||
    label == 0
  ) {
    return "";
  }

  return label;
};

export const updateMainMapLocation = location => {
  return {
    type: UPDATE_MAIN_MAP_LOCATION,
    payload: location
  };
};

export const triggerNextPlannedProperty = prev_id => {
  return {
    type: TRIGGER_NEXT_PLANNED_PROPERTY,
    payload: prev_id
  };
};
