import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Card,
  Container,
  Copy,
  Row,
  Wrapper,
  Scroll,
  Bold
} from "app/NativeComponents/common";
import {
  BottomNavBar,
  Carousel,
  ConfirmLabel,
  NewHeader,
  SelectItem,
  SmallLabel
} from "app/NativeComponents/snippets";
import {
  popSidePanel,
  linkOrUnlinkPropertyToContact,
  toTitleCase,
  getContacts,
  pushSidePanel,
  formatAddress
} from "app/NativeActions";

import PhoneItem from "./PhoneItem";
import EmailItem from "./EmailItem";
import OwnerPortfolio from "./OwnerPortfolio";
import BasicInformation from "./BasicInformation";
import ProfessionalInformation from "./ProfessionalInformation";
import NavigationService from "app/Router/NavigationService";
import PersonItem from "app/DealMachineCore/components/Property/OwnerInfo/PersonItem";
import PersonFlags from "./PersonFlags";

class Contact extends Component {
  constructor(props) {
    super(props);
    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;
    this.state = {
      linking_loading: false,
      person: panel_data?.person || null,
      property: panel_data?.property || null,
      expanded: panel_data?.expanded || false,
      propertyUpdated: panel_data?.propertyUpdated || null
    };
    this._scroll_view = React.createRef();

    this.scrollToSection = this.scrollToSection.bind(this);
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentDidMount() {
    if (this.props.device == "mobile") {
      NavigationService.setTopLevelNavigator(this.props.navigation);
    }

    if (this.state.person) {
      this.getPersonData(this.state.person);
    } else {
      const panel_id =
        this.props.device == "mobile"
          ? this.props?.route?.params?.panel_id
          : this.props.panel_id;
      if (!!panel_id) {
        this.getPersonData(null, panel_id);
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    const prev_panel_data =
      prevProps.device == "mobile"
        ? prevProps?.route?.params?.panel_data
        : prevProps.panel_data;
    const panel_id =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_id
        : this.props.panel_id;

    const prev_panel_id =
      prevProps.device == "mobile"
        ? prevProps?.route?.params?.panel_id
        : prevProps.panel_id;
    if (
      (panel_data?.person &&
        panel_data?.person?.individual_key !==
          prev_panel_data?.person?.individual_key) ||
      panel_id != prev_panel_id
    ) {
      this.getPersonData(panel_data?.person, panel_id);
    }
  }

  getPersonData(person, person_id) {
    if (person || person_id) {
      this.props.getContacts({
        token: this.props.token,
        person_id: person ? person?.individual_key : person_id,
        onLoading: () => {
          this.setState({
            person_loading: true
          });
        },
        onError: () => {
          this.setState({
            person_loading: false
          });
        },
        onSuccess: results => {
          this.setState(
            {
              person_loading: false,
              person:
                results?.contacts?.length > 0 &&
                (results.contacts[0]?.individual_key ==
                  this.state?.person?.individual_key ||
                  !this.state?.person)
                  ? {
                      ...this.state.person,
                      ...results?.contacts[0]
                    }
                  : this.state.person
            },
            () => {}
          );
        }
      });
    }
  }

  scrollToSection(section) {
    const { device } = this.props;

    if (
      device === "desktop" &&
      this._scroll_view &&
      this._scroll_view.current &&
      section?.id
    ) {
      const elmnt = document.getElementById(section?.id);
      let top = this.state.expanded
        ? elmnt.offsetTop - 85
        : elmnt.offsetTop - 85;

      this._scroll_view.current.scroll({
        top,
        behavior: "smooth"
      });
    } else if (
      device === "mobile" &&
      this._scroll_view &&
      this._scroll_view.current &&
      section?.offset
    ) {
      let y = section.offset - 15;
      this._scroll_view.current.scrollTo({
        x: 0,
        y: y,
        animated: true
      });
    }
  }

  render() {
    const { isMobile, device, colors } = this.props;
    const { person, property } = this.state;

    let phones = [];
    if (!!person?.phone_1 && !person?.phone_1_blocked) {
      phones.push({
        number: person.phone_1,
        activity_status: person.phone_1_activity_status,
        contability_score: person.phone_1_contability_score,
        usage_2_months: person.phone_1_usage_2_months,
        usage_12_months: person.phone_1_usage_12_months,
        owner: person?.phone_1_owner,
        type: person?.phone_1_type,
        prepaid_indicator: person?.phone_1_prepaid_indicator,
        do_not_call_flag: person?.phone_1_do_not_call,
        call_status: person?.phone_1_status,
        known_litigator: person?.phone_1_known_litigator
      });
    }

    if (!!person?.phone_2 && !person?.phone_2_blocked) {
      phones.push({
        number: person.phone_2,
        activity_status: person.phone_2_activity_status,
        contability_score: person.phone_2_contability_score,
        usage_2_months: person.phone_2_usage_2_months,
        usage_12_months: person.phone_2_usage_12_months,
        owner: person?.phone_2_owner,
        type: person?.phone_2_type,
        prepaid_indicator: person?.phone_2_prepaid_indicator,
        do_not_call_flag: person?.phone_2_do_not_call,
        call_status: person?.phone_2_status,
        known_litigator: person?.phone_2_known_litigator
      });
    }

    if (!!person?.phone_3 && !person?.phone_3_blocked) {
      phones.push({
        number: person.phone_3,
        activity_status: person.phone_3_activity_status,
        contability_score: person.phone_3_contability_score,
        usage_2_months: person.phone_3_usage_2_months,
        usage_12_months: person.phone_3_usage_12_months,
        owner: person?.phone_3_owner,
        type: person?.phone_3_type,
        prepaid_indicator: person?.phone_3_prepaid_indicator,
        do_not_call_flag: person?.phone_3_do_not_call,
        call_status: person?.phone_3_status,
        known_litigator: person?.phone_3_known_litigator
      });
    }

    let emails = [];
    if (!!person?.email_address_1 && !person?.email_address_1_blocked) {
      emails.push({
        email: person.email_address_1,
        linkage_score: person.email_address_1_linkagescore
      });
    }

    if (!!person?.email_address_2 && !person?.email_address_2_blocked) {
      emails.push({
        email: person.email_address_2,
        linkage_score: person.email_address_2_linkagescore
      });
    }

    if (!!person?.email_address_3 && !person?.email_address_3_blocked) {
      emails.push({
        email: person.email_address_3,
        linkage_score: person.email_address_3_linkagescore
      });
    }

    let related_contacts = [];
    if (!!person?.related_contacts) {
      related_contacts = JSON.parse(JSON.stringify(person?.related_contacts));
    }
    const formatted_property_address = formatAddress({
      address: {
        address: property?.property_address,
        address2: property?.property_address2,
        address_city: property?.property_address_city,
        address_state: property?.property_address_state,
        address_zip: property?.property_address_zip,
        latitude: property?.location?.latitude,
        longitude: property?.location?.longitude
      }
    });

    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: colors.card_color
        }}
      >
        <NewHeader
          title={"View Contact"}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        {person ? (
          <Scroll style={{ flex: 1 }} scroll_ref={this._scroll_view}>
            <Wrapper
              id={"person-information-" + person?.individual_key}
              onLayout={event => {
                const { x, y, width, height } = event?.nativeEvent?.layout;
                this.setState({
                  offset: y
                });
              }}
            >
              <SelectItem
                select_type={"profile_pic"}
                pressedIn={false}
                noPress={true}
                label={""}
                subtext={""}
                selected={true}
                user={{
                  firstname: person?.given_name,
                  lastname: person?.surname,
                  email: person?.email_address_1,
                  image: null
                }}
                renderContent={() => {
                  return (
                    <Wrapper>
                      <Row>
                        <Wrapper>
                          <Copy style={{ color: colors.actionable_text_color }}>
                            <Bold>{person?.full_name}</Bold>
                          </Copy>
                        </Wrapper>
                      </Row>
                      {!!person?.person_flags ? (
                        <Wrapper style={{ paddingTop: 10 }}>
                          <PersonFlags person={person} />
                        </Wrapper>
                      ) : null}
                    </Wrapper>
                  );
                }}
              />
              <BasicInformation
                {...this.props}
                person={person}
                scrollToSection={this.scrollToSection}
                expanded={this.state.expanded}
              />
              <OwnerPortfolio
                owner_hash={person?.owner_hash}
                scrollToSection={this.scrollToSection}
              />

              {!!phones.length > 0 ? (
                <Card
                  label={"Phone Numbers:"}
                  style={{ paddingTop: 10, marginTop: 15 }}
                >
                  {phones.map((phone, i) => {
                    return (
                      <PhoneItem
                        key={"phone_" + i}
                        phone={phone}
                        contact={person}
                        property={property}
                      />
                    );
                  })}
                </Card>
              ) : null}

              {!!emails.length > 0 ? (
                <Card
                  label={"Email Addresses:"}
                  style={{ paddingTop: 10, marginTop: 15 }}
                >
                  {emails.map((email, i) => {
                    return <EmailItem key={"email_" + i} email={email} />;
                  })}
                </Card>
              ) : null}
              <ProfessionalInformation
                {...this.props}
                person={person}
                scrollToSection={this.scrollToSection}
                expanded={this.state.expanded}
              />
            </Wrapper>

            {related_contacts &&
            related_contacts?.length > 0 &&
            related_contacts.filter(
              ({ given_name, surname }) => !!given_name || !!surname
            ).length > 0 ? (
              <>
                <SmallLabel>
                  Related contacts: ({related_contacts.length})
                </SmallLabel>
                {related_contacts.length == 1 &&
                (!!related_contacts[0].surname ||
                  !!related_contacts[0].given_name) ? (
                  <Wrapper
                    style={{
                      padding: 5,
                      justifyContent: "center"
                    }}
                  >
                    <PersonItem
                      person={related_contacts[0]}
                      expanded={this.state.expanded}
                      no_options={false}
                      use_person_flags={false}
                      property={property}
                      propertyUpdated={this.state.propertyUpdated}
                    />
                  </Wrapper>
                ) : (
                  <Wrapper
                    style={{
                      marginBottom: 40,
                      justifyContent: "center"
                    }}
                    className={"contact-people " + this.props.dark_mode}
                  >
                    <Carousel
                      firstItem={0}
                      //don't include related contacts where the surname and the given_name are null
                      items={related_contacts.filter(
                        ({ given_name, surname }) => !!given_name || !!surname
                      )}
                      slidesToShow={1}
                      centerMode={true}
                      infinite={true}
                      dots={related_contacts.length < 7 ? true : false}
                      centerPadding={this.state.expanded ? "20px" : "20px"}
                      itemWidth={320}
                      renderItem={obj => {
                        const person = obj?.item;
                        return (
                          <PersonItem
                            person={person}
                            expanded={this.state.expanded}
                            no_options={false}
                            use_person_flags={false}
                            property={property}
                            propertyUpdated={this.state.propertyUpdated}
                          />
                        );
                      }}
                      include_pagination={true}
                      dotColor={colors.text_color}
                    />
                  </Wrapper>
                )}
              </>
            ) : null}
            <Wrapper style={{ height: 40 }}></Wrapper>
          </Scroll>
        ) : null}

        {property &&
        ((person?.linked_properties &&
          !person?.linked_properties.includes(property?.id)) ||
          (person?.custom_unlinked_properties &&
            person?.custom_unlinked_properties.includes(property?.id))) ? (
          <BottomNavBar>
            <ConfirmLabel
              style={{
                alignSelf: "stretch"
              }}
              icon="link"
              label={
                "Link contact to property: " + formatted_property_address.line1
              }
              confirmButton={"Link Contact"}
              loading={this.state.linking_loading}
              primary={true}
              disabled={false}
              onPress={() => {
                this.props.linkOrUnlinkPropertyToContact({
                  token: this.props.token,
                  property_id: property?.id,
                  person_id: person?.individual_key,
                  matching_type:
                    person?.custom_unlinked_properties &&
                    person?.custom_unlinked_properties.includes(property?.id)
                      ? "custom_match_removed"
                      : "custom_match_added",
                  type:
                    person?.custom_unlinked_properties &&
                    person?.custom_unlinked_properties.includes(property?.id)
                      ? "unlink_property_to_contact"
                      : "link_property_to_contact",
                  onLoading: () => {
                    this.setState({
                      linking_loading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      linking_loading: false
                    });
                  },
                  onSuccess: results => {
                    this.setState(
                      {
                        linking_loading: false,
                        property: results.property,
                        person: results.contact
                      },
                      () => {
                        if (this.state.propertyUpdated && results.property) {
                          this.state.propertyUpdated(results.property);
                        }
                      }
                    );
                  }
                });
              }}
            />
          </BottomNavBar>
        ) : property &&
          person?.linked_properties &&
          person?.linked_properties.includes(property?.id) &&
          (!person?.custom_unlinked_properties ||
            !person?.custom_unlinked_properties.includes(property?.id)) ? (
          <BottomNavBar>
            <ConfirmLabel
              style={{
                alignSelf: "stretch"
              }}
              icon="check"
              label={"Linked to property: " + formatted_property_address.line1}
              primary={false}
              disabled={
                person?.custom_linked_properties.includes(property?.id)
                  ? false
                  : true
              }
              confirmButton={"Unlink Contact"}
              loading={this.state.linking_loading}
              onPress={() => {
                this.props.linkOrUnlinkPropertyToContact({
                  token: this.props.token,
                  property_id: property?.id,
                  person_id: person?.individual_key,
                  matching_type: person?.custom_linked_properties.includes(
                    property?.id
                  )
                    ? "custom_match_added"
                    : "custom_match_removed",
                  type: person?.custom_linked_properties.includes(property?.id)
                    ? "unlink_property_to_contact"
                    : "link_property_to_contact",
                  onLoading: () => {
                    this.setState({
                      linking_loading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      linking_loading: false
                    });
                  },
                  onSuccess: results => {
                    this.setState(
                      {
                        linking_loading: false,
                        property: results.property,
                        person: results.contact
                      },
                      () => {
                        if (this.state.propertyUpdated && results.property) {
                          this.state.propertyUpdated(results.property);
                        }
                      }
                    );
                  }
                });
              }}
            />
          </BottomNavBar>
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  getContacts,
  pushSidePanel,
  linkOrUnlinkPropertyToContact
})(Contact);
