import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Wrapper,
  Title,
  Copy,
  Bold,
  Row,
  Icon,
  Spin
} from "app/NativeComponents/common";
import { PopoverMenu, ConfirmLabel } from "app/NativeComponents/snippets";
import {
  getTotalLeadsCount,
  getLeads,
  pushSidePanel,
  replaceSidePanel,
  setAppliedFilter,
  selectActiveProperty,
  getCustomFilterText, //legacy,
  numberWithCommas,
  getLeadUpdates,
  changeTab
} from "app/NativeActions";

import NativeLeadsView from "app/NativeComponents/components/NativeLeadsView";
import MainHeader from "app/DealMachineCore/reuseable/MainHeader";
import ListTabs from "./ListTabs";
import SearchAndFilter from "./SearchAndFilter";
import ShowcaseFilters from "app/DealMachineCore/reuseable/AdvancedFilters/ShowcaseFilters";
import MobileLeadsView from "./MobileLeadsView";
import ListBuilding from "./ListBuilding";
class Leads extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_leads: [],
      selected_all: false,
      is_selecting: false,
      all_leads_count: 0,
      all_leads_count_loading: false,
      filtered_count: 0,
      filtered_count_loading: false,
      search: "",
      search_loading: false,
      selected_pages: [],
      updated_data_count: 0,
      updated_data_count_loading: false,
      accepted_all_changes: false
    };

    this.getProperties = this.getProperties.bind(this);
    this.checkLead = this.checkLead.bind(this);
    this.checkAllLeads = this.checkAllLeads.bind(this);
    this.checkAllLeadsOnPage = this.checkAllLeadsOnPage.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.updateSearch = this.updateSearch.bind(this);

    this.pressProperty = this.pressProperty.bind(this);
    this.toggleSelecting = this.toggleSelecting.bind(this);

    this.getUpdatedDataCount = this.getUpdatedDataCount.bind(this);
  }

  checkAllLeads() {
    const { selected_leads, selected_all } = this.state;
    const { list_properties } = this.props;

    if (selected_all) {
      this.setState({
        selected_all: false,
        selected_leads: [],
        selected_pages: []
      });
    } else {
      this.setState({
        selected_all: true,
        selected_leads: [],
        selected_pages: []
      });
    }
  }

  checkAllLeadsOnPage(page) {
    const { selected_all, selected_leads, selected_pages } = this.state;
    const { list_properties } = this.props;

    let sa = false;

    if (!selected_all) {
      let found_page;

      for (let i = 0; i < selected_pages.length; i++) {
        if (selected_pages[i] == page) {
          found_page = true;
        }
      }

      if (found_page) {
        this.setState({
          selected_pages: selected_pages.filter(p => p !== page)
        });
      } else {
        this.setState({
          selected_pages: [...selected_pages, page]
        });
      }

      let sl = JSON.parse(JSON.stringify(selected_leads));
      for (let i = 0; i < list_properties.length; i++) {
        let found_lead = false;

        for (let j = 0; j < selected_leads.length; j++) {
          if (selected_leads[j]?.deal?.id == list_properties[i]?.deal?.id) {
            found_lead = true;
          }
        }

        if (found_page) {
          //remove all leads on page
          if (found_lead) {
            sl = sl.filter(
              ({ deal }) => deal?.id != list_properties[i]?.deal?.id
            );
          }
        } else {
          //add all leads on page
          if (!found_lead) {
            sl.push(list_properties[i]);
          }
        }
      }
      this.setState({
        selected_leads: sl
      });
    } else {
      this.setState({
        selected_all: false,
        selected_pages: [],
        selected_leads: []
      });
    }
  }

  toggleSelecting(toggle) {
    this.setState({
      is_selecting: toggle,
      selected_all: false,
      selected_leads: []
    });
  }

  checkLead(lead) {
    const { selected_leads, selected_all } = this.state;
    if (!selected_all) {
      let found_lead = false;
      for (let i = 0; i < selected_leads.length; i++) {
        if (selected_leads[i]?.deal?.id == lead?.deal?.id) {
          found_lead = true;
        }
      }
      if (found_lead) {
        this.setState({
          is_selecting: true,
          selected_leads: selected_leads.filter(
            ({ deal }) => deal.id !== lead?.deal?.id
          )
        });
      } else {
        this.setState({
          is_selecting: true,
          selected_leads: [...selected_leads, lead]
        });
      }
    }
  }

  clearAll() {
    this.setState({
      is_selecting: false,
      selected_all: false,
      selected_leads: [],
      selected_pages: [],
      selecting: false
    });
  }

  componentDidMount() {
    if (this.props.device == "mobile") {
      this.props.changeTab("leads");
    }
    if (this.props.token) {
      this.getProperties({ load_type: "load" });
      this.getTotalLeadCount();
      this.getFilteredLeadCount();
      this.getUpdatedDataCount();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      sort_by,
      applied_filter,
      list_properties_page,
      list_properties_limit,
      trigger_leads_reload,
      trigger_leads_count_reload,
      list_properties_refreshing,
      list_properties_loading,
      active_list_tab,
      token
    } = this.props;
    const { search } = this.state;

    if (token !== prevProps.token) {
      this.getProperties({ load_type: "load" });
      this.getTotalLeadCount();
      this.getFilteredLeadCount();
      this.getUpdatedDataCount();
    }

    if (
      prevProps.sort_by !== sort_by ||
      prevProps.list_properties_limit !== list_properties_limit ||
      token !== prevProps.token
    ) {
      this.getProperties({ load_type: "load", page: 1 });
    } else if (
      prevProps.applied_filter !== applied_filter ||
      prevState.search !== search ||
      prevProps.active_list_tab !== active_list_tab ||
      JSON.stringify(prevProps.lead_filters?.property_flags) !==
        JSON.stringify(this.props.lead_filters?.property_flags) ||
      prevProps.lead_filters?.property_flags_and_or !==
        this.props.lead_filters?.property_flags_and_or
    ) {
      this.clearAll();
      this.getProperties({
        load_type: prevProps.search == search ? "search" : "refresh",
        search: search,
        page: 1
      });
      this.getFilteredLeadCount();
    } else if (
      prevProps.list_properties_page !== list_properties_page ||
      prevProps.list_properties_limit !== list_properties_limit
    ) {
      this.getProperties({
        load_type: "load",
        page: list_properties_page == 0 ? 1 : list_properties_page
      });
    } else if (
      prevProps.trigger_leads_reload !== trigger_leads_reload &&
      trigger_leads_reload &&
      !list_properties_refreshing &&
      !list_properties_loading
    ) {
      this.getProperties({ load_type: "refresh" });
      this.getFilteredLeadCount();
    } else if (
      prevProps.trigger_leads_count_reload !== trigger_leads_count_reload &&
      trigger_leads_count_reload &&
      !this.state.all_leads_count_loading
    ) {
      this.getTotalLeadCount();
      this.getFilteredLeadCount();
    }
  }

  getFilteredLeadCount() {
    const { search } = this.state;
    this.setState({
      filtered_count_loading: true
    });
    const { applied_filter, active_list_tab, lead_filters } = this.props;
    this.props.getTotalLeadsCount({
      token: this.props.token,
      filters:
        this.props.applied_filter && this.props.applied_filter.filters
          ? this.props.applied_filter.filters
          : null,
      old_filters:
        this.props.applied_filter && this.props.applied_filter.old_filters
          ? this.props.applied_filter.old_filters
          : null,

      search,
      list_id: active_list_tab?.id ? active_list_tab?.id : active_list_tab,
      property_flags: lead_filters?.property_flags
        .map(({ value }) => value)
        .join(","),
      property_flags_and_or: lead_filters?.property_flags_and_or,
      onLoading: () => {
        this.setState({
          filtered_count_loading: true
        });
      },
      onError: () => {
        this.setState({
          filtered_count_loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          filtered_count: results,
          filtered_count_loading: false
        });
      }
    });
  }

  getTotalLeadCount() {
    this.setState({
      all_leads_count_loading: true
    });
    this.props.getTotalLeadsCount({
      noCancel: true,
      token: this.props.token,
      list_id: "all_leads",
      onLoading: () => {
        this.setState({
          all_leads_count_loading: true
        });
      },
      onError: () => {
        this.setState({
          all_leads_count_loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          all_leads_count: results,
          all_leads_count_loading: false
        });
      }
    });
  }

  getUpdatedDataCount() {
    const { search } = this.state;

    const { applied_filter, active_list_tab } = this.props;

    this.props.getLeadUpdates({
      token: this.props.token,
      type: "count",
      onLoading: () => {
        this.setState({
          updated_data_count_loading: true
        });
      },
      onError: () => {
        this.setState({
          updated_data_count_loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          updated_data_count: results?.total_lead_count,
          updated_data_count_loading: false
        });
      }
    });
  }

  getProperties({ load_type = "load", page = 1 }) {
    const {
      token,
      list_properties_refreshing,
      list_properties_loading,
      list_properties_begin,
      list_properties_limit,
      list_properties_page,
      sort_by,
      applied_filter,
      active_list_tab,
      lead_filters
    } = this.props;

    const { search_loading, search } = this.state;

    if (true) {

      this.props.getLeads({
        token,
        load_type: load_type,
        sort_by: sort_by
          ? sort_by.slug + "_" + sort_by.type
          : "date_created_desc",
        //filter_lists: this.props.filter_lists,
        search,
        list_id: active_list_tab?.id ? active_list_tab?.id : active_list_tab,
        old_filters:
          this.props.applied_filter && this.props.applied_filter?.old_filters
            ? this.props.applied_filter?.old_filters
            : null, //used for legacy
        filters:
          this.props.applied_filter && this.props.applied_filter?.filters
            ? this.props.applied_filter?.filters
            : null,
        begin:
          load_type === "load_more"
            ? list_properties_begin
            : page
            ? list_properties_limit * (page - 1)
            : list_properties_limit * (list_properties_page - 1),
        limit: list_properties_limit,
        page: page,
        property_flags: lead_filters?.property_flags
          .map(({ value }) => value)
          .join(","),
        property_flags_and_or: lead_filters?.property_flags_and_or,
        onLoading: () => {
          this.setState({
            search_loading: load_type == "search" ? true : search_loading
          });
        },
        onError: () => {
          this.setState({
            search_loading: false
          });
        },
        onSuccess: () => {
          this.setState({
            search_loading: false
          });
        }
      });
    }
  }

  pressProperty(property) {
    this.props.selectActiveProperty(null, property);

    if (this.props.device == "desktop") {
      this.props.replaceSidePanel({
        slug: "property",
        id: property?.property_id,
        focus_side_panel:
          this.props.user?.user_settings?.property_expanded === "not_expanded"
            ? false
            : true,
        data: {
          property
        }
      });
    } else {
      this.props.pushSidePanel({
        slug: "property",
        id: property?.property_id,
        focus_side_panel:
          this.props.user?.user_settings?.property_expanded === "not_expanded"
            ? false
            : true,
        data: {
          property
        }
      });
    }
  }

  updateSearch(search) {
    this.setState({
      search,
      search_loading: search != this.state.search ? true : false
    });
  }

  renderInner() {
    const {
      selected_leads,
      selected_all,

      search,
      search_loading
    } = this.state;
    const { user, colors, applied_filter, isMobile, device, active_list_tab } =
      this.props;

    if (!isMobile && device == "desktop" && user?.team_clearance_level > 0) {
      return (
        <>
          <ListTabs />
          <SearchAndFilter
            {...this.props}
            selected_leads={selected_leads}
            selected_all={selected_all}
            clearAll={this.clearAll}
            search={search}
            search_loading={search_loading}
            updateSearch={this.updateSearch}
            filtered_count={this.state.filtered_count}
          />

          <Wrapper
            style={{
              position: "relative",
              overflow: "hidden",
              flex: 1
            }}
          >
            {active_list_tab?.id && active_list_tab.building == 1 ? (
              <ListBuilding />
            ) : (
              <NativeLeadsView
                search={this.state.search}
                pressProperty={this.pressProperty}
                selected_leads={selected_leads}
                selected_all={selected_all}
                selected_pages={this.state.selected_pages}
                checkLead={this.checkLead}
                checkAllLeads={this.checkAllLeads}
                checkAllLeadsOnPage={this.checkAllLeadsOnPage}
                clearAll={this.clearAll}
                filtered_count={this.state.filtered_count}
                filtered_count_loading={this.state.filtered_count_loading}
                renderTopLabel={() => {
                  if (applied_filter) {
                    return (
                      <>
                        <ConfirmLabel
                          style={{
                            alignSelf: "stretch"
                          }}
                          renderLabel={() => {
                            if (applied_filter.old_filters) {
                              const custom_filter_text = getCustomFilterText(
                                applied_filter.old_filters
                              );
                              return (
                                <Copy>
                                  <Bold>Legacy filters: </Bold>
                                  {custom_filter_text}
                                </Copy>
                              );
                            } else {
                              return (
                                <ShowcaseFilters
                                  filters={applied_filter.filters?.data}
                                  andor_type={
                                    applied_filter.filters?.andor_type
                                  }
                                  editable={false}
                                  text_only={true}
                                />
                              );
                            }
                          }}
                          secondaryButton={
                            !applied_filter.old_filters ? "Edit" : ""
                          }
                          onSecondaryPress={() => {
                            this.props.pushSidePanel({
                              slug: "advanced_filters",
                              overlay: true
                            });
                          }}
                          confirmButton={"Clear All"}
                          confirmButtonIcon={"clear"}
                          onPress={() => {
                            this.props.setAppliedFilter({
                              applied_filter: null
                            });
                          }}
                        />
                      </>
                    );
                  }
                  return null;
                }}
              />
            )}
          </Wrapper>
        </>
      );
    }

    return (
      <MobileLeadsView
        pressProperty={this.pressProperty}
        search={search}
        search_loading={search_loading}
        selected_leads={selected_leads}
        selected_all={selected_all}
        checkLead={this.checkLead}
        checkAllLeads={this.checkAllLeads}
        clearAll={this.clearAll}
        filtered_count={this.state.filtered_count}
        filtered_count_loading={this.state.filtered_count_loading}
        updateSearch={this.updateSearch}
        getProperties={this.getProperties}
        is_selecting={this.state.is_selecting}
        toggleSelecting={this.toggleSelecting}
        {...this.props}
      />
    );
  }

  renderDataUpdates() {
    return (
      <ConfirmLabel
        label={
          this.state.updated_data_count == 1
            ? "Data updates for 1 lead"
            : "Data updates for " +
              numberWithCommas(this.state.updated_data_count) +
              " leads"
        }
        confirmButton={"Review Updates"}
        loading={false}
        primary={true}
        noPrimaryButton={true}
        disabled={false}
        onPress={() => {
          this.props.pushSidePanel({
            slug: "review_all_lead_data_updates",
            overlay: true,
            data: {
              search: this.state.search,
              count: this.state.updated_data_count,
              onBack: this.getUpdatedDataCount,
              onAcceptAllChanges: () => {
                this.setState({
                  accepted_all_changes: true
                });
              }
            }
          });
        }}
        style={{
          borderRadius: 30
        }}
        innerStyle={{
          paddingRight: 0,
          paddingLeft: 25,
          paddingTop: 0,
          paddingBottom: 0
        }}
      />
    );
  }

  render() {
    const { all_leads_count, all_leads_count_loading } = this.state;
    const { colors, isMobile, user } = this.props;
    return (
      <Container
        fill={true}
        style={{
          display: "flex",
          overflow: "hidden",
          backgroundColor: colors.card_color
        }}
        title={"Leads"}
      >
        <MainHeader
          title={"Leads"}
          subtitle={
            all_leads_count == 1
              ? "1 Lead"
              : numberWithCommas(all_leads_count) + " Leads"
          }
          subtitle_loading={all_leads_count_loading}
          subtitle_loading_text={"Loading lead count"}
          onSubtitlePress={() => {
            this.getTotalLeadCount();
          }}
          renderRight={
            this.state.updated_data_count > 0 &&
            !this.state.accepted_all_changes &&
            !this.props.isMobile
              ? () => {
                  return (
                    <Row style={this.props.isMobile ? { width: 225 } : {}}>
                      {user.team_clearance_level > 0
                        ? this.renderDataUpdates()
                        : null}
                    </Row>
                  );
                }
              : null
          }
        />
        {this.props.isMobile &&
        this.state.updated_data_count > 0 &&
        !this.state.accepted_all_changes &&
        user.team_clearance_level > 0
          ? this.renderDataUpdates()
          : null}
        {this.renderInner()}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, lead, filter, list }) => {
  const { token, user } = auth;
  const { device, window_height, isMobile } = native;
  const { colors, dark_mode } = settings;
  const {
    list_properties,
    list_properties_begin,
    list_properties_page,
    list_properties_limit,
    list_properties_loading,
    list_properties_refreshing,
    list_properties_loaded_all,

    trigger_leads_reload,
    trigger_leads_count_reload,

    lead_filters
  } = lead;
  const { sort_by, applied_filter } = filter;
  const { active_list_tab } = list;

  return {
    token,
    user,
    device,
    isMobile,
    window_height,
    colors,
    dark_mode,
    list_properties,
    list_properties_begin,
    list_properties_page,
    list_properties_limit,
    list_properties_loading,
    list_properties_refreshing,
    list_properties_loaded_all,

    sort_by,
    applied_filter,
    active_list_tab,

    trigger_leads_reload,
    trigger_leads_count_reload,
    lead_filters
  };
};

export default connect(mapStateToProps, {
  getTotalLeadsCount,
  getLeads,
  pushSidePanel,
  replaceSidePanel,
  setAppliedFilter,
  selectActiveProperty,

  getLeadUpdates,
  changeTab
})(Leads);
