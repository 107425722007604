import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Wrapper, Copy, Row, Bold, Spin } from "app/NativeComponents/common";

import {
  sendMessageToOpenAI,
  stopOpenAI,
  showErrorMessage,
  updateDialerConversationMessage,
  formatUsPhone
} from "app/NativeActions";
import { IconButton } from "app/NativeComponents/snippets";

class MessageSuggest extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      system_messages: [],
      chat_rendering: true,
      newest_result: ""
    };

    this.sendMessage = this.sendMessage.bind(this);
  }

  componentDidMount() {
    //get system messages
    const { message } = this.props;
    if (!message.message) {
      this.sendMessage();
    }
  }

  componentWillUnmount() {
    if (this.state.chat_rendering) {
      this.props.stopOpenAI({ onSuccess: () => {} });
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  sendMessage() {
    const {
      message,
      contact,
      cleaned_property,
      user,
      default_dialer_settings
    } = this.props;
    const openai_model = "gpt-4o";

    const active_contact = JSON.parse(JSON.stringify(contact));
    delete active_contact.associated_lead;

    const dialer_settings = user?.user_settings?.dialer_settings
      ? user?.user_settings?.dialer_settings
      : default_dialer_settings;

    let system_messages = [];

    if (this.props.generate_voicemail) {
      let content =
        "You are leaving a voicemail as the user " +
        this.props.user?.name +
        ". You are cold calling " +
        contact.full_name +
        " in hopes to achieve your goal and they did not answer the phone. You are kind and considerate, but also determined to meet your goal. If you do a good job on the voicemail you get tipped $100. Keep it short and sweet. You act as just a cold caller with your goal and speak to nothing else. Only answer with what you the cold caller should say in this voicemail. ";

      if (!!this.props.callback_number) {
        content +=
          "Make sure to leave your name and number to call back. You are calling from the phone number " +
          formatUsPhone(this.props.callback_number);
      }

      system_messages.push({
        role: "system",
        content
      });
    } else {
      system_messages.push({
        role: "system",
        content:
          "You are a cold caller named " +
          this.props.user?.name +
          ". You are cold calling " +
          contact.full_name +
          " in hopes to achieve your goal. You are kind and considerate, but also determined to meet your goal. If the recipient of the call is not interested we should respond to their objections and attempt to pursue a meeting at least 2 more times. If the recipient is very upset, simply end the call politely. If you do a good job and the conversation you get tipped $100. Keep your answers short and sweet. You act as just a cold caller with your goal and speak to nothing else. Only answer with what you the cold caller should say next. The user messages will be the call transcript from the actual person we are calling. You should not generating messages for them. If there are no user messages, only include the first thing you'd say to the contact in your conversation.  "
      });
    }

    let dialer_default_goal = "";
    switch (this.props.user?.industry) {
      default:
        dialer_default_goal =
          "Your goal is to inquire about purchasing the property from the contact.";
        break;
      case "Real Estate Agent":
        dialer_default_goal =
          "Your goal is to inquire about listing the property for sale on behalf of the contact as their agent.";
        break;
    }

    const goal_system_message = !!dialer_settings?.dialer_goal
      ? {
          role: "system",
          content: dialer_settings.dialer_goal
        }
      : {
          role: "system",
          content: dialer_default_goal
        };
    if (goal_system_message) {
      system_messages.push(goal_system_message);
    }

    if (this.props.generate_voicemail) {
      const voicemail_system_message =
        !!dialer_settings?.dialer_voicemail_script
          ? {
              role: "system",
              content:
                "Try to best follow this script for your voicemail: " +
                dialer_settings?.dialer_voicemail_script
            }
          : null;

      if (voicemail_system_message) {
        system_messages.push(voicemail_system_message);
      }
    } else {
      const script_system_message = !!dialer_settings?.dialer_script
        ? {
            role: "system",
            content:
              "Try to best follow this script: " +
              dialer_settings?.dialer_script
          }
        : null;

      if (script_system_message) {
        system_messages.push(script_system_message);
      }
    }

    if (contact) {
      system_messages.push({
        role: "system",
        content:
          "We are speaking with " +
          active_contact?.full_name +
          ".Here is some info about the contact you are speaking with: " +
          JSON.stringify(active_contact) +
          " Use this information to guide your conversation."
      });
    }

    if (cleaned_property) {
      system_messages.push({
        role: "system",
        content:
          "We are interested in the property at " +
          cleaned_property["Property address full"] +
          " Here is some info about the property you are interested in: " +
          JSON.stringify(cleaned_property) +
          " Use this information to guide your conversation."
      });
    }
    system_messages.push({
      role: "system",
      content:
        "When referring to an address, only say the address. Don't include the city, state, etc."
    });

    let messages = [];

    if (this.props.generate_voicemail) {
      messages.push({
        role: "user",
        content:
          "Generate the voicemail message to leave for " + contact?.given_name
      });
    } else {
      for (let i = 0; i < this.props.current_conversation.length; i++) {
        if (this.props.current_conversation[i].type !== "suggestion") {
          messages.push({
            role:
              this.props.current_conversation[i].type === "inbound"
                ? "assistant"
                : "user",
            content: this.props.current_conversation[i].message
          });
        }
      }
    }

    this.props.sendMessageToOpenAI({
      messages: [...system_messages, ...messages],
      model: openai_model,
      onLoading: () => {
        this.setState({
          chat_rendering: true
        });
      },
      onUpdate: text => {
        this.setState(prevState => ({
          newest_result: prevState.newest_result + text
        }));
      },
      onError: error => {
        this.setState({
          chat_rendering: false
        });
        this.props.onComplete();
        if (!!error) {
          this.props.showErrorMessage(error, "Error");
          this.props.removeMessage(message?.id, "suggestion");
        }
      },
      onSuccess: results => {
        this.setState(
          {
            chat_rendering: false
          },
          () => {
            this.props.updateDialerConversationMessage({
              type: "suggestion",
              message: results,
              complete: true,
              id: message.id
            });
          }
        );
      },
      onReadyStateChange: () => {
        this.setState({
          chat_rendering: false
        });
        this.props.onComplete();
      }
    });
  }

  render() {
    const { message, last_suggestion_id, colors } = this.props;
    const { newest_result, chat_rendering } = this.state;

    return (
      <Row
        style={{
          backgroundColor: colors.purple_color_muted,
          padding: 15,
          paddingRight: 0,
          paddingLeft: 15,
          borderRadius: 5,
          borderBottomRightRadius: 5,
          borderBottomLeftRadius: 0,
          margin: 5,
          marginRight: 40,
          marginLeft: 0
        }}
      >
        {!message.message && !newest_result && chat_rendering ? (
          <Wrapper style={{ paddingRight: 15 }}>
            <Spin size={"small"} />
          </Wrapper>
        ) : null}

        <Wrapper
          style={
            !chat_rendering
              ? { flex: 1 }
              : !message.message && !newest_result && chat_rendering
              ? {}
              : { paddingRight: 55 }
          }
        >
          {!chat_rendering ? (
            <Copy style={{ fontSize: 12 }}>
              <Bold>Suggested Message:</Bold>
            </Copy>
          ) : null}

          <Copy
            style={{
              color: colors.text_color
            }}
          >
            {!!message.message ? message.message : newest_result}
          </Copy>
        </Wrapper>
        <Wrapper>
          {last_suggestion_id &&
          last_suggestion_id === message?.id &&
          !chat_rendering ? (
            <IconButton
              icon={"refresh"}
              loading={false}
              tooltip={"Try Again"}
              tooltipPlacement={"top"}
              onPress={() => {
                this.props.updateDialerConversationMessage({
                  type: "suggestion",
                  message: "",
                  complete: true,
                  id: message.id
                });

                this.setState(
                  {
                    newest_result: "",
                    chat_rendering: true
                  },
                  () => {
                    this.sendMessage();
                  }
                );
              }}
            />
          ) : !chat_rendering ? (
            <IconButton
              icon={"close"}
              tooltip={"Hide Suggestion"}
              tooltipPlacement={"top"}
              onPress={() => {
                this.props.removeMessage(message?.id, "suggestion");
              }}
            />
          ) : null}
        </Wrapper>
      </Row>
    );
  }
}
const mapStateToProps = ({ auth, native, settings, dialer }) => {
  const { token, user } = auth;
  const { isMobile, device, platform, isIphoneX, window_height } = native;
  const { colors, dark_mode } = settings;
  const { default_dialer_settings } = dialer;

  return {
    token,
    user,
    isMobile,
    device,
    platform,
    colors,
    dark_mode,
    isIphoneX,
    window_height,
    default_dialer_settings
  };
};

export default connect(mapStateToProps, {
  sendMessageToOpenAI,
  stopOpenAI,
  showErrorMessage,
  updateDialerConversationMessage
})(MessageSuggest);
