import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  Card,
  Bold,
  Row,
  Title,
  ProgressBar
} from "app/NativeComponents/common";
import {
  SmallLabel,
  SelectItem,
  InformationItem,
  Carousel,
  InlineButton
} from "app/NativeComponents/snippets";
import { numberWithCommas } from "app/NativeActions";
class PropertyCharacteristics extends Component {
  constructor(props) {
    super(props);

    this.state = { more_info: false, offset: 0 };

    this.formatBathsAndBeds = this.formatBathsAndBeds.bind(this);
  }
  componentWillUnmount() {
    clearInterval(this._interval);
  }

  formatBathsAndBeds(number = null) {
    if (!!number) {
      let num = parseFloat(number);

      if (Number.isInteger(num)) {
        return num; // Return the number as is if it's already an integer
      } else {
        return num?.toFixed(1); // Round to one decimal place if there's a decimal part
      }
    }
    return 0;
  }

  render() {
    const { property, search, colors } = this.props;
    /*

$property_item["HeatingFuelType"] = getHeatingFuelTypeFromCode(
    $property_info["HeatingFuelTypeCode"]
  );

  $property_item["Pool"] = getPoolFromCode($property_info["PoolCode"]);
  $property_item["Stories"] = getStoriesFromCode(
    $property_info["StoriesNbrCode"]
  );

  $property_item["Amenities"] = getAmenitiesFromCode(
    $property_info["Amenities"]
  );
  $property_item["AirConditioning"] = getAirConditioningFromCode(
    $property_info["AirConditioningCode"]
  );
  $property_item["Basement"] = getBasementFromCode(
    $property_info["BasementCode"]
  );
  $property_item["Deck"] = $property_info["DeckInd"] == 1 ? "Yes" : null;
  $property_item["ConstructionType"] = getConstructionTypeFromCode(
    $property_info["ConstructionTypeCode"]
  );
  $property_item["ExteriorWalls"] = getExteriorWallsFromCode(
    $property_info["ExteriorWallsCode"]
  );
  $property_item["InteriorWalls"] = getInteriorWallsFromCode(
    $property_info["InteriorWallsCode"]
  );
  $property_item["NumOfFireplaces"] = $property_info["FireplaceCode"];
  $property_item["FloorCover"] = getFloorCoverFromCode(
    $property_info["FloorCoverCode"]
  );
  $property_item["Garage"] = getGarageFromCode($property_info["Garage"]);
  $property_item["GarageParkingNbr"] = $property_info["GarageParkingNbr"];
  $property_item["Driveway"] = getDrivewayFromCode(
    $property_info["DrivewayCode"]
  );
  $property_item["OtherRooms"] = getOtherRoomsFromCode(
    $property_info["OtherRooms"]
  );
  $property_item["TotalRooms"] = $property_info["TotalRooms"];
  $property_item["Patio"] = getPatioFromCode($property_info["PatioCode"]);
  $property_item["Porch"] = getPorchFromCode($property_info["PorchCode"]);
  $property_item["RoofCover"] = getRoofCoverFromCode(
    $property_info["RoofCoverCode"]
  );
  $property_item["RoofType"] = getRoofTypeFromCode(
    $property_info["RoofTypeCode"]
  );
  $property_item["Sewer"] = getSewerFromCode($property_info["SewerCode"]);
  $property_item["Style"] = getStyleFromCode($property_info["StyleCode"]);
  $property_item["SumBuildingsNbr"] = $property_info["SumBuildingsNbr"];
  $property_item["SumCommercialUnits"] = $property_info["SumCommercialUnits"];
  $property_item["Topography"] = getTopographyFromCode(
    $property_info["TopographyCode"]
  );
  $property_item["Water"] = getWaterFromCode($property_info["WaterCode"]);
  $property_item["SiteInfluence"] = getSiteInfluenceFromCode(
    $property_info["SiteInfluenceCode"]
  );
 */
    return (
      <>
        {"Property Characteristics"
          .toLowerCase()
          .indexOf(search?.toLowerCase()) !== -1 ||
        "Living area".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Year built".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Number of units".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Bedrooms".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Bathrooms".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Heating type".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Pool".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Property type".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Stories".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Construction type".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Roof type".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Roof cover".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Exterior walls".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Interior walls".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Floor cover".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Basement".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Garage".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Driveway".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Patio".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Porch".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Deck".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Amenities".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Air conditioning".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Fireplaces".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Sewer".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Water".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Topography".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Geographic features".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Other rooms".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Total rooms".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Total units".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Number of buildings".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Number of commercial units"
          .toLowerCase()
          .indexOf(search?.toLowerCase()) !== -1 ||
        !search ? (
          <Card
            id={"property-characteristics"}
            label={"Property Characteristics"}
            style={{ paddingTop: 10, marginTop: 15 }}
            onLayout={event => {
              const { x, y, width, height } = event?.nativeEvent?.layout;
              this.setState({
                offset: y
              });
            }}
          >
            <Row
              style={
                this.props.expanded
                  ? {
                      flexDirection: "row",
                      alignItems: "stretch",
                      flexWrap: "wrap"
                    }
                  : { flexDirection: "column", alignItems: "stretch" }
              }
            >
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={
                  !!property?.building_square_feet &&
                  property?.building_square_feet != 0
                    ? property?.building_square_feet
                    : null
                }
                label={"Living area:"}
                format={"sqft"}
                hiddenFromNonSubs={true}
                helper={"building_square_feet"}
              />

              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={property?.year_built}
                label={"Year built:"}
                hiddenFromNonSubs={true}
                helper={"year_built"}
              />

              {this.state.more_info || search ? (
                <>
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={this.formatBathsAndBeds(property?.total_bedrooms)}
                    label={"Bedrooms:"}
                    hiddenFromNonSubs={true}
                    helper={"total_bedrooms"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={this.formatBathsAndBeds(property?.total_baths)}
                    label={"Bathrooms:"}
                    hiddenFromNonSubs={true}
                    helper={"total_baths"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.property_type}
                    label={"Property type:"}
                    format={"text"}
                    hiddenFromNonSubs={true}
                    helper={"property_type"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.ConstructionType}
                    label={"Construction type:"}
                    hiddenFromNonSubs={true}
                    helper={"construction_type"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.Style}
                    label={"Building style:"}
                    hiddenFromNonSubs={true}
                    helper={"building_style"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.EffectiveYearBuilt}
                    label={"Effective year built:"}
                    tooltip={
                      "The year the latest significant construction occurred on the building."
                    }
                    hiddenFromNonSubs={true}
                    helper={"effective_year_built"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.units}
                    label={"Number of units:"}
                    hiddenFromNonSubs={true}
                    helper={"number_of_units"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.SumBuildingsNbr}
                    label={"Number of buildings:"}
                    hiddenFromNonSubs={true}
                    helper={"number_of_buildings"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.SumCommercialUnits}
                    label={"Number of commercial units:"}
                    hiddenFromNonSubs={true}
                    helper={"number_of_commercial_units"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.Stories}
                    label={"Stories:"}
                    hiddenFromNonSubs={true}
                    helper={"stories"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.SumGarageSqFt}
                    label={"Garage area:"}
                    format={"sqft"}
                    hiddenFromNonSubs={true}
                    helper={"garage_area"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.HeatingType}
                    label={"Heating type:"}
                    hiddenFromNonSubs={true}
                    helper={"heating_type"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.HeatingFuelType}
                    label={"Heating fuel:"}
                    hiddenFromNonSubs={true}
                    helper={"heating_fuel"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.AirConditioning}
                    label={"Air conditioning:"}
                    hiddenFromNonSubs={true}
                    helper={"air_conditioning"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.Basement}
                    label={"Basement:"}
                    hiddenFromNonSubs={true}
                    helper={"basement"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.Deck}
                    label={"Deck:"}
                    hiddenFromNonSubs={true}
                    helper={"deck"}
                  />

                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.ExteriorWalls}
                    label={"Exterior walls:"}
                    hiddenFromNonSubs={true}
                    helper={"exterior_walls"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.InteriorWalls}
                    label={"Interior Walls:"}
                    hiddenFromNonSubs={true}
                    helper={"interior_walls"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.NumOfFireplaces}
                    label={"Number of fireplaces:"}
                    hiddenFromNonSubs={true}
                    helper={"number_of_fireplaces"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.FloorCover}
                    label={"Floor cover:"}
                    hiddenFromNonSubs={true}
                    helper={"floor_cover"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.Garage}
                    label={"Garage:"}
                    hiddenFromNonSubs={true}
                    helper={"garage"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.Driveway}
                    label={"Driveway:"}
                    hiddenFromNonSubs={true}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.Amenities}
                    label={"Amenities:"}
                    hiddenFromNonSubs={true}
                    helper={"amenities"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.OtherRooms}
                    label={"Other rooms:"}
                    hiddenFromNonSubs={true}
                    helper={"other_rooms"}
                  />

                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.Pool}
                    label={"Pool:"}
                    hiddenFromNonSubs={true}
                    helper={"pool"}
                  />

                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.Patio}
                    label={"Patio:"}
                    hiddenFromNonSubs={true}
                    helper={"patio"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.Porch}
                    label={"Porch:"}
                    hiddenFromNonSubs={true}
                    helper={"porch"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.RoofCover}
                    label={"Roof cover:"}
                    hiddenFromNonSubs={true}
                    helper={"roof_cover"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.RoofType}
                    label={"Roof type:"}
                    hiddenFromNonSubs={true}
                    helper={"roof_type"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.Sewer}
                    label={"Sewer:"}
                    hiddenFromNonSubs={true}
                    helper={"sewer"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.Topography}
                    label={"Topography:"}
                    hiddenFromNonSubs={true}
                    helper={"topography"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.Water}
                    label={"Water:"}
                    hiddenFromNonSubs={true}
                    helper={"water"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.SiteInfluence}
                    label={"Geographic features:"}
                    hiddenFromNonSubs={true}
                    helper={"geographic_features"}
                  />
                </>
              ) : null}
            </Row>
            {!search && this.state.more_info ? (
              <InlineButton
                onPress={() => {
                  this.setState(
                    {
                      more_info: false
                    },
                    () => {
                      if (this.props.device === "desktop") {
                        this.props.scrollToSection({
                          id: "property-characteristics",
                          offset: this.state.offset
                        });
                      } else {
                        this._interval = setTimeout(() => {
                          this.props.scrollToSection({
                            id: "property-characteristics",
                            offset: this.state.offset
                          });
                        }, 50);
                      }
                    }
                  );
                }}
                button_type={"full"}
                icon={"keyboard-arrow-up"}
              >
                Less Info
              </InlineButton>
            ) : !search ? (
              <InlineButton
                onPress={() => {
                  this.setState(
                    {
                      more_info: true
                    },
                    () => {
                      this.props.scrollToSection({
                        id: "property-characteristics",
                        offset: this.state.offset
                      });
                    }
                  );
                }}
                button_type={"full"}
                icon={"keyboard-arrow-down"}
              >
                More Info
              </InlineButton>
            ) : null}
          </Card>
        ) : null}
      </>
    );
  }
}

export default PropertyCharacteristics;
