import React, { Component } from "react";
import { connect } from "react-redux";

import { SelectItem } from "app/NativeComponents/snippets";

import {
  updateLead,
  pushSidePanel,
  setListModal,
  numberWithCommas
} from "app/NativeActions";
class RemoveFromListButton extends Component {
  render() {
    const { button_search, title, selected_all, selected_leads } = this.props;
    const { token, user, filtered_count } = this.props;

    if (
      !button_search ||
      title.toLowerCase().includes(button_search.toLowerCase())
    ) {
      return (
        <SelectItem
          select_type="none"
          icon="keyboard-arrow-right"
          onPress={() => {
            this.props.setListModal({
              title: "Remove leads from list(s)",
              description: selected_all
                ? "Choose a list or mulitple lists to remove all " +
                  filtered_count +
                  " leads from."
                : selected_leads.length === 1
                ? "Choose a list or mulitple lists to remove " +
                  selected_leads[0].property_address +
                  " from."
                : "Choose a list or mulitple lists to remove your selected " +
                  selected_leads.length +
                  " leads from.",
              type: "add_leads_to_lists",
              selected_leads: selected_all ? "all" : selected_leads,
              selected_lists: [],
              modalAction: ({
                selected_leads,
                selected_lists,
                onLoading = () => {},
                onError = () => {},
                onSuccess = () => {}
              }) => {
                onLoading();
                this.props.updateLead({
                  token: token,
                  type: "remove_leads_from_list",
                  list_ids: selected_lists
                    .map(list => {
                      return list.id;
                    })
                    .join(),
                  select_all: selected_all ? 1 : 0,
                  total_count: selected_all
                    ? filtered_count
                    : selected_leads.length,
                  new_filters:
                    this.props.applied_filter &&
                    this.props.applied_filter.filters
                      ? this.props.applied_filter?.filters
                      : null,
                  filters:
                    this.props.applied_filter &&
                    this.props.applied_filter.old_filters
                      ? this.props.applied_filter?.old_filters
                      : null,
                  property_flags: this.props.lead_filters?.property_flags
                    .map(({ value }) => value)
                    .join(","),
                  property_flags_and_or:
                    this.props.lead_filters?.property_flags_and_or,
                  using_old_filters:
                    this.props.applied_filter &&
                    this.props.applied_filter.old_filters
                      ? true
                      : false,
                  search: this.props.search,
                  list_history_id: this.props.list_history_id || "",
                  list_id: this.props.active_list_tab?.id
                    ? this.props.active_list_tab?.id
                    : this.props.active_list_tab,
                  deal_ids: selected_all
                    ? ""
                    : selected_leads
                        .map(property => {
                          return property.deal.id;
                        })
                        .join(),
                  no_loading: true,
                  onLoading: () => {
                    onLoading();
                  },
                  onError: () => {
                    onError();
                  },
                  onSuccess: () => {
                    onSuccess();
                    this.props.clearAll();
                  }
                });
              }
            });

            this.props.pushSidePanel({ slug: "lists", overlay: true });
            this.props.toggleMenu(false);
          }}
        >
          {title}
        </SelectItem>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth }) => {
  const { token, user } = auth;
  return {
    token,
    user
  };
};

export default connect(mapStateToProps, {
  updateLead,
  pushSidePanel,
  setListModal
})(RemoveFromListButton);
