import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Router from "./app/Router";
import { store, persistor } from "./app/store";
import { PersistGate } from "redux-persist/integration/react";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { GoogleOAuthProvider } from "@react-oauth/google";
//deploy II
ReactDOM.render(
  <GoogleOAuthProvider clientId="28374041577-or3671jh7838hi1mn3ilb90mpn7qglnf.apps.googleusercontent.com">
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router />
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);

datadogRum.init({
  applicationId: "73e180fb-ef27-4836-985a-ed76242d6d9c",
  clientToken: "pubf861ce327adc0c58a30d49f599f077c7",
  site: "datadoghq.com",
  service: "dealmachine-web",
  env: "prod",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input"
});
//
datadogRum.startSessionReplayRecording();
