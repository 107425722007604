import React, { Component } from "react";
import { connect } from "react-redux";

import { Device } from "@twilio/voice-sdk";
import {
  getTwilioToken,
  getPhoneCall,
  setActiveDialerContact,
  resetActiveCall,
  updateActiveCall,
  setDialerConversation,
  setRejectedCall,
  showErrorMessage
} from "app/NativeActions";
import DialerInner from "app/DealMachineCore/components/DialerWidget/DialerInner";

import moment from "moment";

class DeviceHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      incoming_call_id: null,
      incoming_call: null,
      loading_call: false,
      loading_active_call: false,
      twilio_token_request_time: null,
      hide_purchase_upsell: false
    };

    this.getIncomingCall = this.getIncomingCall.bind(this);
    this.clearIncomingCall = this.clearIncomingCall.bind(this);
    this.rejectIncomingCall = this.rejectIncomingCall.bind(this);
    this.createCallEvents = this.createCallEvents.bind(this);
    this.refreshTwilioToken = this.refreshTwilioToken.bind(this);
    this.hidePurchaseUpsell = this.hidePurchaseUpsell.bind(this);
  }

  componentDidMount() {
    this.refreshTwilioToken({ onSuccess: null });
    this.getActiveIncomingPhoneCall();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.active_dialer_contact?.answered_on_another_device &&
      this.props.active_dialer_contact?.answered_on_another_device !==
        prevProps.active_dialer_contact?.answered_on_another_device &&
      this.state.incoming_call
    ) {
      try {
        this.state.incoming_call.disconnect();
      } catch (error) {
        console.error("Error in ending a call:", error);
      }
      this.clearIncomingCall();
    }
  }

  hidePurchaseUpsell() {
    this.setState({ hide_purchase_upsell: true });
  }

  refreshTwilioToken({ onSuccess = null }) {
    if (
      !this.state.twilio_token ||
      !this.state.twilio_token_request_time ||
      moment().diff(moment(this.state.twilio_token_request_time), "minutes") > 5
    ) {
      this.props.getTwilioToken({
        token: this.props.token,
        onLoading: () => {},
        onError: () => {},
        onSuccess: results => {
          if (results?.twilio_token) {
            this.setState(
              {
                twilio_token: results?.twilio_token,
                twilio_token_request_time: new Date()
              },
              () => {
                this._device = new Device(results?.twilio_token, {
                  edge: ["ashburn", "umatilla"],
                  sounds: {
                    incoming: "/assets/sounds/incoming.mp3",
                    outgoing: "/assets/sounds/outgoing.mp3",
                    disconnect: "/assets/sounds/disconnect.mp3"
                  },
                  appName: "DealMachine Phone",
                  appVersion: 0.1,
                  allowIncomingWhileBusy: true
                });

                if (this._device) {
                  this._device.on("registered", e => {
                    console.log("The device is ready to receive calls.");
                  });

                  this._device.on("error", function (error) {
                    console.error("Twilio.Device Error: " + error.message);
                  });

                  this._device.on("ready", function (device) {
                    console.log("Twilio.Device is ready");
                  });

                  this._device.audio
                    .setAudioConstraints({
                      echoCancellation: true,
                      autoGainControl: true,
                      noiseSuppression: true
                    })
                    .then(() => {});
                  this._device.register();

                  this._device.on("incoming", call => {
                    const customParameters = call.customParameters;
                    let call_id = customParameters.get("call_id");
                    this.getIncomingCall(call_id, call);
                    this.createCallEvents(call);
                  });

                  if (onSuccess) {
                    onSuccess();
                  }
                }
              }
            );
          }
        }
      });
    } else {
      if (onSuccess) {
        onSuccess();
      }
    }
  }

  getActiveIncomingPhoneCall() {
    this.props.getPhoneCall({
      token: this.props.token,
      type: "get_active_incoming_call",
      onLoading: () => {
        this.setState({
          loading_active_call: true
        });
      },
      onError: () => {
        this.setState(
          {
            loading_active_call: false
          },
          () => {}
        );
      },
      onSuccess: results => {
        const phone_call = results?.phone_call;

        if (phone_call && phone_call.answered) {
          this.setState(
            {
              loading_active_call: false,
              loading_call: false
            },
            () => {
              this.props.setActiveDialerContact({
                contact: {
                  ...phone_call?.contact,
                  selected_phone: {
                    number: phone_call?.to_phone_number
                  },
                  associated_lead: phone_call?.property,
                  one_off_call: true,
                  incoming_call: true,
                  answered_on_another_device: true
                },
                current_conversation: phone_call?.transcription,
                active_call: {
                  call_id: phone_call.call_id,
                  call_status: "connected"
                }
              });

              this.props.setDialerConversation({
                call_id: phone_call?.call_id,
                current_conversation: phone_call?.transcription,
                call_results: phone_call?.call_results,
                call_note: phone_call?.note
              });
            }
          );
        } else {
          this.setState(
            {
              loading_active_call: false
            },
            () => {}
          );
        }
      }
    });
  }

  createCallEvents(call) {
    call.on("disconnect", () => {
      console.log("disconnect");
      this.clearIncomingCall();
    });
    call.on("cancel", () => {
      console.log("cancel");

      this.clearIncomingCall();
    });

    call.on("error", () => {
      console.log("error");

      this.props.showErrorMessage(
        "There was an error with our phone provider. Please try again later",
        "Error"
      );

      this.clearIncomingCall();
    });

    call.on("reject", () => {
      this.rejectIncomingCall();
    });
  }

  getIncomingCall(incoming_call_id, call) {
    if (!this.state.loading_call) {
      this.props.getPhoneCall({
        token: this.props.token,
        type: "get_phone_call",
        call_id: incoming_call_id,
        onLoading: () => {
          this.setState({
            loading_call: true
          });
        },
        onError: () => {
          this.setState({
            loading_call: false
          });
        },
        onSuccess: results => {
          const phone_call = results?.phone_call;
          if (phone_call) {
            if (!this.props.active_dialer_contact) {
              this.setState(
                {
                  loading_call: false,
                  incoming_call_id: incoming_call_id,
                  incoming_call_info: results?.phone_call,
                  incoming_call: call
                },
                () => {
                  this.props.resetActiveCall();

                  this.props.setActiveDialerContact({
                    contact: {
                      ...results?.phone_call?.contact,
                      selected_phone: {
                        number: phone_call?.to_phone_number
                      },
                      associated_lead: results?.phone_call?.property,
                      one_off_call: true,
                      incoming_call: true
                    },
                    active_call: {
                      call_id: phone_call.call_id,
                      call_status: "connected"
                    }
                  });
                }
              );
            } else {
              this.setState({
                loading_call: false,
                incoming_call_id: incoming_call_id,
                incoming_call_info: results?.phone_call,
                incoming_call: call
              });
            }
          }
        }
      });
    }
  }

  rejectIncomingCall(rejected = false) {
    this.props.setRejectedCall(this.state.incoming_call_id);

    this.setState({
      incoming_call_id: null,
      incoming_call: null,
      incoming_call_info: null
    });
  }
  clearIncomingCall() {
    this.setState({
      incoming_call_id: null,
      incoming_call: null,
      incoming_call_info: null
    });
  }

  render() {
    if (this._device) {
      return (
        <>
          {this.props.active_dialer_contact ? (
            <DialerInner
              twilio_device={this._device}
              clearIncomingCall={this.clearIncomingCall}
              rejectIncomingCall={this.rejectIncomingCall}
              active_dialer_contact={this.props.active_dialer_contact}
              incoming_call={this.state.incoming_call}
              incoming_call_info={this.state.incoming_call_info}
              call_id={this.state.incoming_call_id}
              refreshTwilioToken={this.refreshTwilioToken}
              hide_purchase_upsell={this.state.hide_purchase_upsell}
              hidePurchaseUpsell={this.hidePurchaseUpsell}
            />
          ) : null}
        </>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ auth, settings, dialer }) => {
  const { token, user, init } = auth;
  const { dark_mode, colors } = settings;
  const { active_dialer_contact, active_call } = dialer;
  return {
    token,
    user,
    dark_mode,
    colors,
    active_dialer_contact,
    active_call
  };
};

export default connect(mapStateToProps, {
  getTwilioToken,
  getPhoneCall,
  setActiveDialerContact,
  resetActiveCall,
  updateActiveCall,
  setDialerConversation,
  setRejectedCall,
  showErrorMessage
})(DeviceHandler);
