import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Bold,
  Wrapper,
  Copy,
  ContentfulRichText
} from "app/NativeComponents/common";
import { Header1, Header3, QRCodeWrapper } from "app/NativeComponents/common";

import {
  logout,
  loadOnboardingText,
  startRedirect,
  updateUser,
  tabNavigation,
  getMobileRedirectSessionLink
} from "app/NativeActions";

import {
  GhostButton,
  InlineButton,
  OnboardingWrapper
} from "app/NativeComponents/snippets";
class FinalStepStageOne extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentful_content: null,
      contentful_loading: true,
      completing: false,
      redirect_url: null
    };

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/complete-onboarding");
    }
    this.loadData("final_step_stage_one");

    this.props.getMobileRedirectSessionLink({
      token: this.props.token,
      onLoading: () => {},
      onError: () => {},
      onSuccess: results => {
        this.setState({
          redirect_url: results?.url
        });
      }
    });
  }

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              contentful_content: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              contentful_loading: false
            });
          }
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  render() {
    const { contentful_content, contentful_loading } = this.state;
    const { colors, isMobile } = this.props;

    return (
      <Container title={"🎉 You're all set!"}>
        <OnboardingWrapper>
          {this.state.contentful_content ? (
            <>
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 25
                }}
              >
                <Header1
                  style={{
                    textAlign: "center"
                  }}
                >
                  🎉 <Bold>You're all set!</Bold>
                </Header1>
                <Header3
                  style={{
                    textAlign: "center"
                  }}
                >
                  You are ready to start your journey
                </Header3>
              </Wrapper>

              <Wrapper
                style={{
                  padding: 25,
                  paddingBottom: 0,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {this.props.device !== "mobile" ? (
                  <Copy style={{ textAlign: "center", marginBottom: 10 }}>
                    Download our app to try it on your phone.
                  </Copy>
                ) : null}

                {this.state.redirect_url && this.props.device == "desktop" ? (
                  <>
                    <Wrapper
                      style={{
                        padding: 25,
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <QRCodeWrapper
                        size={100}
                        style={{
                          height: 200,
                          maxWidth: 200,
                          width: 200,
                          borderRadius: 0
                        }}
                        viewBox={`256 256 256 256`}
                        value={this.state.redirect_url}
                      />
                    </Wrapper>
                    <Copy style={{ textAlign: "center", marginBottom: 10 }}>
                      Open the camera on your phone to scan the QR code and
                      download the DealMachine app.
                    </Copy>
                  </>
                ) : null}
              </Wrapper>
              <Wrapper
                style={{
                  padding: 25,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <GhostButton
                  primary={true}
                  button_type={"full"}
                  loading={this.state.completing}
                  onPress={() => {
                    this.props.updateUser({
                      token: this.props.token,
                      type: "onboarding_stepthrough_complete",
                      payload: {
                        onboarding_stepthrough_complete: 1
                      },
                      onLoading: () => {
                        this.setState({
                          completing: true
                        });
                      },
                      onError: () => {
                        this.setState({
                          completing: false
                        });
                      },
                      onSuccess: () => {
                        this.props.tabNavigation({ slug: "map" });
                      },
                      no_loading: true
                    });
                  }}
                >
                  Take Me To My Account
                </GhostButton>
              </Wrapper>
            </>
          ) : null}
        </OnboardingWrapper>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, billing, settings, native }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth } = billing;
  const { isMobile, device, initialRedirect } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  updateUser,
  tabNavigation,
  getMobileRedirectSessionLink
})(FinalStepStageOne);
