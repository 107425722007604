import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Draggable,
  Animation,
  Row,
  Spin,
  Icon,
  Copy,
  Bold
} from "app/NativeComponents/common";
import { IconButton, InlineButton } from "app/NativeComponents/snippets";

import {
  addListToListTabs,
  removeProgressBar,
  tabNavigation,
  numberWithCommas
} from "app/NativeActions";

class ProgressBarsInner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 80,
      width: 650,
      x: props.window_width / 2 - 225,
      y: props.window_height - 105
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    const { colors, window_width, window_height } = this.props;

    return (
      <Draggable
        style={{ zIndex: 1 }}
        container_width={window_width}
        container_height={window_height}
        element={{
          x: this.state.x,
          y: this.state.y,
          width: this.state.width,
          height: this.state.height
        }}
        onDrag={({ x, y }) => {
          this.setState({ x, y });
        }}
      >
        <Animation type={"fadeInUp"} duration={150}>
          <Wrapper
            className={"progress-bars"}
            style={{
              backgroundColor: colors.card_color,
              borderRadius: 5,
              overlay: "hidden"
            }}
          >
            <Wrapper
              style={{
                position: "relative",
                overflow: "hidden",
                borderRadius: 5
              }}
            >
              {this.props.progress_bars.map((progress_bar, i) => {
                switch (progress_bar.type) {
                  case "import":
                    let progress = Math.ceil(
                      (parseInt(progress_bar.progress) /
                        parseInt(progress_bar.total_count)) *
                        100
                    );
                    if (progress > 100) {
                      progress = 100;
                    }

                    return (
                      <Wrapper
                        key={"progress-bar-" + i}
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          width: this.state.width,
                          height: this.state.height
                        }}
                      >
                        <Wrapper
                          style={{
                            backgroundColor: colors.card_color,
                            width: "100%"
                          }}
                        >
                          <Wrapper
                            className={"list-builder-progress-bar"}
                            style={{
                              backgroundColor: colors.success_color_muted,
                              width: !progress_bar?.completed
                                ? progress + "%"
                                : "100%",
                              height: this.state.height
                            }}
                          />
                        </Wrapper>

                        <Wrapper
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            width: this.state.width,
                            height: this.state.height,
                            justifyContent: "center"
                          }}
                        >
                          <Row>
                            {!progress_bar?.completed ? (
                              <Wrapper style={{ paddingLeft: 25 }}>
                                <Spin size={"small"} />
                              </Wrapper>
                            ) : (
                              <Wrapper style={{ paddingLeft: 25 }}>
                                <Icon
                                  icon={"check-circle"}
                                  color={colors.success_color}
                                />
                              </Wrapper>
                            )}
                            <Wrapper
                              style={{
                                paddingRight: 25,
                                paddingLeft: 25,
                                flex: 1
                              }}
                            >
                              <Copy
                                style={{ color: colors.actionable_text_color }}
                              >
                                <Bold>
                                  {!!progress_bar?.data?.list?.title
                                    ? progress_bar?.data?.list?.title
                                    : "Importing List..."}
                                </Bold>
                              </Copy>
                              <Copy style={{ fontSize: 12 }}>
                                {!progress_bar?.completed
                                  ? "Importing " +
                                    numberWithCommas(progress_bar.progress) +
                                    " of "
                                  : "Import complete for "}
                                <Bold>
                                  {numberWithCommas(progress_bar.total_count)}
                                </Bold>
                                {progress_bar?.total_count == 1
                                  ? "lead"
                                  : " leads"}
                              </Copy>
                            </Wrapper>
                            {progress_bar?.data?.list ? (
                              <InlineButton
                                onPress={() => {
                                  this.props.addListToListTabs(
                                    progress_bar?.data?.list
                                  );
                                  this.props.tabNavigation({ slug: "leads" });
                                  this.props.removeProgressBar(progress_bar.id);
                                }}
                                disabled={!progress_bar?.completed}
                              >
                                View List
                              </InlineButton>
                            ) : null}

                            <IconButton
                              onPress={() => {
                                this.props.removeProgressBar(progress_bar.id);
                              }}
                              tooltipPlacement={"top"}
                              tooltip={"Dismiss"}
                              icon={"close"}
                            />
                          </Row>
                        </Wrapper>
                      </Wrapper>
                    );

                  default:
                    return null;
                }
              })}
            </Wrapper>
          </Wrapper>
        </Animation>
      </Draggable>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, modal }) => {
  const { token, user } = auth;

  const { dark_mode, colors } = settings;
  const { window_width, window_height } = native;
  const { progress_bars } = modal;
  return {
    token,
    user,
    dark_mode,
    colors,
    window_width,
    window_height,
    progress_bars
  };
};

export default connect(mapStateToProps, {
  addListToListTabs,
  removeProgressBar,
  tabNavigation
})(ProgressBarsInner);
