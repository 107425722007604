import React, { Component } from "react";
import { connect } from "react-redux";

import { SelectItem, UpsellButton } from "app/NativeComponents/snippets";

import {
  setSkipTracePage,
  pushSidePanel,
  numberWithCommas
} from "app/NativeActions";
class SkipTraceButton extends Component {
  render() {
    const { button_search, title, selected_all, selected_leads } = this.props;
    const { token, filtered_count } = this.props;

    if (
      !button_search ||
      title.toLowerCase().includes(button_search.toLowerCase())
    ) {
      return (
        <UpsellButton
          meta_slug="skip_trace"
          onPress={() => {
            this.props.setSkipTracePage({
              title: "Skip Trace",
              subtitle: selected_all
                ? numberWithCommas(filtered_count) + " Leads"
                : numberWithCommas(selected_leads.length) + " Leads",
              new_filters:
                this.props.applied_filter && this.props.applied_filter.filters
                  ? this.props.applied_filter?.filters
                  : null,
              filters:
                this.props.applied_filter &&
                this.props.applied_filter.old_filters
                  ? this.props.applied_filter?.old_filters
                  : null,
              property_flags: this.props.lead_filters?.property_flags
                .map(({ value }) => value)
                .join(","),
              property_flags_and_or:
                this.props.lead_filters?.property_flags_and_or,
              search: this.props.search,
              list_id: this.props.active_list_tab?.id
                ? this.props.active_list_tab?.id
                : this.props.active_list_tab,
              select_all: selected_all ? 1 : 0,
              list_history_id: this.props.list_history_id || "",
              lead_ids: selected_all
                ? ""
                : selected_leads
                    .map(property => {
                      return property.deal.id;
                    })
                    .join(),
              number_of_leads: selected_all
                ? filtered_count
                : selected_leads.length,
              onSuccess: () => {
                this.props.clearAll();
              }
            });
            this.props.pushSidePanel({ slug: "skip_trace", overlay: true });
            this.props.toggleMenu(false);
          }}
          renderLockedChildren={({ hovering }) => {
            return (
              <>
                <SelectItem
                  select_type="none"
                  icon="keyboard-arrow-right"
                  noPress={true}
                  pressedIn={hovering}
                >
                  {title}
                </SelectItem>
              </>
            );
          }}
          renderChildren={({ hovering }) => {
            return (
              <>
                <SelectItem
                  select_type="none"
                  icon="keyboard-arrow-right"
                  noPress={true}
                  pressedIn={hovering}
                >
                  {title}
                </SelectItem>
              </>
            );
          }}
        />
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth }) => {
  const { token, user } = auth;
  return {
    token,
    user
  };
};

export default connect(mapStateToProps, {
  setSkipTracePage,
  pushSidePanel
})(SkipTraceButton);
