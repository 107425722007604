import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Wrapper } from "app/NativeComponents/common";

import {
  SearchBar,
  InlineButton,
  IconButton,
  SelectMultiple
} from "app/NativeComponents/snippets";

import ActionButtons from "./ActionButtons";

import {
  getHelperDescription,
  pushSidePanel,
  updateLeadFilters
} from "app/NativeActions";
class SearchAndFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      show_quick_filters: false
    };
  }
  render() {
    const { colors, isMobile } = this.props;
    return (
      <Row
        style={{
          padding: 20,
          paddingBottom: isMobile ? 0 : 20,
          paddingLeft: isMobile ? 10 : 20,
          paddingRight: isMobile ? 10 : 20,
          flexWrap: "wrap"
        }}
      >
        <Wrapper style={{ flex: 1 }}>
          <Row>
            <Wrapper style={isMobile ? { flex: 1 } : {}}>
              <SearchBar
                style={{
                  width: isMobile ? "100%" : 300,
                  marginBottom: isMobile ? 10 : 0,
                  marginRight: isMobile ? 10 : 0
                }}
                value={this.state.search}
                title="Search Leads"
                onChange={value => {
                  this.setState({
                    search: value
                  });
                }}
                showButton={true}
                disabledButton={
                  this.props.search_loading ||
                  (this.state.search == this.props.search &&
                    !this.props.search_loading)
                }
                buttonText={
                  this.state.search !== this.props.search &&
                  this.state.search.length == 0
                    ? "Clear"
                    : "Search"
                }
                buttonLoading={this.props.search_loading}
                onPress={() => {
                  this.props.updateSearch(
                    this.state.search.replace(/[^A-Z0-9]+/gi, " ")
                  );
                }}
                renderRight={
                  !!this.state.search && !this.props.search_loading
                    ? () => {
                        return (
                          <IconButton
                            icon={"close"}
                            button_type="small"
                            onPress={() => {
                              this.setState({
                                search: ""
                              });
                              this.props.updateSearch("");
                            }}
                          />
                        );
                      }
                    : null
                }
              />
            </Wrapper>

            <SelectMultiple
              style={{
                height: 50
              }}
              innerStyle={{
                paddingTop: 0,
                paddingBottom: 0
              }}
              show={this.state.show_quick_filters}
              onShow={s => {
                this.setState({
                  show_quick_filters: s
                });
              }}
              options={[
                {
                  label: "MLS Active",
                  value: "mls_active",
                  helper: getHelperDescription("mls_active")
                },
                {
                  label: "MLS Pending",
                  value: "mls_pending",
                  helper: getHelperDescription("mls_pending")
                },
                {
                  label: "MLS Contingent",
                  value: "mls_contingent",
                  helper: getHelperDescription("mls_contingent")
                },
                {
                  label: "Recently Sold",
                  value: "recently_sold",
                  helper: getHelperDescription("recently_sold")
                },
                {
                  label: "Expired Listings",
                  value: "expired_listing",
                  helper: getHelperDescription("expired_listing")
                },
                {
                  label: "Owner Occupied",
                  value: "owner_occupied",
                  helper: getHelperDescription("owner_occupied")
                },
                {
                  label: "Out of State Owners",
                  value: "out_of_state_owner",
                  helper: getHelperDescription("out_of_state_owner")
                },
                {
                  label: "Absentee Owners",
                  value: "absentee_owner",
                  helper: getHelperDescription("absentee_owner")
                },
                {
                  label: "Corporate Owners",
                  value: "corporate_owner",
                  helper: getHelperDescription("corporate_owner")
                },
                {
                  label: "Vacant Homes",
                  value: "vacant_home",
                  helper: getHelperDescription("vacant_home")
                },
                {
                  label: "Adjustable Loans",
                  value: "adjustable_loan",
                  helper: getHelperDescription("adjustable_loan")
                },
                {
                  label: "Bank Owned",
                  value: "bank_owned",
                  helper: getHelperDescription("bank_owned")
                },
                {
                  label: "Cash Buyers",
                  value: "cash_buyer",
                  helper: getHelperDescription("cash_buyer")
                },
                {
                  label: "Free and Clear",
                  value: "free_and_clear",
                  helper: getHelperDescription("free_and_clear")
                },
                {
                  label: "High Equity",
                  value: "high_equity",
                  helper: getHelperDescription("high_equity")
                },
                {
                  label: "Low Equity",
                  value: "low_equity",
                  helper: getHelperDescription("low_equity")
                },
                {
                  label: "Tax Delinquent",
                  value: "tax_delinquent",
                  helper: getHelperDescription("tax_delinquent")
                },
                {
                  label: "Preforeclosures",
                  value: "preforeclosure",
                  helper: getHelperDescription("preforeclosure")
                },
                {
                  label: "Foreclosures",
                  value: "foreclosure",
                  helper: getHelperDescription("foreclosure")
                },
                {
                  label: "Senior Owners",
                  value: "senior_owner",
                  helper: getHelperDescription("senior_owner")
                },
                {
                  label: "Tired Landlords",
                  value: "tired_landlord",
                  helper: getHelperDescription("tired_landlord")
                },

                {
                  label: "Zombie Properties",
                  value: "zombie_property",
                  helper: getHelperDescription("zombie_property")
                },
                {
                  label: "Off Market",
                  value: "off_market",
                  helper: getHelperDescription("off_market")
                },
                {
                  label: "Probates",
                  value: "pre_probate",
                  helper: getHelperDescription("pre_probate")
                },
                {
                  label: "Intrafamily Transfer",
                  value: "intra_family_transfer",
                  helper: getHelperDescription("intra_family_transfer")
                }
              ]}
              selected_options={
                this.props.lead_filters?.property_flags
                  ? this.props.lead_filters?.property_flags
                  : []
              }
              placeholder={"Quick Filters"}
              search_title="Search Filters"
              icon_right={"keyboard-arrow-down"}
              renderComponent={options => {
                return (
                  <InlineButton
                    style={
                      this.props.lead_filters?.property_flags.length > 0
                        ? {
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: colors.active_color,
                            borderStyle: "solid"
                          }
                        : {
                            borderRadius: 5
                          }
                    }
                    selected={
                      this.props.lead_filters?.property_flags.length > 0
                    }
                    icon_right={"keyboard-arrow-down"}
                    pressedIn={options?.hovering || options?.pressedIn}
                    noPress={true}
                  >
                    {this.props.lead_filters?.property_flags.length === 1
                      ? this.props.lead_filters?.property_flags[0].label
                      : this.props.lead_filters?.property_flags.length > 0
                      ? "Quick Filters [" +
                        this.props.lead_filters?.property_flags.length +
                        "]"
                      : "Quick Filters"}
                  </InlineButton>
                );
              }}
              renderBottom={() => {
                return (
                  <>
                    <Row
                      style={{
                        alignSelf: "stretch",
                        borderTopWidth: 1,
                        borderTopStyle: "solid",
                        borderTopColor: colors.border_color,
                        justifyContent: "space-between"
                      }}
                    >
                      <InlineButton
                        style={{}}
                        onPress={() => {
                          this.props.updateLeadFilters({
                            property_flags_and_or:
                              this.props.lead_filters?.property_flags_and_or ===
                              "or"
                                ? "and"
                                : "or"
                          });
                        }}
                        icon={
                          this.props.lead_filters?.property_flags_and_or ===
                          "and"
                            ? "toggle-on"
                            : "toggle-off"
                        }
                        iconColor={
                          this.props.lead_filters?.property_flags_and_or ===
                          "and"
                            ? colors.success_color
                            : null
                        }
                      >
                        Match All
                      </InlineButton>
                      <InlineButton
                        disabled={
                          this.props.lead_filters?.property_flags.length === 0
                        }
                        onPress={() => {
                          this.props.updateLeadFilters({
                            property_flags: []
                          });
                        }}
                      >
                        Clear All
                      </InlineButton>
                    </Row>
                  </>
                );
              }}
              onSelectedOptionsChange={options => {
                this.props.updateLeadFilters({
                  property_flags: options
                });
              }}
            />

            <InlineButton
              icon={"tune"}
              onPress={() => {
                this.setState(
                  {
                    show_quick_filters: false
                  },
                  () => {
                    this.props.pushSidePanel({
                      slug: "advanced_filters",
                      overlay: true
                    });
                  }
                );
              }}
            >
              All Filters
            </InlineButton>
          </Row>
        </Wrapper>
        {!isMobile ? (
          <Wrapper>
            <ActionButtons {...this.props} />
          </Wrapper>
        ) : null}
      </Row>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, lead }) => {
  const { token, user } = auth;
  const { device, window_height, isMobile } = native;
  const { colors, dark_mode } = settings;
  const { lead_filters } = lead;
  return {
    token,
    user,
    device,
    isMobile,
    window_height,
    colors,
    dark_mode,
    lead_filters
  };
};

export default connect(mapStateToProps, { pushSidePanel, updateLeadFilters })(
  SearchAndFilter
);
