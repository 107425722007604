import { isPointInPolygon } from "geolib";

export const checkIfCoordinatesAreInProperty = (coordinates, properties) => {
  let selected_properties = [];

  if (properties) {
    for (let i = 0; i < properties.length; i++) {
      if (properties[i].coordinates) {
        for (let j = 0; j < properties[i].coordinates.length; j++) {
          if (isPointInPolygon(coordinates, properties[i].coordinates[j])) {
            selected_properties.push(properties[i]);
          }
        }
      }
    }
  }

  if (selected_properties.length > 0) {
    return selected_properties;
  }

  return false;
};
