import {
  LOGOUT,
  REMOVE_DIALER_CONVERSATION_MESSAGE,
  SET_ACTIVE_DIALER_CONTACT,
  UPDATE_ACTIVE_CALL,
  UPDATE_DIALER_CONVERSATION,
  UPDATE_DIALER_CONVERSATION_MESSAGE,
  SET_DIALER_CONVERSATION,
  RESET_ACTIVE_CALL,
  SET_CURRENT_CALL_SESSION,
  UPDATE_CALLED_NUMBERS,
  UPDATE_QUEUED_NUMBERS,
  SET_REJECTED_CALL,
  SET_FOLLOW_UP_QUEUE_COUNT,
  UPDATE_CALL_SESSION_COUNT,
  TRIGGER_FOLLOW_UP_QUEUE_REFRESH
} from "app/DealMachineCore/types";

const INITIAL_STATE = {
  active_dialer_contact: null,
  active_call: {
    call_id: null,
    provider_id: null,
    provider: null,
    call_status: null,
    from_phone_number: null,
    answered_by: null,
    dropped_voicemail: false,
    stop_mail: false
  },
  called_numbers: [],
  current_conversation: [],

  follow_up_queue_count: 0,
  current_call_session: null,
  queued_numbers: [],
  default_dialer_settings: {
    scrub_dnc: false,
    scrub_landline: false,
    scrub_wireless: false,
    include_likely_owners: true,
    include_family: true,
    include_likely_renters: false,
    dialer_script: "",
    dialer_voicemail_script: "",
    not_interested_days: 120,
    no_answer_days: 30,
    warm_lead_days: 14,
    hot_lead_days: 7,
    unresponsive_number_tries: 12,
    ai_voicemail: false,
    use_callback_number_in_voicemail: false,
    practice_mode: false
  },
  rejected_call: null,
  follow_up_queue_refresh: false
};

import moment from "moment";
import { setDialerConversation } from "../actions";

const combineQueueItems = (current, newItems) => {
  let combined = [...current];

  newItems.forEach(newItem => {
    let exists = current.find(item => item.number == newItem.number);

    if (!exists) {
      combined.push(newItem);
    }
  });

  return combined;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };

    case SET_ACTIVE_DIALER_CONTACT:
      return {
        ...state,
        active_dialer_contact: action.payload.contact,
        queued_numbers: action.payload.queued_numbers
          ? action.payload.queued_numbers
          : state.queued_numbers,
        current_conversation: action.payload.current_conversation
          ? action.payload.current_conversation
          : [],
        active_call: action.payload.active_call
          ? action.payload.active_call
          : INITIAL_STATE.active_call
      };

    case SET_REJECTED_CALL:
      return {
        ...state,
        rejected_call: action.payload
      };

    case SET_DIALER_CONVERSATION:
      return {
        ...state,
        current_conversation:
          state.active_call?.call_id == action.payload.call_id &&
          action.payload.current_conversation
            ? action.payload.current_conversation
            : state.current_conversation,
        active_call:
          state.active_call?.call_id == action.payload.call_id
            ? {
                ...state.active_call,
                call_results: action.payload.call_results
                  ? action.payload.call_results
                  : state.active_call.call_results,
                note: action.payload.call_note
                  ? action.payload.call_note
                  : state.active_call.note
              }
            : state.active_call
      };

    case UPDATE_CALL_SESSION_COUNT:
      return {
        ...state,
        current_call_session: {
          ...state.current_call_session,
          total_items: action.payload
        }
      };

    case UPDATE_ACTIVE_CALL:
      return {
        ...state,
        active_call: {
          ...state.active_call,
          ...action.payload
        }
      };

    case RESET_ACTIVE_CALL:
      return {
        ...state,
        active_call: INITIAL_STATE.active_call
      };

    case UPDATE_DIALER_CONVERSATION:
      return {
        ...state,
        current_conversation:
          action.payload.complete &&
          (action.payload.type === "inbound" ||
            action.payload.type === "outbound")
            ? action.payload.type === "inbound" &&
              state.current_conversation.find(
                message => message.subtype === "current_inbound_message"
              )
              ? state.current_conversation.map(message => {
                  if (message.subtype === "current_inbound_message") {
                    return {
                      id: action.payload.id,
                      type: action.payload.type,
                      subtype: !!action.payload.subtype
                        ? action.payload.subtype
                        : action.payload.type,
                      url: action.payload.url,
                      message: action.payload.message,
                      timestamp: moment().toISOString(),
                      cleaned: false
                    };
                  }
                  return message;
                })
              : action.payload.type === "outbound" &&
                state.current_conversation.find(
                  message => message.subtype === "current_outbound_message"
                )
              ? state.current_conversation.map(message => {
                  if (message.subtype === "current_outbound_message") {
                    return {
                      id: action.payload.id,
                      type: action.payload.type,
                      subtype: !!action.payload.subtype
                        ? action.payload.subtype
                        : action.payload.type,
                      url: action.payload.url,
                      message: action.payload.message,
                      timestamp: moment().toISOString(),
                      cleaned: false
                    };
                  }
                  return message;
                })
              : [
                  ...state.current_conversation,
                  {
                    id: action.payload.id,
                    type: action.payload.type,
                    subtype: !!action.payload.subtype
                      ? action.payload.subtype
                      : action.payload.type,
                    url: action.payload.url,
                    message: action.payload.message,
                    timestamp: moment().toISOString(),
                    cleaned: false
                  }
                ]
            : (action.payload.type === "inbound" &&
                state.current_conversation.find(
                  message => message.subtype === "current_inbound_message"
                )) ||
              (action.payload.type === "outbound" &&
                state.current_conversation.find(
                  message => message.subtype === "current_outbound_message"
                ))
            ? state.current_conversation.map(message => {
                if (
                  message.subtype === "current_inbound_message" &&
                  action.payload.type === "inbound"
                ) {
                  return {
                    id: action.payload.id,
                    type: action.payload.type,
                    subtype: "current_inbound_message",
                    message: action.payload.message,
                    timestamp: moment().toISOString(),
                    cleaned: false
                  };
                }
                if (
                  message.subtype === "current_outbound_message" &&
                  action.payload.type === "outbound"
                ) {
                  return {
                    id: action.payload.id,
                    type: action.payload.type,
                    subtype: "current_outbound_message",
                    message: action.payload.message,
                    timestamp: moment().toISOString(),
                    cleaned: false
                  };
                }

                return message;
              })
            : [
                ...state.current_conversation,
                {
                  id: action.payload.id,
                  type: action.payload.type,
                  subtype:
                    action.payload.type === "inbound"
                      ? "current_inbound_message"
                      : action.payload.type === "outbound"
                      ? "current_outbound_message"
                      : action.payload.type,
                  message: action.payload.message,
                  timestamp: moment().toISOString(),
                  cleaned: false
                }
              ]
      };

    case UPDATE_DIALER_CONVERSATION_MESSAGE:
      return {
        ...state,
        //update the message matching the id from the payload with the entire payload
        current_conversation: state.current_conversation.map(message => {
          if (
            message?.id == action.payload?.id &&
            message?.type == action.payload?.type
          ) {
            return action.payload;
          }
          return message;
        })
      };

    case REMOVE_DIALER_CONVERSATION_MESSAGE:
      return {
        ...state,
        current_conversation: state.current_conversation.filter(
          message => message.id != action.payload.id
        )
      };

    case SET_CURRENT_CALL_SESSION:
      return {
        ...state,
        current_call_session: action.payload?.current_call_session,
        queued_numbers: action.payload?.queued_numbers
          ? action.payload?.queued_numbers.length > 0
            ? combineQueueItems(
                state.queued_numbers,
                action.payload.queued_numbers
              )
            : []
          : state.queued_numbers,
        called_numbers:
          !action.payload?.current_call_session ||
          action.payload?.current_call_session.id !=
            state.current_call_session?.id
            ? []
            : state.called_numbers
      };

    case UPDATE_CALLED_NUMBERS:
      return {
        ...state,
        called_numbers: action.payload
      };

    case UPDATE_QUEUED_NUMBERS:
      return {
        ...state,
        queued_numbers:
          action.payload?.reset_queue ||
          !state.current_call_session ||
          state.current_call_session?.completed
            ? action.payload?.queued_numbers
            : [...action.payload?.queued_numbers, ...state.queued_numbers]
      };

    case SET_FOLLOW_UP_QUEUE_COUNT:
      return {
        ...state,
        follow_up_queue_count: action.payload
      };

    case TRIGGER_FOLLOW_UP_QUEUE_REFRESH:
      return {
        ...state,
        follow_up_queue_refresh: action.payload
      };

    default:
      return state;
  }
};
