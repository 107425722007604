import React, { PureComponent } from "react";
import { Wrapper, Row, Icon, Copy, Bold } from "app/NativeComponents/common";
import {
  SelectBox,
  InlineButton,
  IconButton,
  PopoverMenu
} from "app/NativeComponents/snippets";
const IGNORE_VALUE = "IGNORE";

class MappingObject extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      show_samples: false
    };
  }

  checkIfThereAreSamples(index) {
    for (let i = 0; i < this.props.upload_list_info?.sample_rows?.length; i++) {
      const row = this.props.upload_list_info?.sample_rows[i];
      if (!!row[index]) {
        return true;
      }
    }

    return false;
  }

  render() {
    return (
      <>
        <Wrapper style={{ flex: 1 }}>
          <SelectBox
            pressedIn={this.state.show_samples}
            renderMiddle={
              this.props.header_item.header_mapped_value == IGNORE_VALUE
                ? () => {
                    return (
                      <Row>
                        <Copy
                          style={{
                            textDecoration: "line-through",
                            color: this.props.colors.light_text_color
                          }}
                        >
                          {this.props.header_item.header_title}
                        </Copy>
                      </Row>
                    );
                  }
                : null
            }
            renderRight={
              this.props.header_item.header_mapped_value == IGNORE_VALUE
                ? () => {
                    return (
                      <Row style={{ padding: 25 }}>
                        <Copy
                          style={{
                            color: this.props.colors.light_text_color
                          }}
                        >
                          Skipped
                        </Copy>
                      </Row>
                    );
                  }
                : this.props.header_item.header_mapped_value
                ? () => {
                    return (
                      <Row style={{ padding: 25 }}>
                        <Icon
                          style={{
                            marginRight: 5
                          }}
                          color={this.props.colors.actionable_text_color}
                          icon="check"
                        />
                        <Copy
                          style={{
                            color: this.props.colors.actionable_text_color
                          }}
                        >
                          <Bold>Mapped</Bold>
                        </Copy>
                      </Row>
                    );
                  }
                : null
            }
            renderHover={() => {
              if (
                this.checkIfThereAreSamples(
                  this.props.header_item.header_index
                ) &&
                !this.props.header_item.header_mapped_value
              ) {
                return (
                  <PopoverMenu
                    show={this.state.show_samples}
                    no_swipe={true}
                    no_cancel={true}
                    popoverSheetTop={300}
                    popover_width={350}
                    onShow={s => {
                      this.setState({
                        show_samples: s
                      });
                    }}
                    popoverPlacement={"left"}
                    popover_title={"Samples from your imported list"}
                    includeCloseButton={true}
                    renderComponent={({ hovering }) => {
                      return (
                        <InlineButton
                          noPress={true}
                          hovering={hovering}
                          pressedIn={this.state.show_samples}
                        >
                          Samples
                        </InlineButton>
                      );
                    }}
                    renderMenu={() => {
                      return (
                        <Wrapper
                          style={{ padding: 25, flex: 1, alignSelf: "stretch" }}
                        >
                          {this.props.upload_list_info.sample_rows.map(
                            (row, i) => {
                              if (!!row[this.props.header_item.header_index]) {
                                return (
                                  <Row
                                    style={{ alignSelf: "stretch" }}
                                    key={"row_" + i}
                                  >
                                    <Icon
                                      size={8}
                                      icon="fiber-manual-record"
                                      style={{
                                        marginRight: 5
                                      }}
                                    />
                                    <Wrapper style={{ flex: 1 }}>
                                      <Copy
                                        key={i}
                                        style={{
                                          whiteSpace: "nowrap"
                                        }}
                                      >
                                        {!!row[
                                          this.props.header_item.header_index
                                        ]
                                          ? row[
                                              this.props.header_item
                                                .header_index
                                            ]
                                          : ""}
                                      </Copy>
                                    </Wrapper>
                                  </Row>
                                );
                              }
                              return null;
                            }
                          )}
                        </Wrapper>
                      );
                    }}
                    menu_items={null}
                  />
                );
              }
              return null;
            }}
            //icon_right="keyboard-arrow-down"
            items={this.props.header_options}
            disabled={false}
            value={this.props.header_item.header_mapped_value}
            placeholder={this.props.header_item.header_title}
            show={this.state.focused}
            onFocus={() => {
              this.setState({
                focused: true
              });
            }}
            onBlur={() => {
              this.setState({
                focused: false
              });
            }}
            onSelect={item => {
              this.setState({
                focused: false
              });
              this.props.setHeaderOption(this.props.header_index, item);
            }}
          />
        </Wrapper>
      </>
    );
  }
}
export default MappingObject;
