import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Row,
  Wrapper,
  Card,
  Icon,
  Copy,
  InnerCopy,
  Title,
  Bold
} from "app/NativeComponents/common";
import {
  CircleButton,
  InformationItem,
  InlineButton,
  SelectItem,
  IconButton,
  PopoverMenu,
  AttentionBox,
  CloseButton,
  SearchBar,
  List
} from "app/NativeComponents/snippets";
import {
  pushSidePanel,
  callPhoneNumber,
  copyToClipBoard,
  formatUsPhone,
  textPhoneNumber,
  toTitleCase,
  showSuccess,
  openEmail
} from "app/NativeActions";
import moment from "moment";
import { store } from "app/store";
import PhoneItem from "app/DealMachineCore/components/Contact/PhoneItem";
import EmailItem from "app/DealMachineCore/components/Contact/EmailItem";
import PersonFlags from "app/DealMachineCore/components/Contact/PersonFlags";
class PersonItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_warning: false,
      show_phones: false,
      show_emails: false,
      phone_height: 0,
      email_height: 0,
      warning_height: 0
    };
  }
  render() {
    const { person, property, colors, device } = this.props;

    let name = "";
    let description = "";

    let formated_birthday = "";
    let age = "";

    if (!!person?.mob) {
      let birthday = person.mob;
      birthday = birthday.slice(0, -2) + "-" + birthday.slice(-2);
      if (birthday === "0000-00") {
        birthday = "";
        formated_birthday = "";
      } else if (birthday.slice(-2) === "00") {
        birthday = birthday.slice(0, -2);
        birthday += "01";
        formated_birthday = moment(birthday).format("MMMM YYYY");
      } else {
        formated_birthday = moment(birthday).format("MMMM YYYY");
      }

      //calculate age in years
      if (!!birthday) {
        age = moment().diff(birthday, "years");
        description = age + " years old";
      }
    }

    let phones = [];
    if (!!person?.phone_1 && !person?.phone_1_blocked) {
      phones.push({
        number: person.phone_1,
        activity_status: person.phone_1_activity_status,
        contability_score: person.phone_1_contability_score,
        usage_2_months: person.phone_1_usage_2_months,
        usage_12_months: person.phone_1_usage_12_months,
        owner: person?.phone_1_owner,
        type: person?.phone_1_type,
        prepaid_indicator: person?.phone_1_prepaid_indicator,
        do_not_call_flag: person?.phone_1_do_not_call,
        call_status: person?.phone_1_status,
        known_litigator: person?.phone_1_known_litigator
      });
    }

    if (!!person?.phone_2 && !person?.phone_2_blocked) {
      phones.push({
        number: person.phone_2,
        activity_status: person.phone_2_activity_status,
        contability_score: person.phone_2_contability_score,
        usage_2_months: person.phone_2_usage_2_months,
        usage_12_months: person.phone_2_usage_12_months,
        owner: person?.phone_2_owner,
        type: person?.phone_2_type,
        prepaid_indicator: person?.phone_2_prepaid_indicator,
        do_not_call_flag: person?.phone_2_do_not_call,
        call_status: person?.phone_2_status,
        known_litigator: person?.phone_2_known_litigator
      });
    }

    if (!!person?.phone_3 && !person?.phone_3_blocked) {
      phones.push({
        number: person.phone_3,
        activity_status: person.phone_3_activity_status,
        contability_score: person.phone_3_contability_score,
        usage_2_months: person.phone_3_usage_2_months,
        usage_12_months: person.phone_3_usage_12_months,
        owner: person?.phone_3_owner,
        type: person?.phone_3_type,
        prepaid_indicator: person?.phone_3_prepaid_indicator,
        do_not_call_flag: person?.phone_3_do_not_call,
        call_status: person?.phone_3_status,
        known_litigator: person?.phone_3_known_litigator
      });
    }

    let emails = [];
    if (!!person?.email_address_1 && !person?.email_address_1_blocked) {
      emails.push({
        email: person.email_address_1,
        linkage_score: person.email_address_1_linkagescore
      });
    }

    if (!!person?.email_address_2 && !person?.email_address_2_blocked) {
      emails.push({
        email: person.email_address_2,
        linkage_score: person.email_address_2_linkagescore
      });
    }

    if (!!person?.email_address_3 && !person?.email_address_3_blocked) {
      emails.push({
        email: person.email_address_3,
        linkage_score: person.email_address_3_linkagescore
      });
    }

    let warning_date = null;
    if (
      (!!property?.mls?.sold_date &&
        moment(person?.date_updated).subtract(3, "months") <
          moment(property?.mls?.sold_date)) ||
      (!!property?.saledate &&
        moment(person?.date_updated).subtract(3, "months") <
          moment(property?.saledate))
    ) {
      if (
        !!property?.mls?.sold_date &&
        (moment(property?.saledate) > moment(property?.mls?.sold_date) ||
          !property?.saledate)
      ) {
        warning_date = moment(property?.mls?.sold_date).format("MMM Do, YYYY");
      } else {
        warning_date = moment(property?.saledate).format("MMM Do, YYYY");
      }
    }

    let is_corporate = false;
    if (property && property?.property_flags) {
      for (let i = 0; i < property?.property_flags?.length; i++) {
        if (property?.property_flags[i]?.value === "corporate_owner") {
          is_corporate = true;
        }
      }
    }

    return (
      <SelectItem
        select_type={"none"}
        pressedIn={false}
        noPress={this.props.noPress}
        onPress={() => {
          if (!this.state.show_phones && !this.state.show_emails) {
            this.props.pushSidePanel({
              slug: "contact",
              expanded_side_panel: this.props.expanded,
              overlay: true,
              id: person?.individual_key,
              data: {
                person,
                property: this.props.property,
                expanded: this.props.expanded,
                propertyUpdated: this.props.propertyUpdated
              }
            });
          }
        }}
        label={""}
        subtext={""}
        selected={true}
        style={
          !this.props.no_border
            ? {
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: colors.border_color,
                marginRight: device === "desktop" ? 5 : 0,
                marginLeft:
                  this.props.style_type === "small"
                    ? 0
                    : device === "desktop"
                    ? 5
                    : 0,
                height: this.props.style_type === "small" ? 80 : 100,
                justifyContent: "center"
              }
            : {}
        }
        renderRight={() => {
          return (
            <Row
              style={{
                marginRight: this.props.noPress ? 20 : 0
              }}
            >
              {!this.props.no_options ? (
                <>
                  {emails?.length > 0 ? (
                    <PopoverMenu
                      show={this.state.show_emails}
                      no_swipe={true}
                      no_cancel={false}
                      popover_dismiss={"Dismiss"}
                      onShow={s => {
                        this.setState({
                          show_emails: s
                        });
                      }}
                      popover_width={450}
                      popoverSheetTop={this.state.email_height + 200}
                      popover_title={"Email Addresses"}
                      includeCloseButton={true}
                      hideWithOutsideClick={true}
                      disabled={false}
                      renderComponent={({ hovering, pressed_in }) => {
                        return (
                          <CircleButton
                            button_type={"small"}
                            noPress={true}
                            pressedIn={
                              hovering || pressed_in || this.state.show_emails
                            }
                            style={{ marginLeft: 0 }}
                            icon="alternate-email"
                            show_notification={true}
                            notification_color={colors.active_color}
                            notification_number={emails?.length}
                            tooltip={"Email Addresses"}
                            tooltipPosition={"bottom"}
                          />
                        );
                      }}
                      renderMenu={() => {
                        return (
                          <>
                            <Wrapper style={{ alignSelf: "stretch" }}>
                              <Wrapper
                                onLayout={event => {
                                  const { x, y, width, height } =
                                    event?.nativeEvent?.layout;
                                  this.setState({
                                    email_height: height
                                  });
                                }}
                              >
                                {this.props.device === "desktop" ? (
                                  <Wrapper
                                    style={{
                                      padding: 25,
                                      borderBottomWidth: 1,
                                      borderBottomStyle: "solid",
                                      borderBottomColor: colors.border_color
                                    }}
                                  >
                                    <Copy>
                                      <Bold>
                                        Email addresses for {person?.full_name}
                                      </Bold>
                                    </Copy>
                                  </Wrapper>
                                ) : null}
                                {emails.map((email, i) => {
                                  return (
                                    <EmailItem
                                      key={"email_" + i}
                                      email={email}
                                    />
                                  );
                                })}
                                {this.props.device === "desktop" ? (
                                  <CloseButton
                                    style={{ marginTop: 10 }}
                                    onPress={() => {
                                      this.setState({
                                        show_emails: false
                                      });
                                    }}
                                  />
                                ) : null}
                              </Wrapper>
                            </Wrapper>
                          </>
                        );
                      }}
                      menu_items={null}
                    />
                  ) : null}
                  {phones?.length > 0 ? (
                    <PopoverMenu
                      show={this.state.show_phones}
                      no_swipe={true}
                      no_cancel={false}
                      popover_dismiss={"Dismiss"}
                      onShow={s => {
                        this.setState({
                          show_phones: s
                        });
                      }}
                      popover_width={450}
                      popoverSheetTop={this.state.phone_height + 200}
                      popover_title={"Phone Numbers"}
                      includeCloseButton={true}
                      disabled={false}
                      hideWithOutsideClick={true}
                      renderComponent={({ hovering, pressed_in }) => {
                        return (
                          <CircleButton
                            button_type={"small"}
                            noPress={true}
                            pressedIn={
                              hovering || pressed_in || this.state.show_phones
                            }
                            style={{ marginLeft: 0 }}
                            icon="phone"
                            show_notification={true}
                            notification_color={colors.active_color}
                            notification_number={phones?.length}
                            tooltip={"Phone Numbers"}
                            tooltipPosition={"bottom"}
                          />
                        );
                      }}
                      renderMenu={() => {
                        return (
                          <>
                            <Wrapper style={{ alignSelf: "stretch" }}>
                              <Wrapper
                                onLayout={event => {
                                  const { x, y, width, height } =
                                    event?.nativeEvent?.layout;
                                  this.setState({
                                    phone_height: height
                                  });
                                }}
                              >
                                {this.props.device === "desktop" ? (
                                  <Wrapper
                                    style={{
                                      padding: 25,
                                      borderBottomWidth: 1,
                                      borderBottomStyle: "solid",
                                      borderBottomColor: colors.border_color
                                    }}
                                  >
                                    <Copy>
                                      <Bold>
                                        Phone numbers for {person?.full_name}
                                      </Bold>
                                    </Copy>
                                  </Wrapper>
                                ) : null}
                                {phones.map((phone, i) => {
                                  return (
                                    <PhoneItem
                                      key={"phone_" + i}
                                      phone={phone}
                                      contact={person}
                                      property={property}
                                      onCall={() => {
                                        this.setState({
                                          show_phones: false
                                        });
                                      }}
                                    />
                                  );
                                })}
                                {this.props.device === "desktop" ? (
                                  <CloseButton
                                    style={{ marginTop: 10 }}
                                    onPress={() => {
                                      this.setState({
                                        show_phones: false
                                      });
                                    }}
                                  />
                                ) : null}
                              </Wrapper>
                            </Wrapper>
                          </>
                        );
                      }}
                      menu_items={null}
                    />
                  ) : null}
                </>
              ) : null}
              {!this.props.noPress ? (
                <IconButton
                  button_type={"small"}
                  icon={"keyboard-arrow-right"}
                  noPress={true}
                />
              ) : null}
            </Row>
          );
        }}
        innerStyle={
          this.props.style_type === "small"
            ? { padding: 15 }
            : !!warning_date && this.props.display_warning
            ? { paddingLeft: 0, paddingRight: 5 }
            : { paddingLeft: 25, paddingRight: 5 }
        }
        renderContent={() => {
          return (
            <Row style={{ flex: 1 }}>
              {!!warning_date && this.props.display_warning ? (
                <PopoverMenu
                  show={this.state.show_warning}
                  no_swipe={true}
                  no_cancel={false}
                  popover_dismiss={"Dismiss"}
                  onShow={s => {
                    this.setState({
                      show_warning: s
                    });
                  }}
                  popover_width={450}
                  popoverSheetTop={this.state.warning_height + 120}
                  popoverPlacement={"bottom"}
                  tooltip={"What does this mean?"}
                  tooltipPosition={"top"}
                  renderComponent={options => {
                    return (
                      <IconButton
                        pressedIn={options.hovering || options.pressedIn}
                        noPress={true}
                        icon="warning"
                        button_type={"small"}
                        icon_color={colors.orange_color}
                      />
                    );
                  }}
                  menu_items={null}
                  renderMenu={() => {
                    return (
                      <Wrapper
                        onLayout={event => {
                          const { x, y, width, height } =
                            event?.nativeEvent?.layout;
                          this.setState({
                            warning_height: height
                          });
                        }}
                      >
                        <Wrapper
                          style={{
                            padding: 25,
                            alignSelf: "stretch",
                            position: "relative"
                          }}
                        >
                          <Row style={{ marginBottom: 10 }}>
                            <Title>Possible Matching Error</Title>
                          </Row>

                          <Copy style={{ marginBottom: 10 }}>
                            Our records indicate that this property was sold on{" "}
                            <Bold>{warning_date}</Bold>. Our records for this
                            person were last updated on{" "}
                            <Bold>
                              {moment(person?.date_updated).format(
                                "MMM Do, YYYY"
                              )}
                              . Since the sold date was within 3 months of our
                              last data update, there is a likelihood of a
                              mismatch.
                            </Bold>
                          </Copy>
                          <Copy>
                            Our person data refreshes every month. Check back
                            again in the next 30 days.
                          </Copy>
                        </Wrapper>

                        <CloseButton
                          onPress={() => {
                            this.setState({
                              show_warning: false
                            });
                          }}
                        />
                      </Wrapper>
                    );
                  }}
                />
              ) : null}

              <Wrapper style={{ flex: 1 }}>
                <Row>
                  <Copy style={{ color: colors.actionable_text_color }}>
                    <Bold>{person?.full_name}</Bold>

                    {age > 0 && this.props.include_search_information ? (
                      <InnerCopy
                        style={{
                          marginLeft: 5,
                          color: colors.light_text_color,
                          fontSize: 12
                        }}
                      >
                        {age + " years old"}
                      </InnerCopy>
                    ) : null}
                  </Copy>

                  {this.props.property &&
                  person?.linked_properties &&
                  person?.linked_properties.includes(this.props.property?.id) &&
                  this.props.show_linked ? (
                    <Wrapper
                      style={{
                        backgroundColor: colors.active_color,
                        width: 20,
                        height: 20,
                        borderRadius: 40,
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: 10
                      }}
                      tooltip={"Linked to property"}
                      tooltipPlacement={"top"}
                    >
                      <Icon
                        icon={"check"}
                        size={14}
                        color={colors.white_text_color}
                      />
                    </Wrapper>
                  ) : null}
                </Row>

                {this.props.include_search_information ? (
                  <Wrapper style={{ paddingTop: 10 }}>
                    <Copy>{person.primary_address_full}</Copy>
                  </Wrapper>
                ) : !!person?.person_flags && this.props.use_person_flags ? (
                  <Wrapper style={{ paddingTop: 10 }}>
                    <PersonFlags person={person} round={true} />
                  </Wrapper>
                ) : this.props.use_linking_flags ? (
                  <Wrapper style={{ marginTop: 5 }}>
                    <Row
                      style={{
                        flexWrap: "wrap"
                      }}
                    >
                      {person.custom_linked_contact == 1 ? (
                        <Wrapper
                          style={{ marginRight: 10, marginBottom: 5 }}
                          tooltip={
                            "Your team has linked this contact to this property."
                          }
                          tooltipPlacement={"top"}
                        >
                          <Row>
                            <Icon
                              icon={"check"}
                              size={14}
                              color={colors.active_color}
                              style={{ marginRight: 5 }}
                            />
                            <Copy style={{ fontSize: 10 }}>
                              <Bold>{"Custom Linked Contact"}</Bold>
                            </Copy>
                          </Row>
                        </Wrapper>
                      ) : (
                        <>
                          {person.likely_owner ||
                          (is_corporate && person?.mailing_address_match) ? (
                            <Wrapper
                              style={{ marginRight: 10, marginBottom: 5 }}
                              tooltip={
                                person.likely_owner
                                  ? "The individual's address and name match the property's mailing address and owner listed in the county records."
                                  : "The individual's address matches the company's mailing address listed in the county records"
                              }
                              tooltipPlacement={"top"}
                            >
                              <Row>
                                <Icon
                                  icon={
                                    person.likely_owner ? "check" : "business"
                                  }
                                  size={14}
                                  color={colors.success_color}
                                  style={{ marginRight: 5 }}
                                />
                                <Copy style={{ fontSize: 10 }}>
                                  <Bold>
                                    {person.likely_owner
                                      ? "Likely Owner"
                                      : "Likely Owner"}
                                  </Bold>
                                </Copy>
                              </Row>
                            </Wrapper>
                          ) : null}

                          {!person.likely_owner && person.in_owner_family ? (
                            <Wrapper
                              style={{ marginRight: 10, marginBottom: 5 }}
                              tooltip={
                                "The person's last name matches the property owner's name listed in the county records."
                              }
                              tooltipPlacement={"top"}
                            >
                              <Row>
                                <Icon
                                  icon={"group"}
                                  size={14}
                                  color={colors.success_color}
                                  style={{ marginRight: 5 }}
                                />
                                <Copy style={{ fontSize: 10 }}>Family</Copy>
                              </Row>
                            </Wrapper>
                          ) : null}

                          {person.resident ? (
                            <Wrapper
                              tooltip={"The person resides at the property."}
                              tooltipPlacement={"top"}
                              style={{ marginRight: 10, marginBottom: 5 }}
                            >
                              <Row>
                                <Icon
                                  icon={"house"}
                                  size={14}
                                  color={colors.success_color}
                                  style={{ marginRight: 5 }}
                                />
                                <Copy style={{ fontSize: 10 }}>Resident</Copy>
                              </Row>
                            </Wrapper>
                          ) : null}

                          {person.likely_renting ? (
                            <Wrapper
                              tooltip={
                                "The individual's name doesn't align with the listed property owner's, and they don't appear to be related. Additionally, their living address is different from the listed mailing address, suggesting they are a renter."
                              }
                              tooltipPlacement={"top"}
                              style={{ marginRight: 10, marginBottom: 5 }}
                            >
                              <Row>
                                <Icon
                                  icon={"attach-money"}
                                  size={14}
                                  color={colors.success_color}
                                  style={{ marginRight: 5 }}
                                />
                                <Copy style={{ fontSize: 10 }}>
                                  Likely Renting
                                </Copy>
                              </Row>
                            </Wrapper>
                          ) : null}
                        </>
                      )}
                    </Row>
                  </Wrapper>
                ) : null}
              </Wrapper>
            </Row>
          );
        }}
      />
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device } = native;
  return {
    token,
    user,
    colors,
    device
  };
};

export default connect(mapStateToProps, { showSuccess, pushSidePanel })(
  PersonItem
);
