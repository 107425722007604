import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  ModalOverlay,
  Animation,
  RightPanelModal,
  KeyboardView,
  Wrapper,
  Copy,
  Bold,
  CardBody,
  Gradient,
  Row,
  Icon,
} from "app/NativeComponents/common";
import {
  NewHeader,
  Header,
  InformationItem,
  ConfirmLabel,
} from "app/NativeComponents/snippets";
import SelectMailerCampaign from "./SelectMailerCampaign";
import ConfirmInvoice from "./ConfirmInvoice";
import {
  setStartMailerCampaignPage,
  popSidePanel,
  getMailerCampaigns,
  updateLead,
} from "app/NativeActions";

class StartMailerCampaignModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_campaign:
        props.start_mailer_campaign_page?.selected_campaign || null,
      page: props.start_mailer_campaign_page?.selected_campaign
        ? "confirm_campaign"
        : "start_campaign",
      auto_reload: props.user?.team_default_credit_reload > 0 ? true : false,
      start_campaign_loading: false,
    };

    this.selectCampaign = this.selectCampaign.bind(this);
    this.toggleCreditReload = this.toggleCreditReload.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.show_start_mailer_campaign_page !==
        prevProps.show_start_mailer_campaign_page ||
      this.props.start_mailer_campaign_page !==
        prevProps.start_mailer_campaign_page
    ) {
      if (this.props.start_mailer_campaign_page.selected_campaign) {
        this.setState({
          selected_campaign:
            this.props.start_mailer_campaign_page.selected_campaign,
          page: "confirm_campaign",
          auto_reload:
            this.props.user?.team_default_credit_reload > 0 ? true : false,
        });
      } else {
        this.setState({
          selected_campaign: null,
          page: "start_campaign",
          auto_reload:
            this.props.user?.team_default_credit_reload > 0 ? true : false,
        });
      }
    }
  }

  selectCampaign(campaign, confirm = false) {
    const { user, token, start_mailer_campaign_page } = this.props;
    if (start_mailer_campaign_page?.number_of_leads == 1) {
      this.props.updateLead({
        token,
        deal_ids: start_mailer_campaign_page.lead_ids,
        list_history_id: start_mailer_campaign_page.list_history_id || "",
        filters: start_mailer_campaign_page.filters,
        filter_lists: start_mailer_campaign_page.filter_lists,
        campaign_id: campaign.id,
        type: "start_mailers",
        no_loading: true,
        onLoading: () => {
          this.setState({ start_campaign_loading: true });
        },
        onError: () => {
          this.setState({ start_campaign_loading: false });
        },
        onSuccess: (results) => {
          this.setState({ start_campaign_loading: false });
          if (start_mailer_campaign_page?.onSuccess) {
            start_mailer_campaign_page.onSuccess(results);
          }
          this.props.popSidePanel();
        },
      });
    } else {
      this.setState({
        selected_campaign: campaign,
        page: "confirm_campaign",
      });
    }
  }

  renderBody() {
    const { page, selected_campaign, auto_reload } = this.state;
    const {
      right_panel,
      start_mailer_campaign_page,
      sending_queue_counts,
      colors,
      user,
      isMobile,
    } = this.props;

    switch (page) {
      case "start_campaign":
        return (
          <>
            <NewHeader
              title={start_mailer_campaign_page.title}
              subtitle={start_mailer_campaign_page.subtitle}
              leftButton={{
                onPress: () => {
                  this.props.popSidePanel();
                },
                icon: isMobile ? "arrow-back" : "close",
              }}
            />
            <SelectMailerCampaign
              selectCampaign={this.selectCampaign}
              start_campaign_loading={this.state.start_campaign_loading}
            />
          </>
        );

      case "confirm_campaign":
        return (
          <>
            <NewHeader
              title={"Confirm Mail"}
              subtitle={start_mailer_campaign_page.subtitle}
              leftButton={{
                onPress: () => {
                  this.setState({
                    page: "start_campaign",
                    selected_campaign: null,
                    auto_reload:
                      user?.team_default_credit_reload > 0 ? true : false,
                  });
                },
                icon: "arrow-back",
              }}
            />

            <ConfirmInvoice
              invoice_type="mailer_campaign"
              auto_reload={auto_reload}
              confirm_button={
                start_mailer_campaign_page.number_of_leads > 1
                  ? "Start Mail"
                  : "Start Mail"
              }
              onConfirm={(results) => {
                if (start_mailer_campaign_page?.onSuccess) {
                  start_mailer_campaign_page.onSuccess(results);
                }
                this.props.popSidePanel();
              }}
              selected_campaign={selected_campaign}
              number_of_leads={start_mailer_campaign_page.number_of_leads}
              number_of_addresses={
                start_mailer_campaign_page.number_of_addresses
              }
              toggleCreditReload={this.toggleCreditReload}
              select_all={start_mailer_campaign_page.select_all}
              lead_ids={start_mailer_campaign_page.lead_ids}
              new_filters={start_mailer_campaign_page.new_filters}
              filters={start_mailer_campaign_page.filters}
              list_history_id={start_mailer_campaign_page.list_history_id}
              list_id={start_mailer_campaign_page.list_id}
              search={start_mailer_campaign_page.search}
              property_flags={start_mailer_campaign_page.property_flags}
              property_flags_and_or={
                start_mailer_campaign_page.property_flags_and_or
              }
              user={user}
              sending_queue_counts={sending_queue_counts}
              renderHeader={() => {
                return (
                  <>
                    <ConfirmLabel
                      small_label={
                        selected_campaign.campaign_type == "repeating_mail"
                          ? "Mail template selected:"
                          : "Mail sequence selected:"
                      }
                      icon="mail"
                      label={selected_campaign.campaign_title}
                      renderRight={() => {
                        return selected_campaign.selected_campaign == 1 ? (
                          <Row style={{ padding: 25 }}>
                            <Icon
                              icon="refresh"
                              size={14}
                              style={{ marginRight: 5 }}
                            />
                            <Copy style={{ fontSize: 12 }}>Repeating</Copy>
                          </Row>
                        ) : null;
                      }}
                    />
                  </>
                );
              }}
            />
          </>
        );

      default:
        break;
    }
  }
  toggleCreditReload(auto_reload) {
    this.setState({
      auto_reload,
    });
  }

  render() {
    const { start_mailer_campaign_page, right_panel } = this.props;
    if (start_mailer_campaign_page) {
      return (
        <Container
          style={{
            display: "flex",
            alignSelf: "stretch",
            backgroundColor: this.props.colors.card_color,
          }}
        >
          {this.renderBody()}
        </Container>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ auth, native, settings, mailer }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;
  const { start_mailer_campaign_page, show_start_mailer_campaign_page } =
    mailer;
  return {
    token,
    user,
    device,
    isMobile,
    platform,
    colors,
    start_mailer_campaign_page,
    show_start_mailer_campaign_page,
  };
};

export default connect(mapStateToProps, {
  setStartMailerCampaignPage,
  popSidePanel,
  updateLead,
})(StartMailerCampaignModal);
