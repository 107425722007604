import { TRIGGER_LOGOUT } from "app/DealMachineCore/types";
import api from "app/DealMachineCore/apis/DealMachineAPIV2";
const dm_api = api.create();

export const getContactsCount = ({
  token,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();

    onSuccess();
  };
};


export const getCompanies = ({
  token,
  company_name,
  company_address,
  company_city,
  company_state,
  company_zip,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getCompanies({
        token,
        company_name,
        company_address,
        company_city,
        company_state,
        company_zip
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getCompanyPeople = ({
  token,
  company_number,
  jurisdiction_code,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getCompanyPeople({
        token,
        company_number,
        jurisdiction_code
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};


export const getContacts = ({
  token,

  person_id,
  limit,
  begin = 0,
  sort_by,
  firstname = null,
  lastname = null,
  middlename = null,
  firstname2 = null,
  lastname2 = null,
  middlename2 = null,


  firstname3 = null,
  lastname3 = null,
  middlename3 = null,
  firstname4 = null,
  lastname4 = null,
  middlename4 = null,
  firstname5 = null,
  lastname5 = null,
  middlename5 = null,

  companies = [],
  company_people = [],


  address_zipcode = null,
  address_state = null,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getContacts({
        token,
        person_id,
        limit,
        begin,
        sort_by,
        firstname,
        lastname,
        middlename,
        firstname2,
        lastname2,
        middlename2,
        firstname3,
        lastname3,
        middlename3,
        firstname4,
        lastname4,
        middlename4,
        firstname5,
        lastname5,
        middlename5,

        address_zipcode,
        address_state
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const linkOrUnlinkPropertyToContact = ({
  token,
  type = "link_property_to_contact",
  person_id,
  property_id,
  matching_type,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .updateContacts({
        token,
        type,
        person_id,
        property_id,
        matching_type
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};
