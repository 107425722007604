import MobileDetect from "mobile-detect";
import React, { Component } from "react";
import {
  openUrl,
  AppConfig,
  isMobilePhoneCheck,
  DeepLink
} from "app/NativeActions";

class MobileRedirect extends Component {
  componentDidMount() {
    const path = this.props.match?.path;
    const id = this.props.match?.params?.id;

    if (isMobilePhoneCheck()) {
      switch (path) {
        case "/task/:id":
          openUrl("dealmachine://task/" + id, true);
          break;

        case "/property/:id":
          openUrl("dealmachine://property/" + id, true);
          break;

        case "/contact/:id":
          openUrl("dealmachine://contact/" + id, true);
          break;

        case "/edit-list/:id":
          openUrl("dealmachine://edit-list/" + id, true);
          break;
        case "/notifications":
          openUrl("dealmachine://notifications", true);
          break;
      }
    } else {
      switch (path) {
        case "/task/:id":
          openUrl(
            AppConfig().app_url + "/leads#tasks^o~edit-task^id-" + id + "!o",
            true
          );
          break;

        case "/property/:id":
          openUrl(
            AppConfig().app_url +
              "leads" +
              new DeepLink({
                object: "property",
                attribute: "id",
                value: id
              }).url_hash,
            true
          );
          break;

        case "/contact/:id":
          openUrl(
            AppConfig().app_url +
              "leads" +
              new DeepLink({
                object: "contact",
                attribute: "id",
                value: id
              }).url_hash,
            true
          );
          break;

        case "/edit-list/:id":
          openUrl(
            AppConfig().app_url +
              "leads" +
              new DeepLink({
                object: "edit_list",
                attribute: "id",
                value: id
              }).url_hash,
            true
          );
          break;
        case "/notifications":
          openUrl(
            AppConfig().app_url +
              new DeepLink({
                object: "notifications"
              }).url_hash,
            true
          );
          break;

        default:
          return;
      }
    }
  }
  render() {
    return null;
  }
}
export default MobileRedirect;
