import {
  LOGOUT,
  TOGGLE_MODAL,
  START_MODAL_FADE,
  SET_MODAL,
  UPDATE_MODAL,
  SET_PLAN_MODAL,
  TOGGLE_PLAN_MODAL,
  SUCCESS_MESSAGE,
  RESET_SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  RESET_ERROR_MESSAGE,
  IS_LOADING,
  SET_LOADING_MESSAGE,
  SET_NOTIFICATION,
  SHOW_NOTIFICATION,
  TOGGLE_REDIRECT,
  UNDO_ACTION,
  UNDO_ACTION_FAIL,
  UNDO_ACTION_SUCCESS,
  TOGGLE_DEAL_CREDIT_MODAL,
  SET_EDIT_MODAL,
  TOGGLE_EDIT_MODAL,
  SET_RIGHT_PANEL,
  CREATE_MAILER_CAMPAIGN_SUCCESS,
  REMOVE_MAILER_CAMPIGN_SUCCESS,
  SET_LEAD_MODAL,
  SET_RIGHT_PANEL_META,
  SET_UPSELL_MODAL,
  TOGGLE_UPSELL_MODAL,
  PUSH_SIDE_PANEL,
  REPLACE_SIDE_PANEL,
  UPDATE_SIDE_PANEL,
  POP_SIDE_PANEL,
  COMPLETE_POP_SIDE_PANEL,
  POP_ALL_SIDE_PANEL,
  COMPLETE_POP_ALL_SIDE_PANEL,
  CHANGE_TAB,
  BUILD_SIDE_PANELS,
  SET_ACTIVE_POPOVER,
  ADD_PROGRESS_BAR,
  UPDATE_PROGRESS_BAR,
  REMOVE_PROGRESS_BAR,
  COMPLETE_PROGRESS_BAR
} from "app/DealMachineCore/types";

const INITIAL_STATE = {
  show: false,
  type: "",
  startFade: false,
  title: "",
  description: "",
  icon: "",
  image: "",
  onPress: null,
  onCancel: null,
  submit: "",
  secondary: "",
  secondaryPress: "",
  buttonType: "",
  buttonText: "",
  buttonPress: null,
  cancel: "",
  submit_loading: false,
  require_complete_function: false,
  plan: {},
  toggle_plan: "monthly",
  success_message: "",
  success_title: "",
  error_message: "",
  error_title: "",
  loading_message: "",
  loading_title: "",
  is_loading: false,
  show_notification: false,
  notification: {
    title: "",
    subtitle: "",
    text: "",
    image: "",
    email: "",
    type: "",
    onPress: null
  },
  redirect: null,
  redirect_data: null,
  change_log: null,
  deal_credit_modal_show: false,
  active_popover: null,
  edit_modal: null,
  show_edit_modal: false,
  right_panel: null,
  lead_modal: null,
  toggle_upsell_modal: false,
  upsell_modal: { slug: null, type: "" },
  open_popover_id: { show: false, slug: "" },
  start_pop_side_panel: false,
  side_panel_views: [],
  start_pop_all_side_panel: false,
  popSidePanelSuccess: null,
  popAllSidePanelSuccess: null,
  pop_over_ids: ["assign", "lists", "tags", "delete", "status"],
  progress_bars: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };
    case SHOW_NOTIFICATION:
      return {
        ...state,
        show_notification: action.payload
      };
    case SET_ACTIVE_POPOVER:
      return {
        ...state,
        active_popover: action.payload
      };
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: {
          title: action.payload.title,
          subtitle: action.payload.subtitle,
          text: action.payload.text,
          image: action.payload.image,
          email: action.payload.email,
          type: action.payload.type,
          onPress: action.payload.onPress
        }
      };
    case TOGGLE_MODAL:
      return {
        ...state,
        startFade: false,
        show: action.payload.show,
        type: action.payload.type
      };
    case UPDATE_MODAL: {
      return {
        ...state,
        ...action.payload
      };
    }
    case SET_MODAL:
      return {
        ...state,
        title: action.payload.title,
        description: action.payload.description,
        icon: action.payload.icon,
        image: action.payload.image,
        submit: action.payload.submit,
        secondary: action.payload.secondary,
        secondaryPress: action.payload.secondaryPress,
        buttonType: action.payload.buttonType,
        buttonText: action.payload.buttonText,
        buttonPress: action.payload.buttonPress,
        cancel: action.payload.cancel,
        onPress: action.payload.onPress,
        onCancel: action.payload.onCancel,
        require_complete_function: action.payload.require_complete_function
      };
    case SET_PLAN_MODAL:
      return {
        ...state,
        plan: action.payload.plan
      };
    case TOGGLE_PLAN_MODAL:
      return {
        ...state,
        toggle_plan: action.payload
      };
    case START_MODAL_FADE:
      return {
        ...state,
        startFade: true
      };
    case SUCCESS_MESSAGE:
      return {
        ...state,
        success_message: action.payload.message,
        success_title: action.payload.title,
        error_message: "",
        error_title: "",
        is_loading: false,
        loading_message: "",
        loading_title: "",
        change_log: action.payload.change_log ? action.payload.change_log : null
      };
    case RESET_SUCCESS_MESSAGE:
      return {
        ...state,
        success_message: "",
        success_title: ""
      };
    case ERROR_MESSAGE:
      return {
        ...state,
        success_message: "",
        success_title: "",
        error_message: action.payload.message,
        error_title: action.payload.title,
        is_loading: false,
        loading_message: "",
        loading_title: ""
      };
    case RESET_ERROR_MESSAGE:
      return {
        ...state,
        error_message: ""
      };
    case IS_LOADING:
      return {
        ...state,
        success_message: "",
        success_title: "",
        error_message: "",
        error_title: "",
        is_loading: action.payload,
        loading_message: !action.payload ? "" : state.loading_message,
        loading_title: !action.payload ? "" : state.loading_title
      };
    case SET_LOADING_MESSAGE:
      return {
        ...state,
        loading_message: action.payload.message,
        loading_title: action.payload.title
      };
    case TOGGLE_REDIRECT:
      return {
        ...state,
        redirect: action.payload.type,
        redirect_data: action.payload.type ? action.payload.data : null
      };
    case UNDO_ACTION_SUCCESS:
      return {
        ...state,
        change_log: null
      };

    case TOGGLE_DEAL_CREDIT_MODAL:
      return {
        ...state,
        deal_credit_modal_show: action.payload
      };
    case SET_EDIT_MODAL:
      return {
        ...state,
        edit_modal: action.payload
      };
    case TOGGLE_EDIT_MODAL:
      return {
        ...state,
        show_edit_modal: action.payload
      };
    case SET_RIGHT_PANEL:
      return {
        ...state,
        right_panel: action.payload
      };
    case SET_RIGHT_PANEL_META:
      return {
        ...state,
        right_panel_meta: action.payload
      };
    case CREATE_MAILER_CAMPAIGN_SUCCESS:
      return {
        ...state,
        right_panel: "mailer_campaign"
      };
    case REMOVE_MAILER_CAMPIGN_SUCCESS:
      return {
        ...state,
        right_panel: null
      };
    case SET_LEAD_MODAL:
      return {
        ...state,
        lead_modal: action.payload
      };
    case TOGGLE_UPSELL_MODAL:
      return {
        ...state,
        toggle_upsell_modal: action.payload
      };
    case SET_UPSELL_MODAL:
      return {
        ...state,
        upsell_modal: action.payload
      };

    case PUSH_SIDE_PANEL:
      return {
        ...state,
        side_panel_views: [...state.side_panel_views, action.payload]
      };
    case REPLACE_SIDE_PANEL:
      return {
        ...state,
        side_panel_views:
          state.side_panel_views.length > 0
            ? state.side_panel_views.map((view, i) => {
                if (i == state.side_panel_views.length - 1) {
                  return action.payload;
                }
                return view;
              })
            : [action.payload]
      };
    case UPDATE_SIDE_PANEL:
      return {
        ...state,
        side_panel_views: state.side_panel_views.map((view, i) => {
          if (i == action.payload.index) {
            return {
              ...view,
              overlay:
                typeof action.payload.overlay == "boolean"
                  ? action.payload.overlay
                  : view.overlay,
              data: {
                ...view.data,
                ...action.payload.data
              }
            };
          }
          return view;
        })
      };
    case BUILD_SIDE_PANELS:
      return {
        ...state,
        side_panel_views: action.payload,
        start_pop_side_panel: false,
        start_pop_all_side_panel: false
      };

    case POP_SIDE_PANEL:
      return {
        ...state,
        start_pop_side_panel: true,
        popSidePanelSuccess: action.payload?.onSuccess
      };

    case COMPLETE_POP_SIDE_PANEL:
      let new_side_panel_views = JSON.parse(
        JSON.stringify(state.side_panel_views)
      );
      new_side_panel_views.splice(-1, 1);
      return {
        ...state,
        start_pop_side_panel: false,
        side_panel_views: new_side_panel_views,
        popSidePanelSuccess: null
      };
    case POP_ALL_SIDE_PANEL:
      return {
        ...state,
        start_pop_all_side_panel: true,
        popAllSidePanelSuccess: action.payload?.onSuccess
      };
    case COMPLETE_POP_ALL_SIDE_PANEL:
      return {
        ...state,
        start_pop_all_side_panel: false,
        popAllSidePanelSuccess: null,
        side_panel_views: []
      };
    case CHANGE_TAB:
      return {
        ...state,
        side_panel_views: []
      };

    case ADD_PROGRESS_BAR:
      return {
        ...state,
        progress_bars: [...state.progress_bars, action.payload]
      };

    case UPDATE_PROGRESS_BAR:
      return {
        ...state,
        progress_bars: state.progress_bars.map((bar, i) => {
          if (bar.id == action.payload.id) {
            return {
              ...bar,
              progress:
                parseInt(bar.progress) + parseInt(action.payload.progress)
            };
          }
          return bar;
        })
      };

    case COMPLETE_PROGRESS_BAR:
      return {
        ...state,
        progress_bars: state.progress_bars.map((bar, i) => {
          if (bar.id == action.payload) {
            return {
              ...bar,
              completed: true,
              data: {
                ...bar?.data,
                list: {
                  ...bar?.data?.list,
                  building: 0
                }
              }
            };
          }
          return bar;
        })
      };

    case REMOVE_PROGRESS_BAR:
      return {
        ...state,
        progress_bars: state.progress_bars.filter(bar => {
          return bar.id != action.payload;
        })
      };

    default:
      return state;
  }
};
