import React, { Component } from "react";
import { connect } from "react-redux";

import {
  setActiveDialerContact,
  updateActiveCall,
  replaceSidePanel,
  phoneCall,
  resetActiveCall,
  updateCallSession,
  updateCalledNumbers,
  getCallSession,
  showErrorMessage,
  getDialerStats,
  playAudio,
  killAudio,
  toTitleCase
} from "app/NativeActions";

import DialerComponents from "app/NativeComponents/components/NativeDialer/DialerComponents";
import moment from "moment/moment";

class DialerInner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      call_status: null,
      call_time: null,
      call_id: !!props?.call_id ? props.call_id : null,
      mic_permissions: null,
      countdown: props.user?.user_settings?.dialer_settings?.practice_mode
        ? 5
        : 3,
      call_canceled: false,
      bypass_countdown: false,
      start_call_loading: false,
      start_call_loading_id: null,
      mute: false
    };

    this._call = null;
    this._localstream = null;
    this._audio = null;

    this.handleDialer = this.handleDialer.bind(this);
    this.handleHangup = this.handleHangup.bind(this);
    this.acceptIncomingCall = this.acceptIncomingCall.bind(this);

    this.endCall = this.endCall.bind(this);
    this.startCall = this.startCall.bind(this);

    this.setCallTime = this.setCallTime.bind(this);
    this.stopTimer = this.stopTimer.bind(this);

    this.closeSession = this.closeSession.bind(this);
    this.completeSession = this.completeSession.bind(this);

    this.createCallEvents = this.createCallEvents.bind(this);
    this.updateCountdown = this.updateCountdown.bind(this);
    this.clearCountdown = this.clearCountdown.bind(this);
    this.bypassCountdown = this.bypassCountdown.bind(this);

    this.handleNumberChange = this.handleNumberChange.bind(this);

    this.muteCall = this.muteCall.bind(this);
  }

  getLoadingId() {
    //cerate a random id and set it to the state loading_id
    return Math.floor(Math.random() * 100000000000000000);
  }

  stopTimer() {
    this.setState(
      { call_start_time: null, isTimerActive: false, call_time: null },
      () => {
        clearTimeout(this._call_time_interval);
      }
    );
  }

  setCallTime() {
    if (this.state.call_start_time && this.state.isTimerActive) {
      let current_time = moment().format("YYYY-MM-DD HH:mm:ss");

      let ms = moment(current_time, "YYYY-MM-DD HH:mm:ss").diff(
        moment(this.state.call_start_time, "YYYY-MM-DD HH:mm:ss")
      );

      var d = moment.duration(ms);
      var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

      this.setState(
        {
          call_time: s
        },
        () => {
          clearTimeout(this._call_time_interval);
          this._call_time_interval = setTimeout(() => {
            this.setCallTime();
          }, 1000);
        }
      );
    }
  }

  updateCountdown() {
    this.setState(
      {
        countdown: this.state.countdown - 1 > 0 ? this.state.countdown - 1 : 0
      },
      () => {
        clearTimeout(this._countdown_interval);

        if (this.state.countdown > 0) {
          this._countdown_interval = setTimeout(() => {
            this.updateCountdown();
          }, 1000);
        } else if (
          this.props.active_call?.call_id &&
          this.props.active_call?.call_status === "loading" &&
          !this.state.call_canceled
        ) {
          this.handleDialer();
        }
      }
    );
  }

  clearCountdown(dont_cancel = false) {
    clearTimeout(this._countdown_interval);
    this.setState({
      countdown: 0,
      call_canceled: !dont_cancel,
      bypass_countdown: false
    });

    if (!dont_cancel && this.props.active_call?.call_id) {
      this.props.phoneCall({
        token: this.props.token,
        type: "cancel_call",
        call_id: this.props.active_call?.call_id
      });
    }
  }

  bypassCountdown() {
    this.setState(
      {
        countdown: 0,
        call_canceled: false,
        bypass_countdown: true
      },
      () => {
        if (
          this.props.active_call?.call_id &&
          this.props.active_call?.call_status === "loading" &&
          !this.state.call_canceled
        ) {
          this.handleDialer();
        }
      }
    );
  }

  componentWillUnmount() {
    clearTimeout(this._call_time_interval);
    clearTimeout(this._countdown_interval);
    clearTimeout(this._animation_timeout);

    killAudio({ audio: this._audio });
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mic_permissions === "granted") {
      if (
        this.props.active_call.call_status !==
          prevProps.active_call.call_status &&
        this.props.active_call.call_status === "calling" &&
        !this.state.call_start_time &&
        !this.props.active_dialer_contact?.answered_on_another_device
      ) {
        this.setState(
          {
            call_start_time: moment().format("YYYY-MM-DD HH:mm:ss"),
            isTimerActive: true
          },
          () => {
            this.setCallTime();
          }
        );
      }

      if (
        ((this.props.active_call.call_status !==
          prevProps.active_call.call_status &&
          this.props.active_call.call_status === "call_ended") ||
          (this.props.active_call.answered_by !==
            prevProps.active_call.answered_by &&
            this.props.active_call.answered_by === "machine")) &&
        !this.state.show_more
      ) {
        this.setState({
          call_start_time: null
        });
      }

      if (
        !!this.props.active_dialer_contact?.selected_phone?.number &&
        this.props.active_dialer_contact?.selected_phone?.number !==
          prevProps.active_dialer_contact?.selected_phone?.number &&
        !this.props.active_dialer_contact?.incoming_call &&
        !this.props.active_dialer_contact?.answered_on_another_device
      ) {
        this.handleHangup(true);
        this.handleNumberChange({
          new_number: true,
          old_contact: prevProps.active_dialer_contact
        });
      } else if (
        !!this.props.active_dialer_contact?.selected_phone?.number &&
        this.props.active_dialer_contact?.selected_phone?.number !==
          prevProps.active_dialer_contact?.selected_phone?.number &&
        this.props.active_dialer_contact?.incoming_call &&
        this.state.call_canceled
      ) {
        //clear a canceled call
        this.setState({
          call_canceled: false
        });
      }
    }
  }

  handleNumberChange({
    first_number = false,
    new_number = false,
    old_contact = null,
    mic_permissions = null
  }) {
    if (!!mic_permissions) {
      this.setState({
        mic_permissions
      });
    }

    if (new_number) {
      clearTimeout(this._animation_timeout);
      this.setState(
        {
          newNumberAnimation: "exit"
        },
        () => {
          this._animation_timeout = setTimeout(() => {
            this.setState(
              {
                newNumberAnimation: "enter"
              },
              () => {
                this._animation_timeout = setTimeout(() => {
                  this.setState({
                    newNumberAnimation: null
                  });
                }, 250);
              }
            );
          }, 250);
        }
      );
    }

    //check if an item in this.props.called_numbers contains the number of the current contact
    let found_number = false;
    this.props.called_numbers.map((number, i) => {
      if (number.number == old_contact?.selected_phone?.number) {
        found_number = true;
      }
    });

    if (this.props.current_call_session && !first_number) {
      if (
        (!this.props.active_call?.call_status ||
          this.props.active_call?.call_status === "loading" ||
          this.props.active_call?.call_status === "calling" ||
          this.props.active_call?.call_status === "connected") &&
        !found_number
      ) {
        this.props.updateCallSession({
          token: this.props.token,
          type: "skip_call_session_item",
          call_id: this.props.active_call?.call_id,
          call_session_id: this.props.current_call_session?.id,
          to_phone_number: old_contact?.selected_phone?.number,
          skipped_contact_id: this.props.active_dialer_contact?.individual_key,
          skipped_property_id:
            this.props.active_dialer_contact?.associated_lead?.id
        });
      }
      if (!found_number) {
        this.props.updateCalledNumbers([
          ...this.props.called_numbers,
          {
            number: old_contact?.selected_phone?.number
          }
        ]);
      }
    }

    if (
      !this.props.active_dialer_contact?.incoming_call &&
      !this.props.active_dialer_contact?.answered_on_another_device
    ) {
      clearTimeout(this._countdown_interval);
      this.setState(
        {
          countdown: this.props.user?.user_settings?.dialer_settings
            ?.practice_mode
            ? 5
            : 3,
          call_canceled: false,
          start_call_loading: true
        },
        () => {
          this.startCall();
          clearTimeout(this._countdown_interval);
          this._countdown_interval = setTimeout(() => {
            this.updateCountdown();
          }, 1000);
        }
      );
    }
  }

  startCall() {
    this.props.refreshTwilioToken({
      onSuccess: () => {
        const call_loading_id = this.getLoadingId();
        this.setState(
          {
            start_call_loading_id: call_loading_id
          },
          () => {
            this.props.phoneCall({
              token: this.props.token,
              type: "start_outgoing_call",
              to_phone_number:
                this.props.active_dialer_contact?.selected_phone?.number,
              phone_owner:
                this.props.active_dialer_contact?.selected_phone?.owner,
              phone_type:
                this.props.active_dialer_contact?.selected_phone?.type,
              do_not_call_flag:
                this.props.active_dialer_contact?.selected_phone
                  ?.do_not_call_flag,
              contact_id: this.props.active_dialer_contact?.individual_key,
              property_id:
                this.props.active_dialer_contact?.associated_lead?.id,
              call_session_id: this.props.current_call_session
                ? this.props.current_call_session?.id
                : null,
              practice_mode:
                this.props.user?.user_settings?.dialer_settings?.practice_mode,
              onLoading: () => {
                this.setState(
                  {
                    start_call_loading: true,
                    call_canceled: false
                  },
                  () => {
                    this.props.updateActiveCall({
                      ...this.props.active_call,
                      call_id: null,
                      dropped_voicemail: false,
                      stop_mail: false,
                      from_phone_number: null,
                      answered_by: null,
                      call_status: "loading"
                    });
                  }
                );
              },
              onError: error => {
                //handle error
                if (call_loading_id == this.state.start_call_loading_id) {
                  clearTimeout(this._countdown_interval);
                  this.setState(
                    {
                      start_call_loading: false,
                      countdown: 0,
                      call_canceled: true
                    },
                    () => {
                      this.props.updateActiveCall({
                        ...this.props.active_call,
                        dropped_voicemail: false,
                        call_status: null,
                        stop_mail: false
                      });
                    }
                  );

                  this.stopTimer();
                  this.props.showErrorMessage(error, "Error");
                }
              },
              onSuccess: results => {
                if (call_loading_id == this.state.start_call_loading_id) {
                  this.props.updateActiveCall({
                    ...this.props.active_call,
                    answered_by: null,
                    dropped_voicemail: false,
                    stop_mail: false,
                    from_phone_number: results.from_phone_number,
                    call_id: results.call_id
                  });

                  this.setState(
                    {
                      start_call_loading: false
                    },
                    () => {
                      if (
                        this.state.countdown === 0 &&
                        !this.state.call_canceled
                      ) {
                        this.handleDialer();
                      } else if (this.state.call_canceled) {
                        this.props.phoneCall({
                          token: this.props.token,
                          type: "cancel_call",
                          call_id: results.call_id
                        });
                      }

                      if (
                        this.props.current_call_session &&
                        !this.props.current_call_session?.completed
                      ) {
                        this.props.getCallSession({
                          token: this.props.token,
                          type: "get_current_call_session",
                          onLoading: () => {},
                          onError: () => {},
                          onSuccess: results => {}
                        });
                      }

                      if (this.props.active_dialer_contact?.follow_up_queue) {
                        this.props.getDialerStats({
                          token: this.props.token,
                          filter_user: this.props.user?.id,
                          type: "my_follow_up_queue_count"
                        });
                      }
                    }
                  );
                }
              }
            });
          }
        );
      }
    });
  }

  acceptIncomingCall() {
    if (this._call) {
      if (this.props.active_call?.call_id) {
        this.props.phoneCall({
          token: this.props.token,
          type: "end_call",
          call_id: this.props.active_call?.call_id
        });
      }
      this.stopTimer();

      try {
        this._call.disconnect();
      } catch (error) {
        console.error("Error in ending a call:", error);
      }
    }

    this.props.setActiveDialerContact({
      contact: {
        ...this.props.incoming_call_info?.contact,
        selected_phone: {
          number: this.props.incoming_call_info?.to_phone_number
        },
        associated_lead: this.props.incoming_call_info?.property,
        one_off_call: true,
        incoming_call: true
      },
      active_call: {
        dropped_voicemail: false,
        stop_mail: false,
        from_phone_number: null,
        answered_by: "",
        note: "",
        call_id: this.props.incoming_call_info?.call_id,
        call_status: "answered"
      }
    });

    if (this.props.incoming_call) {
      this.props.incoming_call.accept();

      this.props.phoneCall({
        token: this.props.token,
        type: "answered_phone_call",
        call_id: this.props.incoming_call_info?.call_id
      });
    }

    this.setState({
      call_id: this.props.incoming_call_info?.call_id
    });

    this.props.updateActiveCall({
      ...this.props.active_call,
      answered_by: null,
      call_results: null,
      dropped_voicemail: false,
      call_id: this.props.incoming_call_info?.call_id,
      call_status: "answered"
    });

    //start timeer
    this.setState(
      {
        call_start_time: moment().format("YYYY-MM-DD HH:mm:ss"),
        isTimerActive: true
      },
      () => {
        this.setCallTime();
      }
    );

    this.createCallEvents(this.props.incoming_call, "incoming_call");
  }

  endCall(next_call = false) {
    if (this.props.active_call?.call_id) {
      this.props.phoneCall({
        token: this.props.token,
        type: "end_call",
        call_id: this.props.active_call?.call_id
      });
    }

    this.stopTimer();

    if (next_call) {
      this.props.resetActiveCall();
    } else {
      this.props.updateActiveCall({
        ...this.props.active_call,
        call_status: "call_ended"
      });
    }
  }

  muteCall(mute, call = null) {
    this.setState(
      {
        mute: mute
      },
      () => {
        if (this.props.device === "desktop") {
          if (call) {
            call.mute(this.state.mute);
          } else {
            if (
              this.props.incoming_call &&
              this.props.active_dialer_contact?.incoming_call
            ) {
              this.props.incoming_call.mute(this.state.mute);
            } else if (this._call) {
              this._call?.mute(this.state.mute);
            }
          }
        }
      }
    );
  }

  createCallEvents(call, call_type = "outgoing_call") {
    if (this.props.device === "desktop") {
      // Add Event Listeners if needed
      call.on("ringing", hasEarlyMedia => {
        if (call_type !== "incoming_call") {
          this.props.phoneCall({
            token: this.props.token,
            type: "save_provider_id",
            call_id: this.props.active_call.call_id,
            provider_id: this._call?.parameters?.CallSid,
            provider: "twilio"
          });

          this.props.updateActiveCall({
            ...this.props.active_call,
            call_status: "calling",
            provider_id: this._call?.parameters?.CallSid,
            provider: "twilio"
          });

          // Get the local stream
          this._localstream = this._call.getLocalStream();
        }

        this.muteCall(this.state.mute, call);

        // Get the remote stream
      });

      call.on("connect", conn => {
        console.log(conn);

        this.muteCall(this.state.mute, call);
      });

      call.on("disconnect", () => {
        console.log("The call has been disconnected.");

        this._localstream = null;
        this.endCall();
      });
      call.on("cancel", data => {
        console.log("The call has been canceled.");
        this._localstream = null;
        this.endCall();
      });

      call.on("error", data => {
        console.log("The call has an error.");
        this._localstream = null;
        this.props.showErrorMessage(
          "There was an error with our phone provider. Please try again later",
          "Error"
        );

        this.endCall();
      });

      call.on("reject", data => {
        console.log("The call has been rejected.");

        this.endCall();
      });

      call.on("accept", d => {
        if (call_type !== "incoming_call") {
          if (this.props.call_status?.call_status !== "answered") {
            this.props.updateActiveCall({
              ...this.props.active_call,
              call_status: "connected"
            });
          }
        } else {
          // Get the local stream
          this._localstream = call.getLocalStream();
        }
      });

      call.on("warning", function (warningName, warningData) {
        if (warningName === "low-mos") {
          console.log(warningData);
        }
      });

      call.on("warning-cleared", function (warningName) {
        if (warningName === "low-mos") {
        }
      });

      call.on("mute", (isMuted, call) => {
        // isMuted is true if the input audio is currently muted
        // i.e. The remote participant CANNOT hear local device's input

        // isMuted is false if the input audio is currently unmuted
        // i.e. The remote participant CAN hear local device's input
        //isMuted ? console.log("muted") : console.log("unmuted");

        if (isMuted !== this.state.mute) {
          this.muteCall(this.state.mute, call);
        }
      });

      call.on("reconnected", () => {
        console.log("The call has regained connectivity.");
      });
    } else if (this.props.device === "mobile") {
      call.on("ringing", e => {
        playAudio({
          mobile_file: "outgoing.mp3",
          onPrepared: audio => {
            this._audio = audio;
          }
        });
        const call_sid = call.getSid();
        if (call_type !== "incoming_call") {
          this.props.phoneCall({
            token: this.props.token,
            type: "save_provider_id",
            call_id: this.props.active_call.call_id,
            provider_id: call_sid,
            provider: "twilio"
          });

          this.props.updateActiveCall({
            ...this.props.active_call,
            call_status: "calling",
            provider_id: call_sid,
            provider: "twilio"
          });
        }

        this.muteCall(this.state.mute, call);
      });

      call.on("connected", conn => {
        console.log("The call has been connected.");

        this.muteCall(this.state.mute, call);
      });

      call.on("disconnected", () => {
        console.log("The call has been disconnected.");

        playAudio({
          mobile_file: "disconnect.mp3",
          onPrepared: audio => {
            this._audio = audio;
          }
        });

        this.endCall();
      });
      call.on("cancelled", data => {
        console.log("The call has been canceled.");

        this.endCall();
      });

      call.on("connectFailure", () => {
        console.log("The call has failed.");

        this.endCall();
      });

      call.on("rejected", () => {
        console.log("The call has been rejected.");

        this.endCall();
      });

      call.on("accepted", d => {
        console.log("The call has been accepted.");

        if (call_type !== "incoming_call") {
          if (this.props.call_status?.call_status !== "answered") {
            this.props.updateActiveCall({
              ...this.props.active_call,
              call_status: "connected"
            });
          }
        }
      });

      call.on("qualityWarningsChanged", function (warningName, warningData) {
        if (warningName === "low-mos") {
          console.log(warningData);
        }
      });

      call.on("reconnected", () => {
        console.log("The call has regained connectivity.");
      });
    }
  }

  async handleDialer() {
    if (this.props.twilio_device) {
      if (this.props.device === "desktop") {
        try {
          this._call = await this.props.twilio_device.connect({
            params: {
              token: this.props.token,
              call_id: this.props.active_call.call_id,
              to_phone_number:
                this.props.active_dialer_contact?.selected_phone?.number,
              from_phone_number: this.props.active_call.from_phone_number,
              contact_id: this.props.active_dialer_contact?.individual_key,
              property_id: this.props.active_dialer_contact?.associated_lead?.id
            }
          });

          this.createCallEvents(this._call, "outgoing_call");
        } catch (error) {
          console.log(error);
          console.error("Error in making a call:", error);
        }
      } else {
        try {
          this._call = await this.props.twilio_device.connect(
            this.props.twilio_token,
            {
              params: {
                token: this.props.token,
                call_id: this.props.active_call.call_id,
                To:
                  this.props.active_dialer_contact?.selected_phone?.number.substring(
                    0,
                    2
                  ) == "+1"
                    ? this.props.active_dialer_contact?.selected_phone?.number
                    : "+1" +
                      this.props.active_dialer_contact?.selected_phone?.number,
                From: this.props.active_call.from_phone_number,
                to_phone_number:
                  this.props.active_dialer_contact?.selected_phone?.number,
                from_phone_number: this.props.active_call.from_phone_number
              },
              contactHandle:
                toTitleCase(this.props.active_dialer_contact?.given_name) +
                " " +
                toTitleCase(this.props.active_dialer_contact?.surname)
            }
          );

          this.createCallEvents(this._call, "outgoing_call");
        } catch (error) {
          console.error("Error in making a call:", error);
        }
      }
    }
  }

  handleHangup(next_call = false) {
    if (this.props.incoming_call) {
      try {
        this.props.incoming_call.disconnect();
      } catch (error) {
        console.error("Error in ending a call:", error);
      }
      this.props.clearIncomingCall();
    }

    if (this._call) {
      this.endCall(next_call);

      try {
        this._call.disconnect();
      } catch (error) {
        console.error("Error in ending a call:", error);
      }
    }
  }

  closeSession() {
    if (
      this.props.current_call_session?.id &&
      !this.props.active_dialer_contact?.one_off_call &&
      !this.props.active_dialer_contact?.incoming_call
    ) {
      this.props.updateCallSession({
        token: this.props.token,
        type: "check_and_end_call_session",
        call_session_id: this.props.current_call_session?.id,
        onLoading: () => {},
        onError: () => {},
        onSuccess: results => {
          if (results.call_session?.completed) {
            this.props.replaceSidePanel({
              slug: "call_session",
              overlay: true,
              data: {
                call_session: results.call_session
              }
            });
          }
        }
      });

      this.props.setActiveDialerContact({
        contact: null,
        queued_numbers: []
      });
    } else {
      this.props.setActiveDialerContact({
        contact: null,
        queued_numbers: []
      });
    }

    this.handleHangup();
  }

  completeSession(end_early = false) {
    if (
      this.props.current_call_session &&
      !this.props.active_dialer_contact?.one_off_call
    ) {
      this.props.updateCallSession({
        token: this.props.token,
        type: end_early ? "end_current_call_session" : "complete_call_session",
        call_session_id: this.props.current_call_session?.id,
        onLoading: () => {
          this.setState({
            complete_session_loading: true
          });
        },
        onError: () => {
          this.setState({
            complete_session_loading: false
          });
        },
        onSuccess: results => {
          this.setState(
            {
              complete_session_loading: false
            },
            () => {
              if (results.call_session?.completed) {
                this.props.replaceSidePanel({
                  slug: "call_session",
                  overlay: true,
                  data: {
                    call_session: results.call_session
                  }
                });
              }
            }
          );
          this.props.setActiveDialerContact({
            contact: null,
            queued_numbers: []
          });
          this.handleHangup();
        }
      });
    } else {
      this.handleHangup();
    }
  }

  render() {
    const { colors } = this.props;
    const { call_status } = this.props.active_call;

    return (
      <DialerComponents
        twilio_device={this.props.twilio_device}
        incoming_call_info={this.props.incoming_call_info}
        incoming_call={this.props.incoming_call}
        clearIncomingCall={this.props.clearIncomingCall}
        rejectIncomingCall={this.props.rejectIncomingCall}
        acceptIncomingCall={this.acceptIncomingCall}
        handleHangup={this.handleHangup}
        start_call_loading={this.state.start_call_loading}
        call_time={this.state.call_time}
        startCall={this.startCall}
        call_start_time={this.state.call_start_time}
        isTimerActive={this.state.isTimerActive}
        countdown={this.state.countdown}
        clearCountdown={this.clearCountdown}
        bypassCountdown={this.bypassCountdown}
        call_canceled={this.state.call_canceled}
        newNumberAnimation={this.state.newNumberAnimation}
        closeSession={this.closeSession}
        completeSession={this.completeSession}
        complete_session_loading={this.state.complete_session_loading}
        handleNumberChange={this.handleNumberChange}
        hidePurchaseUpsell={this.props.hidePurchaseUpsell}
        hide_purchase_upsell={this.props.hide_purchase_upsell}
        call={this._call}
        localstream={this._localstream}
        mute={this.state.mute}
        muteCall={this.muteCall}
      />
    );
  }
}

const mapStateToProps = ({ auth, native, settings, dialer, billing }) => {
  const { token, user } = auth;

  const { dark_mode, colors } = settings;
  const { device } = native;
  const { source_of_truth } = billing;
  const {
    active_dialer_contact,
    active_call,
    current_conversation,
    current_call_session,
    queued_numbers,
    called_numbers
  } = dialer;
  return {
    token,
    user,
    dark_mode,
    colors,
    device,
    active_dialer_contact,
    active_call,
    current_conversation,
    current_call_session,
    queued_numbers,
    called_numbers,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  setActiveDialerContact,
  updateActiveCall,
  replaceSidePanel,
  phoneCall,
  resetActiveCall,
  updateCallSession,
  updateCalledNumbers,
  getCallSession,
  showErrorMessage,
  getDialerStats
})(DialerInner);
