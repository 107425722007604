import React, { Component } from "react";

import { Row } from "app/NativeComponents/common";
import {
  CircleButton,
  SelectItem,
  IconButton
} from "app/NativeComponents/snippets";
import { copyToClipBoard, openEmail } from "app/NativeActions";
import { store } from "app/store";

class EmailItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copy_success: false
    };
  }

  componentWillUnmount() {
    clearInterval(this.copy_success_timeout);
  }

  render() {
    const { email } = this.props;
    const { copy_success } = this.state;
    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;

    return (
      <SelectItem
        select_type={"none"}
        noPress={true}
        label={""}
        renderRight={() => {
          return (
            <Row style={device === "desktop" ? { paddingRight: 15 } : {}}>
              <IconButton
                icon={copy_success ? "check" : "content-copy"}
                backgroundColor={copy_success ? colors.success_color : null}
                icon_color={copy_success ? colors.white_text_color : null}
                tooltip={
                  copy_success
                    ? "Copied: " + email.email.toLowerCase()
                    : "Copy: " + email.email.toLowerCase()
                }
                tooltipPlacement="top"
                onPress={() => {
                  copyToClipBoard(email.email.toLowerCase());
                  clearInterval(this.copy_success_timeout);
                  let $this = this;
                  this.copy_success_timeout = $this.setState(
                    {
                      copy_success: true
                    },
                    () => {
                      setTimeout(() => {
                        $this.setState({
                          copy_success: false
                        });
                      }, 3000);
                    }
                  );
                }}
              />
              <IconButton
                tooltipPlacement="top"
                tooltip={"Email: " + email.email.toLowerCase()}
                onPress={() => {
                  openEmail({ email: email.email.toLowerCase() });
                }}
                icon={"alternate-email"}
              />
            </Row>
          );
        }}
      >
        {email.email.toLowerCase()}
      </SelectItem>
    );
  }
}

export default EmailItem;
