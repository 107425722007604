import React, { Component } from "react";
import { connect } from "react-redux";
import {
  formatUsPhone,
  popSidePanel,
  updateCallTrackingNumbers,
  showErrorMessage
} from "app/NativeActions";
import {
  Container,
  Copy,
  KeyboardView,
  Wrapper
} from "app/NativeComponents/common";
import {
  InputBox,
  NewHeader,
  GhostButton,
  AttentionBox,
  SelectItem,
  SmallLabel
} from "app/NativeComponents/snippets";

class AddCallTrackingNumber extends Component {
  constructor(props) {
    super(props);

    const default_callback = !!props.user?.team_phone
      ? formatUsPhone(props.user?.team_phone)
      : "";

    //get the area code from the phone number
    let area_code = "";
    if (default_callback) {
      area_code = default_callback.substring(1, 4);
    }

    this.state = {
      loading: false,
      areacode: area_code,
      phone_label: "",
      callback_type: "normal",
      default_tracking_number: false,
      callback_number: props.user?.team_phone ? props.user?.team_phone : ""
    };

    this._area_code_input = React.createRef();
    this._phone_label_input = React.createRef();
    this._callback_number_input = React.createRef();

    this.addPhoneNumber = this.addPhoneNumber.bind(this);
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  checkIfNeedsToSave() {
    if (
      !!this.state.areacode &&
      this.state.areacode.length === 3 &&
      !!this.state.callback_number
    ) {
      return true;
    }

    return false;
  }

  addPhoneNumber() {
    if (
      !!this.state.areacode &&
      this.state.areacode.length === 3 &&
      !!this.state.callback_number
    ) {
      this.props.updateCallTrackingNumbers({
        token: this.props.token,
        type: "create_call_tracking_number",
        phone_label: this.state.phone_label,
        areacode: this.state.areacode,
        callback_number: this.state.callback_number,
        callback_type: this.state.callback_type,
        default_tracking_number: this.state.default_tracking_number,
        onLoading: () => {
          this.setState({ loading: true });
        },
        onError: error => {
          this.setState({ loading: false });

          this.props.showErrorMessage(error, "Error");
        },
        onSuccess: results => {
          this.setState({ loading: false }, () => {
            const panel_data =
              this.props.device == "mobile"
                ? this.props?.route?.params?.panel_data
                : this.props.panel_data;

            if (panel_data?.handleNewNumber) {
              panel_data.handleNewNumber(results.call_tracking_number);
            }

            this.props.popSidePanel();
          });
        }
      });
    }
  }

  render() {
    const { colors } = this.props;
    const { loading } = this.state;

    return (
      <Container>
        <NewHeader
          title={"Get Call Tracking Number"}
          leftButton={{
            icon: "arrow-back",
            onPress: this.props.popSidePanel
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <AttentionBox
            title={"Track ROI with call tracking numbers"}
            description={
              "Get a unique phone number to track calls from your DealMachine marketing. Once you have a call tracking number, you can assign it to a mail design."
            }
          />

          <InputBox
            input_ref={this._phone_label_input}
            name="phone_label"
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="none"
            keyboardType="normal"
            disabled={loading}
            placeholder="Label"
            onChange={value => this.setState({ phone_label: value })}
            value={this.state.phone_label}
            type="text"
            onSubmitEditing={() => {
              if (this._area_code_input?.current) {
                this._area_code_input.current.focus();
              }
            }}
          />

          <SmallLabel
            description={
              "Enter the area code you would like your call tracking number to have. If there are none available we'll get you the next closest option by latitude and longitude."
            }
          >
            Request an Area Code
          </SmallLabel>
          <InputBox
            input_ref={this._area_code_input}
            name="areacode"
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="none"
            keyboardType="normal"
            disabled={loading}
            placeholder="Desired Area Code"
            onChange={value =>
              this.setState({ areacode: value.replace(/[^0-9]/g, "") })
            }
            value={this.state.areacode}
            type="number"
            maxLength={3}
            onSubmitEditing={() => {
              if (this._callback_number_input?.current) {
                this._callback_number_input.current.focus();
              }
            }}
          />

          <InputBox
            input_ref={this._callback_number_input}
            name="callback_number"
            disabled={loading}
            returnKeyType="next"
            placeholder="Callback Number"
            onChange={value => this.setState({ callback_number: value })}
            onSubmitEditing={() => {
              if (!this.checkIfNeedsToSave()) {
                this.addPhoneNumber();
              }
            }}
            blurOnSubmit={false}
            value={this.state.callback_number}
            type="text"
            mask_type={"cel-phone"}
            mask={"(999) 999-9999"}
          />

          <SelectItem
            selected={this.state.default_tracking_number}
            onPress={() => {
              this.setState({
                default_tracking_number: !this.state.default_tracking_number
              });
            }}
            select_type="toggle"
            description={
              "Make this my default tracking number. This will be the number used for all new mail designs."
            }
          >
            Default Tracking Number
          </SelectItem>

          <Wrapper
            style={{
              padding: 25
            }}
          >
            <Copy>
              Each additional call tracking number over your plans limit will
              add an additional $5 to your subscription.
            </Copy>
          </Wrapper>

          <GhostButton
            primary={true}
            button_type={"full"}
            loading={loading}
            disabled={!this.checkIfNeedsToSave()}
            onPress={this.addPhoneNumber}
          >
            Get Call Tracking Number
          </GhostButton>
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;

  return {
    token,
    user,
    isMobile,
    colors,
    device
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateCallTrackingNumbers,
  showErrorMessage
})(AddCallTrackingNumber);
