import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Bold,
  Copy,
  Icon,
  ProfilePic,
  Title,
  Row,
  Spin,
  Wrapper
} from "app/NativeComponents/common";
import {
  formatUsPhone,
  replaceSidePanel,
  setRejectedCall,
  getQueuedNumbers,
  getContactCallHistory,
  setActiveDialerContact
} from "app/NativeActions";
import {
  IconButton,
  InlineButton,
  PopoverMenu
} from "app/NativeComponents/snippets";
import moment from "moment";

class MainButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_input_device: props.selected_input_device,
      countdown_button_hover: false
    };
  }

  renderSelectAudioDeviceMenu() {
    const { colors } = this.props;

    if (this.props.audio_input_devices && this.props.selected_input_device) {
      let menu_items = [];

      for (let i = 0; i < this.props.audio_input_devices.length; i++) {
        menu_items.push({
          title: this.props.audio_input_devices[i].name,
          select_type: "icon",
          icon:
            this.props.audio_input_devices[i].type === "speaker"
              ? "volume-up"
              : this.props.audio_input_devices[i].type === "earpiece"
              ? "smartphone"
              : "headset-mic",
          selected:
            this.props.audio_input_devices[i].uuid ==
            this.state.selected_input_device?.uuid,
          description: "",
          onPress: () => {
            this.setState(
              {
                selected_input_device: this.props.audio_input_devices[i]
              },
              () => {
                this.props.selectNewAudioInputDevice(
                  this.props.audio_input_devices[i]
                );
              }
            );
          }
        });
      }

      return (
        <PopoverMenu
          show={this.state.show_audio_select_menu}
          no_swipe={false}
          no_cancel={false}
          onShow={s => {
            this.setState(
              {
                show_audio_select_menu: s
              },
              () => {
                this.props.getAudioDevices();
              }
            );
          }}
          popoverPlacement={"bottom"}
          popover_title={"Select Audio Device"}
          hideWithOutsideClick={true}
          includeCloseButton={true}
          renderComponent={options => {
            return (
              <IconButton
                noPress={true}
                pressedIn={options.hovering || options.pressed_in}
                tooltip={"Select Audio Device"}
                tooltipPlacement={"top"}
                icon={
                  this.state.selected_input_device?.type === "bluetooth"
                    ? "headset-mic"
                    : "volume-up"
                }
                icon_color={
                  this.state.selected_input_device?.type === "bluetooth" ||
                  this.state.selected_input_device?.type === "speaker"
                    ? colors.dark_phone_color
                    : colors.white_text_color
                }
                hover_color={colors.hover_white_color}
                backgroundColor={
                  this.state.selected_input_device?.type === "bluetooth" ||
                  this.state.selected_input_device?.type === "speaker"
                    ? colors.white_color
                    : null
                }
              />
            );
          }}
          menu_items={menu_items}
        />
      );
    }
  }

  componentDidMount() {
    if (
      this.props.active_dialer_contact?.one_off_call &&
      !this.props.active_dialer_contact?.incoming_call
    ) {
      this.props.getQueuedNumbers({
        token: this.props.token,
        property_id: this.props.active_dialer_contact?.associated_lead?.id,
        contact_id: this.props.active_dialer_contact?.individual_key,
        type: this.props.active_dialer_contact?.associated_lead
          ? "queued_numbers_for_property"
          : "queued_numbers_for_contact",
        include_numbers:
          this.props.active_dialer_contact?.selected_phone?.number,
        onLoading: () => {},
        onError: () => {},
        onSuccess: results => {}
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.selected_input_device &&
      !prevProps.selected_input_device &&
      this.props.selected_input_device !== prevProps.selected_input_device
    ) {
      this.setState({
        selected_input_device: this.props.selected_input_device
      });
    }

    if (
      this.props.countdown != prevProps.countdown &&
      this.props.countdown == 0 &&
      this.state.countdown_button_hover
    ) {
      this.setState({
        countdown_button_hover: false
      });
    }
  }

  render() {
    const { colors, active_dialer_contact } = this.props;
    const { call_status } = this.props.active_call;

    const { start_call_loading, call_time } = this.props;

    return (
      <>
        <Row>
          <Wrapper
            style={{
              width: this.props.device === "desktop" ? 60 : 30,
              height: this.props.device === "desktop" ? 60 : 30,
              borderRadius: this.props.device === "desktop" ? 30 : 15,
              backgroundColor: colors.dark_phone_inner_color,
              overflow: "hidden",
              position: "relative",
              margin: 10,
              marginRight: 0
            }}
          >
            {!!this.props.countdown &&
            this.props.countdown > 0 &&
            !this.props.active_dialer_contact?.incoming_call &&
            !this.props.active_dialer_contact?.answered_on_another_device ? (
              <Button
                onPress={() => {
                  this.setState(
                    {
                      countdown_button_hover: false
                    },
                    () => {
                      this.props.bypassCountdown();
                    }
                  );
                }}
                onHover={() => {
                  this.setState({
                    countdown_button_hover: true
                  });
                }}
                onHoverOut={() => {
                  this.setState({
                    countdown_button_hover: false
                  });
                }}
                style={{
                  flex: 1,
                  alignSelf: "stretch",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  backgroundColor: this.state.countdown_button_hover
                    ? colors.success_color
                    : "transparent"
                }}
                tooltip={"Call Now"}
                tooltipPlacement={"top"}
              >
                {this.state.countdown_button_hover ? (
                  <>
                    <Icon
                      icon={"call"}
                      size={22}
                      color={colors.white_text_color}
                    />
                  </>
                ) : (
                  <>
                    {this.props.device === "desktop" ||
                    !!this.props.newNumberAnimation ? (
                      <Spin
                        color={colors.white_text_color}
                        size={this.props.device === "mobile" ? "small" : null}
                      />
                    ) : null}

                    {!this.props.newNumberAnimation ? (
                      <Wrapper
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <Copy
                          style={{
                            color: colors.white_text_color,
                            fontSize: 22
                          }}
                        >
                          <Bold>{this.props.countdown}</Bold>
                        </Copy>
                      </Wrapper>
                    ) : null}
                  </>
                )}
              </Button>
            ) : (start_call_loading ||
                call_status === "loading" ||
                call_status === "calling") &&
              !this.props.call_canceled ? (
              <Wrapper
                style={{
                  flex: 1,
                  alignSelf: "stretch",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative"
                }}
              >
                <Spin
                  color={colors.white_text_color}
                  size={this.props.device === "mobile" ? "small" : null}
                />
              </Wrapper>
            ) : (
              <ProfilePic
                size={this.props.device === "desktop" ? 60 : 30}
                firstname={active_dialer_contact?.given_name}
                lastname={active_dialer_contact?.surname}
                email={active_dialer_contact?.email_address_1}
                image={null}
                style={{ marginRight: 0 }}
                background_color={colors.dark_phone_inner_color}
                fontSize={this.props.device === "desktop" ? 18 : 14}
                fontColor={colors.white_text_color}
              />
            )}
          </Wrapper>

          <Wrapper
            style={{
              flex: 1,
              justifyContent: "center",
              height: this.props.height
            }}
          >
            <Wrapper style={{ paddingRight: 15, paddingLeft: 15 }}>
              <Row>
                <Wrapper>
                  {this.props.active_dialer_contact?.incoming_call &&
                  !this.props.active_dialer_contact
                    ?.answered_on_another_device &&
                  (!call_status ||
                    call_status === "loading" ||
                    call_status === "calling" ||
                    call_status === "connected") ? (
                    <Copy
                      style={{
                        color: colors.white_text_color,
                        fontSize: 12
                      }}
                    >
                      Incoming Call:
                    </Copy>
                  ) : null}
                  <Copy style={{ color: colors.white_text_color }}>
                    <Bold>{active_dialer_contact?.full_name}</Bold>
                  </Copy>
                  <Row style={{ alignItems: "center", marginTop: 3 }}>
                    <Copy style={{ color: colors.white_text_color }}>
                      {formatUsPhone(
                        active_dialer_contact?.selected_phone?.number
                      )}
                    </Copy>
                    {active_dialer_contact?.selected_phone?.type === "W" ? (
                      <Wrapper
                        tooltip={"Wireless Phone"}
                        tooltipPlacement={"top"}
                        style={{ marginLeft: 5 }}
                      >
                        <Icon
                          icon={"mobile-friendly"}
                          size={14}
                          color={colors.white_text_color}
                        />
                      </Wrapper>
                    ) : active_dialer_contact?.selected_phone?.type === "L" ? (
                      <Wrapper
                        tooltip={"Landline"}
                        tooltipPlacement={"top"}
                        style={{ marginLeft: 5 }}
                      >
                        <Icon
                          icon={"mobile-off"}
                          size={14}
                          color={colors.white_text_color}
                        />
                      </Wrapper>
                    ) : null}
                    {active_dialer_contact?.selected_phone?.do_not_call_flag ==
                    1 ? (
                      <Wrapper
                        noPress={true}
                        style={{
                          backgroundColor: colors.orange_color_muted,
                          borderRadius: 5,
                          padding: 0,
                          paddingRight: 5,
                          paddingLeft: 5,
                          marginLeft: 5
                        }}
                        tooltip={
                          "Please note: The displayed phone number is on the National Do Not Call Registry. Calling this number for unsolicited offers or promotions may violate telemarketing rules. Proceed at your own risk and ensure compliance with all relevant regulations."
                        }
                        tooltipPlacement="top"
                      >
                        <Row>
                          <Copy style={{ fontSize: 10 }}>DNC</Copy>
                        </Row>
                      </Wrapper>
                    ) : null}
                  </Row>

                  {this.props.user?.user_settings?.dialer_settings
                    ?.practice_mode ? (
                    <Row>
                      <Wrapper
                        noPress={true}
                        tooltip={
                          "Practice mode is enabled. Calls will not be placed to the selected contact and instead routed to our AI practice bots."
                        }
                        tooltipPlacement="top"
                      >
                        <Copy
                          style={{
                            fontSize: 12,
                            color: colors.orange_color
                          }}
                        >
                          <Bold>Practice Mode</Bold>
                        </Copy>
                      </Wrapper>
                    </Row>
                  ) : (
                    <Row>
                      {!!active_dialer_contact?.selected_phone.call_status ? (
                        <Copy
                          style={{
                            color: colors.active_color,
                            fontSize: 12,
                            marginRight: 10
                          }}
                        >
                          <Bold>
                            {active_dialer_contact?.selected_phone?.call_status
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, l => l.toUpperCase())}
                          </Bold>
                        </Copy>
                      ) : null}

                      <Row>
                        {this.props.active_dialer_contact
                          ?.answered_on_another_device &&
                        call_status !== "call_ended" ? (
                          <Copy
                            style={{
                              color: colors.white_text_color,
                              fontSize: 12,
                              marginRight: 5
                            }}
                          >
                            <Bold>Answered on another device</Bold>
                          </Copy>
                        ) : this.props.call_start_time &&
                          this.props.isTimerActive &&
                          !this.props.active_dialer_contact
                            ?.answered_on_another_device ? (
                          <Copy
                            style={{
                              color: colors.white_text_color,
                              fontSize: 12
                            }}
                          >
                            {call_time}
                          </Copy>
                        ) : null}
                      </Row>
                    </Row>
                  )}
                </Wrapper>

                <IconButton
                  button_type={"small"}
                  icon={"open-in-new"}
                  icon_color={colors.white_text_color}
                  hover_color={colors.hover_white_color}
                  tooltip={"Open Contact"}
                  tooltipPlacement={"top"}
                  onPress={() => {
                    if (this.props.hideMore) {
                      this.props.hideMore();
                    }
                    this.props.replaceSidePanel({
                      slug: "contact",
                      expanded_side_panel: true,
                      overlay: true,
                      id: this.props.active_dialer_contact?.individual_key,
                      data: {
                        person: this.props.active_dialer_contact,
                        property:
                          this.props.active_dialer_contact?.associated_lead
                      }
                    });
                  }}
                />
              </Row>
            </Wrapper>
          </Wrapper>
          <Row style={{ paddingRight: 10 }}>
            {this.props.active_dialer_contact?.incoming_call &&
            !this.props.active_dialer_contact?.answered_on_another_device &&
            (!call_status ||
              call_status === "loading" ||
              call_status === "calling" ||
              call_status === "connected") ? (
              <Row>
                <Wrapper className={"ring-animation"}>
                  <IconButton
                    onPress={this.props.acceptIncomingCall}
                    icon={"call"}
                    tooltip={"Answer Call"}
                    tooltipPlacement={"top"}
                    icon_color={colors.success_color}
                    hover_color={colors.hover_white_color}
                  />
                </Wrapper>
                <IconButton
                  onPress={() => {
                    this.props.setRejectedCall(
                      this.props.incoming_call_info?.call_id
                    );
                    if (
                      this.props.incoming_call &&
                      this.props.device === "desktop"
                    ) {
                      this.props.incoming_call.reject();
                    }
                    this.props.handleHangup();
                    this.props.setActiveDialerContact({
                      contact: null,
                      queued_numbers: []
                    });
                  }}
                  icon={"call-end"}
                  tooltip={"Don't Answer"}
                  tooltipPlacement={"top"}
                  icon_color={colors.error_color}
                  hover_color={colors.hover_white_color}
                />
              </Row>
            ) : this.props.countdown > 0 &&
              !this.props.call_canceled &&
              !this.props.active_dialer_contact?.incoming_call &&
              !this.props.active_dialer_contact?.answered_on_another_device ? (
              <Row>
                {this.props.device === "mobile"
                  ? this.renderSelectAudioDeviceMenu()
                  : null}

                <IconButton
                  tooltip={!this.props.mute ? "Mute" : "Unmute"}
                  tooltipPlacement={"top"}
                  icon={!this.props.mute ? "mic" : "mic-off"}
                  icon_color={colors.white_text_color}
                  hover_color={colors.hover_white_color}
                  backgroundColor={this.props.mute ? colors.error_color : null}
                  onPress={() => {
                    this.props.muteCall(!this.props.mute);
                  }}
                />

                {this.props.device === "desktop" ? (
                  <InlineButton
                    onPress={() => {
                      this.props.clearCountdown();

                      if (!start_call_loading) {
                        this.props.handleHangup(false);
                      }
                    }}
                    icon_color={colors.white_text_color}
                    textColor={colors.white_text_color}
                    hover_color={colors.hover_white_color}
                    disabled={start_call_loading && this.props.countdown === 0}
                  >
                    Cancel
                  </InlineButton>
                ) : (
                  <IconButton
                    onPress={() => {
                      this.props.clearCountdown();

                      if (!start_call_loading) {
                        this.props.handleHangup(false);
                      }
                    }}
                    icon={"call-end"}
                    tooltip={"Hang Up"}
                    tooltipPlacement={"top"}
                    icon_color={colors.error_color}
                    hover_color={colors.hover_white_color}
                    disabled={start_call_loading && this.props.countdown === 0}
                  />
                )}
              </Row>
            ) : !!call_status &&
              call_status !== "call_ended" &&
              !this.props.call_canceled ? (
              <>
                {!this.props.active_dialer_contact
                  ?.answered_on_another_device ? (
                  <Row>
                    {this.props.device === "mobile"
                      ? this.renderSelectAudioDeviceMenu()
                      : null}

                    <IconButton
                      tooltip={!this.props.mute ? "Mute" : "Unmute"}
                      tooltipPlacement={"top"}
                      icon={!this.props.mute ? "mic" : "mic-off"}
                      icon_color={colors.white_text_color}
                      hover_color={colors.hover_white_color}
                      backgroundColor={
                        this.props.mute ? colors.error_color : null
                      }
                      onPress={() => {
                        this.props.muteCall(!this.props.mute);
                      }}
                    />
                    <Wrapper
                      className={
                        call_status === "connected" &&
                        !this.props.active_dialer_contact.incoming_call
                          ? "ring-animation"
                          : ""
                      }
                    >
                      <IconButton
                        onPress={() => {
                          this.props.handleHangup(false);
                        }}
                        icon={
                          call_status === "connected"
                            ? "ring-volume"
                            : "call-end"
                        }
                        tooltip={"Hang Up"}
                        tooltipPlacement={"top"}
                        icon_color={colors.error_color}
                        hover_color={colors.hover_white_color}
                        disabled={call_status === "loading"}
                      />
                    </Wrapper>
                  </Row>
                ) : (
                  <>
                    <IconButton
                      onPress={this.props.closeSession}
                      icon={"close"}
                      tooltip={"Close"}
                      tooltipPlacement={"top"}
                      icon_color={colors.white_text_color}
                      hover_color={colors.hover_white_color}
                    />
                  </>
                )}
              </>
            ) : (
              <Row>
                {this.props.device === "mobile"
                  ? this.renderSelectAudioDeviceMenu()
                  : null}

                {call_status !== "call_ended" ? (
                  <IconButton
                    onPress={this.props.startCall}
                    icon={"call"}
                    tooltip={"Call"}
                    tooltipPlacement={"top"}
                    icon_color={colors.success_color}
                    hover_color={colors.hover_white_color}
                  />
                ) : null}

                <IconButton
                  onPress={this.props.closeSession}
                  icon={"close"}
                  tooltip={"Close"}
                  tooltipPlacement={"top"}
                  icon_color={colors.white_text_color}
                  hover_color={colors.hover_white_color}
                />
              </Row>
            )}
          </Row>
        </Row>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, dialer, native }) => {
  const { token, user } = auth;
  const { device, desktopMobile } = native;
  const { dark_mode, colors } = settings;
  const { active_dialer_contact, active_call } = dialer;

  return {
    token,
    user,
    dark_mode,
    device,
    desktopMobile,
    colors,
    active_dialer_contact,
    active_call
  };
};

export default connect(mapStateToProps, {
  replaceSidePanel,
  setRejectedCall,
  getQueuedNumbers,
  getContactCallHistory,
  setActiveDialerContact
})(MainButtons);
