import React, { Component } from "react";
import { connect } from "react-redux";

import DeviceHandler from "./DeviceHandler";
import { checkIfUserHasMetadata } from "app/NativeActions";

class NativePhone extends Component {
  constructor(props) {
    super(props);

    this._device = null;
  }

  render() {
    const phone_plan = checkIfUserHasMetadata("dialer");

    if (
      phone_plan &&
      this.props.user &&
      this.props.init &&
      !!this.props.user?.user_ws_key
    ) {
      return (
        <>
          <DeviceHandler />
        </>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ auth, settings, dialer }) => {
  const { token, user, init } = auth;
  const { dark_mode, colors } = settings;
  const { active_dialer_contact } = dialer;

  return {
    token,
    user,
    init,
    dark_mode,
    colors,
    active_dialer_contact
  };
};

export default connect(mapStateToProps, {})(NativePhone);
